export namespace TeamMemberRole {
    export const MEMBER = 'MEMBER';
    export const MANAGER = 'MANAGER';
}

export interface ITeamMember {
    userId: string;
    teamId: string;
    teamRole: string;
    email: string;
    fullName: string;
    icon: string;
}
