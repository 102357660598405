import { Paging, PagingHistory } from '../interfaces/paging.interface';

export namespace ElectronicJournalActions {
    export class GetById {
        static readonly type = '[Electronic Journal API] Get By Id';

        constructor(public businessUnitId: string,
                    public deviceId: string,
                    public businessDate: Date | string,
                    public sequenceNumber: number) {
        }
    }

    export class Search {
        static readonly type = '[Electronic Journal API] Search';
    }

    export class CommitSearchFilterForm {
        static readonly type = '[Electronic Journal API] Commit Search Filter Form';
    }

    export class ResetCommittedSearchFilterForm {
        static readonly type = '[Electronic Journal API] Reset Committed Search Filter Form';
    }

    export class SetEditingFilter {
        static readonly type = '[Electronic Journal API] Set Editing Filter';

        constructor(public editing: boolean) {
        }
    }

    export class SetSearchPaging {
        static readonly type = '[Electronic Journal API] Set Search Paging';

        constructor(public paging?: Paging) {
        }
    }

    export class SetSearchPagingHistory {
        static readonly type = '[Electronic Journal API] Set Search Paging History';

        constructor(public pagingHistory?: PagingHistory) {
        }
    }

    export class GetBusinessUnits {
        static readonly type = '[Electronic Journal API] Get Business Units';
    }

    export class GetDevices {
        static readonly type = '[Electronic Journal API] Get Devices';
    }

    export class GetTransStatusCodes {
        static readonly type = '[Electronic Journal API] Get Transaction Status Codes';
    }

    export class GetTransTypeCodes {
        static readonly type = '[Electronic Journal API] Get Transaction Type Codes';
    }

    export class GetTenderTypeCodes {
        static readonly type = '[Electronic Journal API] Get Tender Type Codes';
    }
}
