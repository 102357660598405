import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { IUserInvitation } from '../interfaces/user-invitation.interface';
import { IUserRequest } from '../interfaces/user-request.interface';
import { IUser } from '../interfaces/user.interface';
import { UserService } from '../services/users.service';
import { UserConfigurationActions } from './user-configuration.actions';
import { AuthorizedActions } from './authorized.actions';
import { AuthorizedState } from './authorized.state';

export class UserConfigurationStateModel {
    activeUsers: IUser[];
    pendingUserInvitations: IUserInvitation[];
    pendingUserRequests: IUserRequest[];
}

const USER_CONFIG_STATE_TOKEN = new StateToken<UserConfigurationStateModel>('userConfiguration');

@State<UserConfigurationStateModel>({
    name: USER_CONFIG_STATE_TOKEN,
    defaults: {
        activeUsers: [],
        pendingUserInvitations: [],
        pendingUserRequests: [],
    },
})
@Injectable()
export class UserConfigurationState {

    constructor(private userService: UserService, private store: Store) {
    }

    static activeUsersBySearch(searchValue: string) {
        return createSelector([UserConfigurationState], (state: UserConfigurationStateModel): IUser[] =>
            state.activeUsers?.filter((user:  IUser) => user.fullName.toLowerCase().includes(searchValue.toLowerCase())
             || user.email.toLowerCase().includes(searchValue.toLowerCase())));
    }

    static activeUser(userId: string) {
        return createSelector([UserConfigurationState], (state: UserConfigurationStateModel): IUser =>
            state.activeUsers.find((user: IUser) => user.userId === userId));
    }

    @Selector()
    static activeUsers(state: UserConfigurationStateModel): IUser[] {
        return state.activeUsers;
    }

    @Selector()
    static activeUsersAsOptions(state: UserConfigurationStateModel): string[] {
        return state.activeUsers?.map(user => `${user.fullName} (${user.email})`);
    }

    @Selector()
    static pendingInvitations(state: UserConfigurationStateModel): IUserInvitation[] {
        return state.pendingUserInvitations;
    }

    @Selector()
    static pendingRequests(state: UserConfigurationStateModel): IUserRequest[] {
        return state.pendingUserRequests;
    }

    @Action(AuthorizedActions.LoggedIn)
    @Action(UserConfigurationActions.LoadActiveAndPendingUsers)
    loadActiveAndPendingUsers(ctx: StateContext<UserConfigurationStateModel>): Observable<UserConfigurationStateModel> {
        return this.userService.getActiveAndPendingUsers().pipe(
            tap((result: UserConfigurationStateModel) =>
                ctx.patchState({
                    ...result
                })
            )
        );
    }

    @Action(UserConfigurationActions.InviteUser)
    inviteUser(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.InviteUser): Observable<{ success: boolean; resent: boolean }> {
        const activeUser = this.store.selectSnapshot(AuthorizedState.authorizedUser);
        return this.userService.inviteUser(action.email, activeUser).pipe(
            tap((result: { success: boolean; resent: boolean; message?: string }) => {
                if (!result.success) {
                    throw new Error(result.message ? result.message : 'Failed to send invitation.');
                }
            })
        );
    }

    @Action(UserConfigurationActions.EditUser)
    editUser(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.EditUser): Observable<IGenericServerResponse> {
        return this.userService.editUser(action.request).pipe(
            tap((result: IGenericServerResponse) => {
                if (!result.success) {
                    if (result.message) {
                        throw new Error(`Failed to save user changes due to: ${result.message}`);
                    } else {
                        throw new Error('Failed to save user changes');
                    }
                }
            })
        );
    }

    @Action(UserConfigurationActions.UserAdminChange)
    userAdminChange(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.UserAdminChange): Observable<IGenericServerResponse> {
        return this.userService.userAdminChange(action.request).pipe(
            tap((result: IGenericServerResponse) => {
                if (!result.success) {
                    if (result.message) {
                        throw new Error(`Failed to change user admin status due to: ${result.message}`);
                    } else {
                        throw new Error('Failed to change user admin status');
                    }
                }
            })
        );
    }

    @Action(UserConfigurationActions.DeleteUser)
    deleteUser(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.DeleteUser): Observable<IGenericServerResponse> {
        return this.userService.deleteUser(action.userId).pipe(
            tap((result: IGenericServerResponse) => {
                if (!result.success) {
                    if (result.message) {
                        throw new Error(`Failed to delete user due to: ${result.message}`);
                    } else {
                        throw new Error('Failed to delete user');
                    }
                }
            })
        );
    }

    @Action(UserConfigurationActions.AcceptPendingRequests)
    acceptPendingRequests(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.AcceptPendingRequests): Observable<IGenericServerResponse[]> {
        if (action.requests?.length > 0) {
            return forkJoin(action.requests.map((userRequest: IUserRequest) =>
                this.userService.acceptPendingRequest(userRequest.userId)));
        }
    }

    @Action(UserConfigurationActions.RejectPendingRequests)
    rejectPendingRequests(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.RejectPendingRequests): Observable<IGenericServerResponse[]> {
        if (action.requests?.length > 0) {
            return forkJoin(action.requests.map((userRequest: IUserRequest) =>
                this.userService.rejectPendingRequest(userRequest.userId)));
        }
    }

    @Action(UserConfigurationActions.ResendPendingInvitations)
    resendPendingInvitations(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.ResendPendingInvitations): Observable<IGenericServerResponse[]> {
        const activeUser = this.store.selectSnapshot(AuthorizedState.authorizedUser);
        if (action.invitations?.length > 0) {
            return forkJoin(action.invitations.map((userInvite: IUserInvitation) =>
                this.userService.inviteUser(userInvite.email, activeUser).pipe(
                    tap((result: IGenericServerResponse) => {
                        if (!result.success) {
                            if (result.message) {
                                throw new Error(`Failed to resend invitation due to: ${result.message}`);
                            } else {
                                throw new Error('Failed to resend invitation');
                            }
                        }
                    })
                )));
        }
    }

    @Action(UserConfigurationActions.DeletePendingInvitations)
    deletePendingInvitations(ctx: StateContext<UserConfigurationStateModel>, action: UserConfigurationActions.DeletePendingInvitations): Observable<IGenericServerResponse[]> {
        if (action.invitations?.length > 0) {
            return forkJoin(action.invitations.map((userInvite: IUserInvitation) =>
                this.userService.cancelInvite(userInvite.email).pipe(
                    tap((result: IGenericServerResponse) => {
                        if (!result.success) {
                            if (result.message) {
                                throw new Error(`Failed to delete invitation due to: ${result.message}`);
                            } else {
                                throw new Error('Failed to delete invitation');
                            }
                        }
                    })
                )));
        }
    }

}
