import { GetWorkflowItemsRequest, IReview, IWorkflowConfig } from '../interfaces/review.interface';

export namespace PromotionWorkflowActions {

    export class LoadWorkflowConfig {
        static readonly type = '[Promotion Workflow] Load Workflow Configs';
    }

    export class GetWorkflowItems {
        static readonly type = '[Promotion Workflow] Get Workflow Items';
        constructor(public request: GetWorkflowItemsRequest) { }
    }

    export class SaveWorkflowConfigs {
        static readonly type = '[Promotion Workflow] Save New Workflow Configs';

        constructor(public workflowConfigs: IWorkflowConfig[], public objectType: string) {
        }
    }

    export class ApproveReview {
        static readonly type = '[Promotion Workflow] Approve Review';
        constructor(public review: IReview) { }
    }

    export class RejectReview {
        static readonly type = '[Promotion Workflow] Reject Review';
        constructor(public review: IReview) { }
    }

    export class RetryTestEnvironmentDeploy {
        static readonly type = '[Promotion Workflow] Retry Test Environment Deploy';
        constructor(public objectId: string, public workflowConfigId: string) {
        }
    }
}
