<div class="nb-form-field">
    <label for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <nb-select id="{{id}}" placeholder="{{placeholder}}" shape="semi-round" size="large" fullWidth
        [formControl]="control" [status]="getStatus()" [multiple]="multiSelect">
        <nb-option-group *ngFor="let group of optionGroups" [title]="group.displayValue">
            <nb-option *ngFor="let option of group.options" class="option" [value]="option.key">
                <div id="colorSwatch{{id}}{{group.displayValue}}{{option.color}}"
                    *ngIf="option.color && isDefaultDataColor(option.color)" class="color-swatch"
                    [ngClass]="option.color">
                </div>
                <div id="colorSwatch{{id}}{{group.displayValue}}{{option.color}}"
                    *ngIf="option.color && !isDefaultDataColor(option.color)" class="color-swatch unknown-color"
                    [ngStyle]="{'background-color': option.color}">
                </div>
                <span>{{option.displayValue}}</span>
            </nb-option>
        </nb-option-group>
        <nb-option *ngFor="let option of options" class="option" [value]="option.key">
            <div id="colorSwatch{{id}}{{option.color}}" *ngIf="option.color && isDefaultDataColor(option.color)"
                class="color-swatch" [ngClass]="option.color">
            </div>
            <div id="colorSwatch{{id}}{{option.color}}" *ngIf="option.color && !isDefaultDataColor(option.color)"
                class="color-swatch unknown-color" [ngStyle]="{'background-color': option.color}">
            </div>
            <span>{{option.displayValue}}</span>
        </nb-option>
    </nb-select>
    <div class="error-container">
        <span *ngIf="control?.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
