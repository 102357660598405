import { ITEM as PROMO_ITEM, TRANS as PROMO_TRANS } from './promotion.interface';
import { SelectOption } from '../../../@forms/forms.interface';
export interface IQualificationAttributeConfig {
    attributeId: string;
    displayName: string;
    editable?: boolean;
    attributeLevel: string;
    attributeTypeCode: string;
    attributeValueOptions?: IQualificationAttributeValueOptionConfig[];
}

export interface IQualificationAttributeValueOptionConfig {
    attributeId: string;
    attributeValueOptionId: string;
    attributeValue: string;
}

export interface GetAllQualificationAttributeConfigsResponse {
    qualificationAttributeConfigs: IQualificationAttributeConfig[];
}

export interface ItemAttributeFieldConfig {
    operatorOptions: SelectOption[];
    valueFieldType: 'text' | 'number' | 'select';
    valueFieldId: 'valueString' | 'valueList';
    valueFieldOptions: SelectOption[];
}

export interface AttributeFormModel {
    attributeId: string;
    displayName: string;
    attributeLevel: string;
    attributeType: string;
    valueOptions: string[];
}

export namespace AttributeLevel {
    export const ITEM = PROMO_ITEM;
    export const TRANS = PROMO_TRANS;
}

export namespace AttributeTypeCode {
    export const LIST = 'LIST';
    export const TEXT = 'TEXT';
    export const NUMBER = 'NUMBER';
}

export namespace AttributeOperator {
    export const GREATER = 'GREATER';
    export const LESSER = 'LESSER';
    export const EQUALS = 'EQUALS';
    export const IN = 'IN';
}

export namespace TransactionAttributeSelectOptions {
    export const GREATER: SelectOption = { key: AttributeOperator.GREATER, displayValue: 'Greater Than' };
    export const LESSER: SelectOption = { key: AttributeOperator.LESSER, displayValue: 'Less Than' };
    export const EQUALS: SelectOption = { key: AttributeOperator.EQUALS, displayValue: 'Equals' };
    export const IN: SelectOption = { key: AttributeOperator.IN, displayValue: 'IN' };

    export const displayValuesByKey = {
        [GREATER.key]: GREATER.displayValue,
        [LESSER.key]: LESSER.displayValue,
        [EQUALS.key]: EQUALS.displayValue,
        [IN.key]: IN.displayValue
    };
}
