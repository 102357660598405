export enum LineItemTypeCode {
    STORE_SALE = 'STORE_SALE',
    WEB = 'WEB',
    BOPIS = 'BOPIS',
    BISPIS = 'BISPIS',
    ORDER_IN_STORE = 'ORDER_IN_STORE',
    SFS = 'SFS',
    CURBSIDE = 'CURBSIDE',
    GIFT_CARD = 'GIFT_CARD',
    SEND_SALE = 'SEND_SALE',
    REPEAT_DELIVERY = 'REPEAT_DELIVERY',
}

export enum ItemTypeCode {
    STOCK = 'STOCK',
    GIFTCARD = 'GIFTCARD',
    STORE_ORDER_SHIPPING = 'STORE_ORDER_SHIPPING',
    SERVICE = 'SERVICE',
    MANUFACTURER_COUPON = 'MANUFACTURER_COUPON',
    STORE_COUPON = 'STORE_COUPON',
    DONATION = 'DONATION',
    WARRANTY = 'WARRANTY',
    FEE = 'FEE',
    LOYALTY = 'LOYALTY',
    EMPLOYEE = 'EMPLOYEE',
    CUSTOMER_ACCOUNT_LINK = 'CUSTOMER_ACCOUNT_LINK',
    CUSTOMER_ACCOUNT = 'CUSTOMER_ACCOUNT',
    SUBSCRIPTION = 'SUBSCRIPTION',
    MILITARY = 'MILITARY'
}

export enum TenderGroup {
    IN_STORE = 'IN_STORE',
    ORDER_PRE_AUTH = 'ORDER_PRE_AUTH'
}

export enum AuthResultCode {
    OK = 'OK'
}

export enum TaxGroupAuthority {
    Override = 'override'
}

export enum TransTypeCode {
    AUDITABLE_ACTION = 'AUDITABLE_ACTION',
    SALE = 'SALE',
    SALE_ORDER = 'SALE_ORDER',
    RETURN = 'RETURN',
    INQUIRY = 'INQUIRY',
    RETURN_ORDER = 'RETURN_ORDER',
    VOID = 'VOID',
    NO_SALE = 'NO_SALE',
    OPEN_BUSINESS_UNIT = 'OPEN_BUSINESS_UNIT',
    CLOSE_BUSINESS_UNIT = 'CLOSE_BUSINESS_UNIT',
    OPEN_DEVICE = 'OPEN_DEVICE',
    CLOSE_DEVICE = 'CLOSE_DEVICE',
    OPEN_TILL = 'OPEN_TILL',
    CLOSE_TILL = 'CLOSE_TILL',
    RECONCILE_TILL = 'RECONCILE_TILL',
    SUSPEND_TILL = 'SUSPEND_TILL',
    FLOAT_TILL = 'FLOAT_TILL',
    OPEN_STORE_BANK = 'OPEN_STORE_BANK',
    CLOSE_STORE_BANK = 'CLOSE_STORE_BANK',
    PAY_IN = 'PAY_IN',
    PAY_OUT = 'PAY_OUT',
    CASH_UP = 'CASH_UP',
    CASH_DOWN = 'CASH_DOWN',
    TRAINING_START = 'TRAINING_START',
    TRAINING_END = 'TRAINING_END',
    SUSPEND_RETRIEVED = 'SUSPEND_RETRIEVED',
    REDEEM = 'REDEEM',
    ORDER_STATUS = 'ORDER_STATUS'
}

export enum TransStatusCode {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    SUSPENDED = 'SUSPENDED',
    FAILED = 'FAILED',
    SUSPEND_RETRIEVED = 'SUSPEND_RETRIEVED',
    SUSPEND_CANCELLED = 'SUSPEND_CANCELLED',
    VOIDED = 'VOIDED',
    ABORTED = 'ABORTED',
    ORPHANED = 'ORPHANED'
}

export enum TenderTypeCode {
    BANK_CHECK = 'BANK_CHECK',
    CASH = 'CASH',
    ROUNDING_ADJUSTMENT = 'ROUNDING_ADJUSTMENT',
    CHECK = 'CHECK',
    MANUFACTURER_COUPON = 'MANUFACTURER_COUPON',
    STORE_COUPON = 'STORE_COUPON',
    CREDIT_CARD = 'CREDIT_CARD',
    VX690_CARD = 'VX690_CARD',
    UNSUPPORTED_AUTHORIZATION = 'UNSUPPORTED_AUTHORIZATION',
    EXTERNAL_AUTH = 'EXTERNAL_AUTH',
    DEBIT_CARD = 'DEBIT_CARD',
    GIFT_CARD = 'GIFT_CARD',
    GIFT_CERTIFICATE = 'GIFT_CERTIFICATE',
    MALL_CERTIFICATE = 'MALL_CERTIFICATE',
    E_WALLET = 'E_WALLET',
    QR_CODE_WALLET = 'QR_CODE_WALLET',
    VOUCHER = 'VOUCHER',
    ONLINE = 'ONLINE',
    POINTS = 'POINTS',
    STORE_CREDIT = 'STORE_CREDIT',
    TRAVELERS_CHECK = 'TRAVELERS_CHECK',
    UNDETERMINED_CARD = 'UNDETERMINED_CARD',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
    PRIVATE_LABEL = 'PRIVATE_LABEL',
    CO_BRAND = 'CO_BRAND',
    PAYMENT_TOKEN = 'PAYMENT_TOKEN'
}

export interface AbstractTrans {
    [key: string]: any;
    createTime?: Date | string;
    createBy?: string;
    lastUpdateTime?: Date | string;
    lastUpdateBy?: string;
    businessDate?: string;
    businessUnitId: string;
    deviceId: string;
    sequenceNumber: number;
}

export interface TransSummary {
    deviceId: string;
    businessDate: Date | string;
    sequenceNumber: number;
    businessUnitId: string;
    deviceType?: string;
    transTypeCode?: TransTypeCode;
    transStatusCode?: TransStatusCode;
    username?: string;
    beginTime?: Date | string;
    endTime?: Date | string;
    localOffset?: number;
    transactionDurationInSec?: number;
    trainingMode?: boolean;
    barcode?: string;
    sessionId?: string;
    tillId?: string;
    customerId?: string;
    loyaltyCardNumber?: string;
    customerName?: string;
    employeeIdForDiscount?: string;
    voidableFlag?: boolean;
    itemCount?: number;
    rcptRtnCount?: number;
    nonRcptRtnCount?: number;
    isoCurrencyCode?: string;
    total?: string;
    preTenderBalanceDue?: string;
    taxTotal?: string;
    discountTotal?: string;
    tenderTypeCodes?: string;
    tender1TypeCode?: string;
    tender1Amount?: string;
    tender1CardTypeCode?: string;
    tender1MaskedCardNumber?: string;
    tender1AuthCode?: string;
    tender2TypeCode?: string;
    tender2Amount?: string;
    tender2CardTypeCode?: string;
    tender2MaskedCardNumber?: string;
    tender2AuthCode?: string;
    tender3TypeCode?: string;
    tender3Amount?: string;
    tender3CardTypeCode?: string;
    tender3MaskedCardNumber?: string;
    tender3AuthCode?: string;
    tender4TypeCode?: string;
    tender4Amount?: string;
    tender4CardTypeCode?: string;
    tender4MaskedCardNumber?: string;
    tender4AuthCode?: string;
    tender5TypeCode?: string;
    tender5Amount?: string;
    tender5CardTypeCode?: string;
    tender5MaskedCardNumber?: string;
    tender5AuthCode?: string;
    voidedSequenceNumber?: number;
    suspendedSequenceNumber?: number;
    suspendedDeviceId?: string;
    resumedSequenceNumber?: number;
    resumedDeviceId?: string;
    reasonCode?: string;
    paidTo?: string;
    storeBankId?: string;
    mfrCouponsTotal?: number;
    mfrCouponsCount?: number;
    mfrCouponsTaxableTotal?: number;
    mfrCouponsTaxableCount?: number;
    mfrCouponsNonTaxableTotal?: number;
    mfrCouponsNonTaxableCount?: number;
    storePromosTotal?: number;
    storePromosCount?: number;
    storePhysicalCouponsTotal?: number;
    storePhysicalCouponsCount?: number;
    storeElectronicPromosTotal?: number;
    storeElectronicPromosCount?: number;
    storePromosTaxableTotal?: number;
    storePromosTaxableCount?: number;
    storePromosNonTaxableTotal?: number;
    storePromosNonTaxableCount?: number;
    totalPhysicalCouponsCount?: number;
    loyaltyRewardsTotal?: number;
    loyaltyRewardsCount?: number;
}
