export namespace PromotionGroupsActions {
    export class GetPromotionGroups {
        static readonly type = '[Promotion API] Get Promotion Groups';
    }

    export class SearchPromotionGroups {
        static readonly type = '[Promotion Groups] Search Promotion Groups';
        constructor(public searchString: string) {
        }
    }

    export class ClearSearch {
        static readonly type = '[Promotion Groups] Clear Search';
    }
}
