import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import produce from 'immer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAppAccess } from '../interfaces/app-access.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { ITeamMember, TeamMemberRole } from '../interfaces/team-member.interface';
import {
    CreateTeamResponse,
    ITeam,
    TeamFormModel,
    UpdateTeamResponse
} from '../interfaces/team.interface';
import { UserService } from '../services/users.service';
import { TeamActions } from './team.actions';
import { TeamConfigurationState } from './team-configuration.state';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { AuthorizedActions } from './authorized.actions';

export class TeamStateModel {
    team: ITeam;
    appAccessList: IAppAccess[];
    teamMembers: ITeamMember[];
    newTeamForm: NgxsForm<TeamFormModel>;
}

const TEAM_STATE_TOKEN = new StateToken<TeamStateModel>('team');
@State<TeamStateModel>({
    name: TEAM_STATE_TOKEN,
    defaults: {
        team: undefined,
        appAccessList: [],
        teamMembers: [],
        newTeamForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        }
    }
})
@Injectable()
export class TeamState {

    constructor(private store: Store, private userService: UserService) { }

    static appAccessByType(accessType: string) {
        return createSelector([TeamState], (state: TeamStateModel): IAppAccess[] =>
            state.appAccessList?.filter((appAccess: IAppAccess) => appAccess.accessType === accessType));
    }

    private static areTeamMembersDifferent(state: TeamStateModel): boolean {
        return state.team?.teamMembers?.length !== state.teamMembers?.length ||
            state.team?.teamMembers?.some(member => !state.teamMembers.find(teamMember => teamMember.userId === member.userId && teamMember.teamRole === member.teamRole));
    }

    private static isAppAccessListDifferent(state: TeamStateModel): boolean {
        return state.team?.appAccessList?.length !== state.appAccessList?.length ||
            state.team?.appAccessList?.some(access => !state.appAccessList.find(appAccess => appAccess.applicationId === access.applicationId && appAccess.accessType === access.accessType));
    }

    @Selector()
    static team(state: TeamStateModel): ITeam {
        return state.team;
    }

    @Selector()
    static teamMembers(state: TeamStateModel): ITeamMember[] {
        return state.teamMembers;
    }

    @Selector()
    static teamManagers(state: TeamStateModel): ITeamMember[] {
        return state.teamMembers?.filter(teamMember => teamMember.teamRole === 'MANAGER');
    }

    @Selector()
    static appAccessList(state: TeamStateModel): IAppAccess[] {
        return state.appAccessList;
    }

    @Selector()
    static isTeamChanged(state: TeamStateModel): boolean {
        return this.areTeamMembersDifferent(state) || this.isAppAccessListDifferent(state);
    }

    @Action(TeamActions.LoadTeam)
    loadTeam(ctx: StateContext<TeamStateModel>, action: TeamActions.LoadTeam): Observable<ITeam> {
        return this.store.select(TeamConfigurationState.teamById(action.teamId)).pipe(
            tap((result: ITeam) => {
                if (result) {
                    ctx.patchState({
                        team: result,
                        appAccessList: result.appAccessList,
                        teamMembers: result.teamMembers,
                    });
                } else {
                    throw new Error(`Could not find team [${action.teamId}]`);
                }
            })
        );
    }

    @Action(TeamActions.UpdateAppAccessList)
    updateAppAccessList(ctx: StateContext<TeamStateModel>, action: TeamActions.UpdateAppAccessList): void {
        if (action.applicationId && action.accessType) {
            const index = ctx.getState().appAccessList ? ctx.getState().appAccessList.findIndex((appAccess: IAppAccess) =>
                appAccess.applicationId === action.applicationId && appAccess.accessType === action.accessType) : -1;
            if (index !== -1) {
                ctx.setState(produce((draft) => {
                    draft.appAccessList.splice(index, 1);
                }));
            } else if (ctx.getState().appAccessList) {
                ctx.patchState({
                    appAccessList: [...ctx.getState().appAccessList, { applicationId: action.applicationId, accessType: action.accessType }],
                });
            } else {
                ctx.patchState({
                    appAccessList: [{ applicationId: action.applicationId, accessType: action.accessType }],
                });
            }
        }
    }

    @Action(TeamActions.UpdateTeamMemberRole)
    updateTeamMemberRole(ctx: StateContext<TeamStateModel>, action: TeamActions.UpdateTeamMemberRole): void {
        if (action.userId && action.teamRole) {
            ctx.setState(produce((draft) => {
                const teamMember = draft.teamMembers?.find(member => member.userId === action.userId);
                if (teamMember) {
                    teamMember.teamRole = action.teamRole;
                }
            }));
        }
    }

    @Action(TeamActions.RemoveTeamMember)
    removeTeamMember(ctx: StateContext<TeamStateModel>, action: TeamActions.RemoveTeamMember): void {
        if (action.userId) {
            const index = ctx.getState().teamMembers ? ctx.getState().teamMembers.findIndex((member: ITeamMember) => member.userId === action.userId) : -1;
            if (index !== -1) {
                ctx.setState(produce((draft) => {
                    draft.teamMembers.splice(index, 1);
                }));
            }
        }
    }

    @Action(TeamActions.AddTeamMember)
    addTeamMember(ctx: StateContext<TeamStateModel>, action: TeamActions.AddTeamMember): void {
        if (action.user) {
            const existingUser = ctx.getState().teamMembers?.find(teamMember => teamMember.userId === action.user.userId);
            if (!existingUser) {
                const newTeamMember: ITeamMember = {
                    userId: action.user.userId,
                    teamId: ctx.getState().team.teamId,
                    teamRole: TeamMemberRole.MEMBER,
                    email: action.user.email,
                    fullName: action.user.fullName,
                    icon: action.user.icon
                };
                if (ctx.getState().teamMembers) {
                    ctx.patchState({
                        teamMembers: [...ctx.getState().teamMembers, newTeamMember]
                    });
                } else {
                    ctx.patchState({
                        teamMembers: [newTeamMember]
                    });
                }
            } else {
                throw new Error(`${existingUser.fullName} already exists on the team`);
            }
        }
    }

    @Action(TeamActions.DeleteTeam)
    deleteTeam(ctx: StateContext<TeamStateModel>): Observable<IGenericServerResponse> {
        if (ctx.getState().team) {
            const teamId = ctx.getState().team.teamId;
            const teamName = ctx.getState().team.teamName;
            return this.userService.deleteTeam(teamId).pipe(
                tap((result: IGenericServerResponse) => {
                    if (!result.success) {
                        throw new Error(`Failed to delete team [${teamName}]`);
                    }
                })
            );
        }
    }

    @Action(TeamActions.SaveChanges)
    saveChanges(ctx: StateContext<TeamStateModel>): Observable<UpdateTeamResponse> {
        if (ctx.getState().team) {
            const teamId = ctx.getState().team.teamId;
            const teamName = ctx.getState().team.teamName;
            return this.userService.updateTeam(teamId, ctx.getState().teamMembers, ctx.getState().appAccessList).pipe(
                tap((result: UpdateTeamResponse) => {
                    if (result?.team) {
                        ctx.patchState({
                            team: result.team,
                            appAccessList: result.team.appAccessList,
                            teamMembers: result.team.teamMembers,
                        });
                        this.store.dispatch(AuthorizedActions.RefreshAuthorizedItems);
                    } else {
                        throw new Error(`Failed to save changes to team [${teamName}]`);
                    }
                })
            );
        }
    }

    @Action(TeamActions.CreateTeam)
    createTeam(ctx: StateContext<TeamStateModel>): Observable<CreateTeamResponse> {
        if (ctx.getState().newTeamForm?.model) {
            return this.userService.createTeam(ctx.getState().newTeamForm.model).pipe(
                tap((result: CreateTeamResponse) => {
                    if (!result?.success) {
                        throw new Error(`Failed to create new team [${ctx.getState().newTeamForm.model.teamId}]`);
                    }
                })
            );
        } else {
            throw new Error('Create Team form is invalid');
        }
    }
}
