import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from '../../forms.interface';
import { FormsService } from '../../forms.service';

@Component({
    selector: 'jmcc-multi-checkbox',
    templateUrl: './multi-checkbox.component.html',
    styleUrls: ['./multi-checkbox.component.scss']
})
export class MultiCheckboxComponent implements OnInit {

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    enabled = true;

    @Input()
    options: SelectOption[] = [];

    @Input()
    control: FormControl;

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    required: boolean;

    @Input()
    tooltip: string;

    @Input()
    tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';

    @Input()
    displayOrientation: 'vertical' | 'horizontal' = 'horizontal';

    constructor(private formsService: FormsService) { }

    ngOnInit(): void {
        this.errorMessages = this.formsService.generateErrorMessages(this.errorMessages, this.label);
        this.setDisabledState(!this.enabled);
    }

    setDisabledState(isDisabled: boolean): void {
        this.formsService.setDisabledState(this.control, isDisabled);
    }

    getStatus(): string {
        return this.formsService.getStatus(this.control);
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.control, this.errorMessages);
    }

    isLabelSelected(labelId: string): boolean {
        return this.control.value.some((label: string) => label === labelId);
    }

    toggle(labelId: string) {
        this.control.markAsTouched();
        const values: string[] = [...this.control.value];
        const index = values.findIndex(label => label === labelId);
        if (index !== -1) {
            values.splice(index, 1);
        } else {
            values.push(labelId);
        }
        this.control.setValue(values);
    }
}
