import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateToken } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetCommentsRequest, GetCommentsResponse, IComment } from '../interfaces/comment.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { ContextService } from '../services/context.service';
import { CommentsActions } from './comments.actions';

export class CommentsStateModel {
    [x: string]: IComment[];
}

const COMMENTS_STATE_TOKEN = new StateToken<CommentsStateModel>('comments');

@State<CommentsStateModel>({
    name: COMMENTS_STATE_TOKEN,
    defaults: {}
})
@Injectable()
export class CommentsState {

    constructor(private contextService: ContextService) { }

    static comments(objectId: string) {
        return createSelector([CommentsState],
            (state: CommentsStateModel): IComment[] => state[objectId]
        );
    }

    static numberOfComments(objectId: string) {
        return createSelector([CommentsState],
            (state: CommentsStateModel): number => state[objectId]?.length || 0
        );
    }

    @Action(CommentsActions.GetComments)
    getComments(ctx: StateContext<CommentsStateModel>, action: CommentsActions.GetComments): Observable<GetCommentsResponse> {
        if (this.isValidRequest(action.request)) {
            const hasExistingComments = this.hasExistingComments(ctx.getState(), action.request.objectId) ||
            this.hasExistingComments(ctx.getState(), action.request.objectIds) ||
            this.hasExistingComments(ctx.getState(), action.request.commentId) ||
            this.hasExistingComments(ctx.getState(), action.request.userId);
            if (action.forceReload || !hasExistingComments) {
                return this.contextService.getComments(action.request).pipe(
                    tap((result: GetCommentsResponse) => {
                        if (result.comments) {
                            ctx.patchState(result.comments);
                        }
                    })
                );
            }
        }
    }

    @Action(CommentsActions.SaveComment)
    saveComment(ctx: StateContext<CommentsStateModel>, action: CommentsActions.SaveComment): Observable<IGenericServerResponse> {
        if (action.comment) {
            return this.contextService.saveComment(action.comment, action.saveType).pipe(
                tap((result: IGenericServerResponse) => {
                    if (!result.success) {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(CommentsActions.DeleteComment)
    deleteComment(ctx: StateContext<CommentsStateModel>, action: CommentsActions.DeleteComment): Observable<IGenericServerResponse> {
        if (action.comment) {
            return this.contextService.deleteComment(action.comment.commentId, action.comment.objectId, action.comment.objectType).pipe(
                tap((result: IGenericServerResponse) => {
                    if (!result.success) {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    private isValidRequest(request: GetCommentsRequest): boolean {
        return request && (!!request.commentId || !!request.objectId || !!request.objectIds || !!request.userId);
    }

    private hasExistingComments(state: CommentsStateModel, id: string | string[]): boolean {
        if (Array.isArray(id)) {
            return id.map(objectId => state[objectId]).every(comment => !!comment);
        }
        return id && !!state[id];
    }
}
