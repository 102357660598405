import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { CampaignFormModel, ICampaign, ISaveCampaignResponse } from '../interfaces/promotion/campaign.interface';
import { CampaignActions } from './campaigns.actions';
import { PromotionDashboardActions } from './promotion-dashboard.actions';
import { SelectOption } from '../../@forms/forms.interface';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { CampaignService } from '../services/campaign.service';

export interface CampaignCardStatus {
    campaignId: string;
    expanded: boolean;
}

export class CampaignStateModel {
    campaign: ICampaign;
    campaignForm: NgxsForm<CampaignFormModel>;
    activeCampaigns: ICampaign[];
    campaignCardStatuses: CampaignCardStatus[];
}

const CAMPAIGN_STATE_TOKEN = new StateToken<CampaignStateModel>('campaign');

@State<CampaignStateModel>({
    name: CAMPAIGN_STATE_TOKEN,
    defaults: {
        campaign: null,
        campaignForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        activeCampaigns: null,
        campaignCardStatuses: [],
    },
})
@Injectable()
export class CampaignState {

    constructor(private campaignService: CampaignService, private store: Store) {
    }

    static campaignById(campaignId: string) {
        return createSelector([CampaignState], (state: CampaignStateModel): ICampaign =>
            state.activeCampaigns?.find(campaign => campaign.campaignId === campaignId));
    }

    @Selector()
    static campaign(state: CampaignStateModel) {
        return state.campaign;
    }

    @Selector()
    static activeCampaignsSelectOptions(state: CampaignStateModel): SelectOption[] {
        return state.activeCampaigns?.map(c => ({ key: c.campaignId, displayValue: c.campaignName }));
    }

    @Selector()
    static campaignCardStatuses(state: CampaignStateModel): CampaignCardStatus[] {
        return state.campaignCardStatuses;
    }

    @Action(CampaignActions.LoadCampaign)
    loadCampaign(ctx: StateContext<CampaignStateModel>, action: CampaignActions.LoadCampaign): Observable<{ campaign: ICampaign }> {
        return this.campaignService.loadCampaign(action.id).pipe(
            tap((result: { campaign: ICampaign }) => {
                if (result) {
                    ctx.patchState({
                        campaign: result.campaign,
                        campaignForm: {
                            model: {
                                campaignId: result.campaign.campaignId,
                                campaignName: result.campaign.campaignName,
                                color: result.campaign.color,
                                campaignDescription: result.campaign.description,
                            }
                        }
                    });
                }
            })
        );
    }

    @Action(CampaignActions.CampaignCardExpanded)
    updateCampaignCardStatus(ctx: StateContext<CampaignStateModel>, action: CampaignActions.CampaignCardExpanded) {
        const statuses = [];
        ctx.getState().campaignCardStatuses?.forEach(c => statuses.push(cloneDeep(c)));
        const item = statuses.find(s => s.campaignId === action.id);
        if (item) {
            item.expanded = action.expanded;
        } else {
            statuses.push({ campaignId: action.id, expanded: action.expanded });
        }
        ctx.patchState({
            campaignCardStatuses: statuses
        });
    }

    @Action(CampaignActions.LoadActiveCampaigns)
    loadActiveCampaigns(ctx: StateContext<CampaignStateModel>): Observable<{ campaigns: ICampaign[] }> {
        return this.campaignService.findActiveCampaigns().pipe(
            tap((result: { campaigns: ICampaign[] }) => {
                if (result) {
                    ctx.patchState({
                        activeCampaigns: result.campaigns
                    });
                }
            })
        );
    }


    @Action(CampaignActions.SaveCampaign)
    saveCampaign(ctx: StateContext<CampaignStateModel>, action: CampaignActions.SaveCampaign): Observable<ISaveCampaignResponse> {
        return this.campaignService.saveCampaign(action.campaign, action.create).pipe(
            tap((result: ISaveCampaignResponse) => {
                if (result.success) {
                    ctx.patchState({
                        campaign: result.campaign
                    });
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }

    @Action(CampaignActions.ClearCampaign)
    clearCampaign(ctx: StateContext<CampaignStateModel>) {
        ctx.patchState({
            campaign: null,
            campaignForm: {
                model: undefined
            }
        });
    }

    @Action(CampaignActions.DeleteCampaign)
    deleteCampaign(ctx: StateContext<CampaignStateModel>, action: CampaignActions.DeleteCampaign): Observable<IGenericServerResponse> {
        return this.campaignService.deleteCampaign(action.id).pipe(
            tap((result: IGenericServerResponse) => {
                if (result.success) {
                    this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }

    @Action(CampaignActions.ArchiveCampaign)
    archiveCampaign(ctx: StateContext<CampaignStateModel>, action: CampaignActions.ArchiveCampaign): Observable<IGenericServerResponse> {
        return this.campaignService.archiveCampaign(action.id).pipe(
            tap((result: IGenericServerResponse) => {
                if (result.success) {
                    this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }
}
