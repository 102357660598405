export class StringUtil {
    static snakeCaseToWords(value: string): string {
        return value
            ?.split('_')
            .map(word => {
                const firstChar = word[0].toUpperCase();
                const remainingChars = word.substring(1).toLowerCase();
                return firstChar + remainingChars;
            }).join(' ');
    }
}
