import { NgxsModuleOptions } from '@ngxs/store';
import { environment } from '../environments/environment';

export const ngxsConfig: NgxsModuleOptions = {
    developmentMode: !environment.production,
    selectorOptions: {
        // These Selector Settings are recommended in preparation for NGXS v4
        // (See https://www.ngxs.io/concepts/select for their effects)
        suppressErrors: false,
        injectContainerState: false,
    },
    compatibility: {
        strictContentSecurityPolicy: true,
    },
};
