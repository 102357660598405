export interface Money {
    amount: number;
    currency: string;
}

export class MoneyUtil {
    static parse(value: string): Money {
        if (!value) {
            return {
                amount: undefined,
                currency: undefined
            };
        }

        // Assumes Java Joda Money format - Example: 'USD 199.99'
        const parts = value.split(' ');
        const currency = parts[0];
        const amount = parseFloat(parts[1]);

        return {
            currency,
            amount
        };
    }
}
