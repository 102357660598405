import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Component({
    selector: 'jmcc-oauth2-callback',
    template: ''
})
export class Oauth2CallbackComponent implements OnInit {

    constructor(private authService: NbAuthService, private router: Router) { }

    ngOnInit() {
        this.authService.authenticate('jmccOAuth').subscribe(() => {
            this.router.navigate(['/']);
        });
    }
}
