import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { IApp } from '../interfaces/app.interface';
import { ContextService } from '../services/context.service';
import { AuthorizedActions } from './authorized.actions';

export class AppStateModel {
    enabledApps: IApp[];
}

const APP_STATE_TOKEN = new StateToken<AppStateModel>('app');

@State<AppStateModel>({
    name: APP_STATE_TOKEN,
    defaults: {
        enabledApps: [],
    },
})
@Injectable()
export class AppState {

    constructor(private contextService: ContextService) {
    }

    @Selector()
    static enabledApps(state: AppStateModel) {
        return state.enabledApps;
    }

    @Action(AuthorizedActions.LoggedIn)
    loggedIn(ctx: StateContext<AppStateModel>) {
        return this.contextService.getEnabledApps().pipe(
            tap(result => ctx.patchState({
                enabledApps: result.apps
            }))
        );
    }
}
