import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { DashboardService } from '../services/dashboard.service';
import { Log } from '../../@theme/directives/logger';
import { DashboardActions } from './dashboard.actions';
import { IDashboard } from '../interfaces/dashboard.interface';
import { IDashboardWidgetDefinition } from '../interfaces/dashboard-widget-definition.interface';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizedState } from './authorized.state';
import { IUser } from '../interfaces/user.interface';

export class DashboardStateModel {
    public dashboard: IDashboard;
    public availableWidgets: IDashboardWidgetDefinition[];
}

const DASHBOARD_STATE_TOKEN = new StateToken<DashboardStateModel>('dashboard');

@State<DashboardStateModel>({
    name: DASHBOARD_STATE_TOKEN,
    defaults: {
        availableWidgets: [],
        dashboard: { dashboardWidgets: [] },
    },
})
@Injectable()
export class DashboardState {

    constructor(private dashboardService: DashboardService,
        private store: Store) {
    }

    @Selector()
    static dashboard(state: DashboardStateModel): IDashboard {
        return state.dashboard;
    }

    @Selector()
    static availableWidgets(state: DashboardStateModel): IDashboardWidgetDefinition[] {
        return state.availableWidgets;
    }

    @Log()
    @Action(DashboardActions.LoadDashboard)
    loadDashboard(ctx: StateContext<DashboardStateModel>): Observable<{ dashboard: IDashboard }> {
        const user: IUser = this.store.selectSnapshot(AuthorizedState.authorizedUser);
        if (user) {
            return this.dashboardService.getDashboard(user.dashboardId).pipe(
                tap((result: { dashboard: IDashboard }) => {
                    ctx.patchState({
                        dashboard: result.dashboard
                    });
                })
            );
        }
    }

    @Log()
    @Action(DashboardActions.LoadAvailableWidgets)
    loadAvailableWidgets(ctx: StateContext<DashboardStateModel>): Observable<{ availableWidgets: IDashboardWidgetDefinition[] }> {
        return this.dashboardService.getAvailableWidgets().pipe(
            tap((result: { availableWidgets: IDashboardWidgetDefinition[] }) => {
                ctx.patchState({
                    availableWidgets: result.availableWidgets
                });
            })
        );
    }

    @Log()
    @Action(DashboardActions.SaveDashboard)
    saveDashboard(ctx: StateContext<DashboardStateModel>, action: DashboardActions.SaveDashboard): Observable<{ dashboard: IDashboard }> {
        return this.dashboardService.saveDashboard(action.dashboard).pipe(
            tap((result: { dashboard: IDashboard }) => {
                ctx.patchState({
                    dashboard: result.dashboard
                });
            })
        );
    }
}
