import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IGetOfferTemplatesResponse, IGetRewardCategoryConfigsResponse, IOfferSelectOptions, IOfferTemplates } from '../interfaces/promotion/promotion-offers.interface';
import { PromotionService } from '../services/promotion.service';
import { PromotionOffersActions } from './promotion-offers.actions';
import produce from 'immer';
import { ITEM, QualificationItemsRewardPreference, QualificationTypeCode, RewardCategoryTypeCode, RewardTypeCode, TRANS } from '../interfaces/promotion/promotion.interface';

export class PromotionOffersStateModel {
    templates: IOfferTemplates;
    offerSelectOptions: IOfferSelectOptions;
    offerTypeDecimalInputs: string[];
}

const PROMOTION_OFFERS_STATE_TOKEN = new StateToken<PromotionOffersStateModel>('promotionOffers');

@State<PromotionOffersStateModel>({
    name: PROMOTION_OFFERS_STATE_TOKEN,
    defaults: {
        templates: {},
        offerSelectOptions: {
            qualificationLevelOptions: [
                { key: TRANS, displayValue: 'Transaction' },
                { key: ITEM, displayValue: 'Item' }
            ],
            qualificationTypeOptions: [
                { key: QualificationTypeCode.AMT, displayValue: 'Amount' },
                { key: QualificationTypeCode.AMT_TOTAL, displayValue: 'Amount Total' },
                { key: QualificationTypeCode.QTY, displayValue: 'Quantity' }
            ],
            qualificationItemsRewardPreference: [
                { key: QualificationItemsRewardPreference.ALLOW, displayValue: 'Allow' },
                { key: QualificationItemsRewardPreference.PREVENT, displayValue: 'Prevent' },
                { key: QualificationItemsRewardPreference.REQUIRE, displayValue: 'Require' }
            ],
            rewardLevelOptions: [
                { key: TRANS, displayValue: 'Transaction' },
                { key: ITEM, displayValue: 'Item' }
            ],
            rewardTypeOptions: [
                { key: RewardTypeCode.AMT, displayValue: 'Amount Off' },
                { key: RewardTypeCode.PCT, displayValue: 'Percent Off' },
                { key: RewardTypeCode.PRICE, displayValue: 'Price' },
                { key: RewardTypeCode.PRICE_TOTAL, displayValue: 'Total Price' }
            ],
            rewardCategoryOptions: [],
            rewardCategoryTypeOptions: [
                { key: RewardCategoryTypeCode.AMT, displayValue: 'Amount' },
                { key: RewardCategoryTypeCode.PCT, displayValue: 'Percent Off' },
            ]
        },
        offerTypeDecimalInputs: [QualificationTypeCode.AMT, QualificationTypeCode.AMT_TOTAL, RewardTypeCode.PRICE, RewardTypeCode.PRICE_TOTAL]
    }
})
@Injectable()
export class PromotionOffersState {

    constructor(private promotionService: PromotionService) {
    }

    @Selector()
    static templates(state: PromotionOffersStateModel): IOfferTemplates {
        return state.templates;
    }

    @Selector()
    static offerSelectOptions(state: PromotionOffersStateModel): IOfferSelectOptions {
        return state.offerSelectOptions;
    }

    @Selector()
    static offerTypeDecimalInputs(state: PromotionOffersStateModel): string[] {
        return state.offerTypeDecimalInputs;
    }

    @Action(PromotionOffersActions.LoadOfferTemplates)
    loadOfferTemplates(ctx: StateContext<PromotionOffersStateModel>): Observable<IGetOfferTemplatesResponse> {
        return this.promotionService.getOfferTemplates().pipe(
            tap((result: IGetOfferTemplatesResponse) => {
                ctx.patchState({
                    templates: result.idToTemplateMap
                });
            })
        );
    }

    @Action(PromotionOffersActions.LoadRewardCategoryConfigs)
    loadRewardCategoryConfigs(ctx: StateContext<PromotionOffersStateModel>): Observable<IGetRewardCategoryConfigsResponse> {
        return this.promotionService.getRewardCategoryConfigs().pipe(
            tap((result: IGetRewardCategoryConfigsResponse) => {
                if (result?.rewardCategoryConfigs?.length) {
                    ctx.setState(produce((draft) => {
                        draft.offerSelectOptions.rewardCategoryOptions = result.rewardCategoryConfigs.map(
                            (config) => ({ displayValue: config.displayName, key: config.categoryId }));
                    }));
                }
            })
        );
    }

}
