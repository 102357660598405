<ng-template><ng-content select="jmcc-step"></ng-content></ng-template>
<div class="step-content">
    <ng-container [ngTemplateOutlet]="active?.content"></ng-container>
</div>
<div class="stepper-nav-container">
    <div class="steps">
        <ng-container *ngFor="let step of steps; let index = index">
            <div class="step">
                <div class="step-index" [ngClass]="{'completed': (step.visited && !isActiveStep(step)) || step.cancelled || step.completed, 'active': isActiveStep(step) && !step.cancelled && !step.completed,
                'success': step.status === 'success',
                'warning': step.status === 'warning',
                'danger': step.status === 'danger'}">
                    <nb-icon *ngIf="(step.visited && !isActiveStep(step)) || step.completed" icon="checkmark-outline"></nb-icon>
                    <nb-icon *ngIf="step.cancelled" icon="close-outline"></nb-icon>
                    <span *ngIf="!step.completed && !step.loading && !step.cancelled && !(step.visited && !isActiveStep(step))">{{ index + 1 }}</span>
                    <nb-spinner [status]="step.status" *ngIf="step.loading" class="step-spinner" [message]="''" [size]="'small'"></nb-spinner>
                </div>
                <span class="step-label label">{{step.label}}</span>
                <span class="step-label label">{{step.subLabel}}</span>
            </div>
            <div *ngIf="index !== steps.length - 1" class="step-connector" [ngClass]="{'active': index < activeIndex, 'success': step.status === 'success',
                'warning': step.status === 'warning',
                'danger': step.status === 'danger' }">
                <div *ngIf="step.connectorLabelTop" class="step-connector-label label-top">{{step.connectorLabelTop}}</div>
                <div *ngIf="step.connectorLabelBottom" class="step-connector-label label-bottom">{{step.connectorLabelBottom}}</div>
            </div>
        </ng-container>
    </div>
    <ng-content select="jmcc-stepper-nav-buttons"></ng-content>
</div>
