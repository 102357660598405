import { IDashboard } from '../interfaces/dashboard.interface';

export namespace DashboardActions {

    export class LoadDashboard {
        static readonly type = '[Dashboard] Load Dashboard';
    }

    export class LoadAvailableWidgets {
        static readonly type = '[Dashboard] Load Available Widgets';
    }

    export class SaveDashboard {
        static readonly type = '[Dashboard] Save Dashboard';
        constructor(public dashboard: IDashboard) {
        }
    }

}
