interface LoggerParams {
    type?: 'log' | 'trace' | 'warn' | 'info' | 'debug' | 'error';
    inputs?: boolean;
    outputs?: boolean;
    printInProd?: boolean;
    timeStamp?: boolean;
}

// Default values when just @Log() is used
const defaultParams: Required<LoggerParams> = {
    type: 'log',
    inputs: true,
    outputs: true,
    printInProd: false,
    timeStamp: true
};

/**
 * @Log() appended on top of method to console.log() input, output of the method attached to.
 * @param params optional type of LoggerParams which can be passed to @Log()
 * If none is given, defaultParams are used.
 */
export function Log(params?: LoggerParams): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {

    const options: Required<LoggerParams> = {
        type: params?.type || defaultParams.type,
        inputs: params?.inputs === undefined ? defaultParams.inputs : params.inputs,
        outputs: params?.outputs === undefined ? defaultParams.outputs : params.outputs,
        printInProd: params?.printInProd === undefined ? defaultParams.printInProd : params.printInProd,
        timeStamp: params?.timeStamp === undefined ? defaultParams.timeStamp : params.timeStamp
    };

    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {

        // Original method
        const original = descriptor.value;

        // Know that this is not the original variable name
        // ParamNames are as they appear when passed to the method
        const paramNames = descriptor.value.toString().replace(/\s/g, '').match(/\((.*?)\)/)[1].split(',');

        descriptor.value = function (...args: any[]) {

            // Inputvalues
            const paramValues: any[] = args.map(v => v);

            // Make the object to be printed
            let nameAndValue: any = {};
            if (paramValues.length === paramNames.length) {
                for (let i = 0; i < paramNames.length; i++) {
                    nameAndValue = { ...nameAndValue, ...{ [paramNames[i]]: paramValues[i] } };
                }
            }

            const result = original.apply(this, args);

            let timeStamp = '';
            if (options.timeStamp) {
                timeStamp = formatConsoleDate(new Date());
            }

            /* eslint-disable no-console */
            if (params?.inputs && !params?.outputs) {
                // Only Inputs
                console[options.type](timeStamp, original.name + ' -> IN: ', nameAndValue);
            } else if (!params?.inputs && params?.outputs) {
                // Only Outputs
                console[options.type](timeStamp, original.name + ' -> OUT: ', result);
            } else {
                // Input and Output
                console[options.type](timeStamp, original.name + ' -> IN: ', nameAndValue, ' OUT: ', result);
            }
            /* eslint-enable no-console */
            return result;
        };
    };
}

export function formatConsoleDate(date: Date) {
    const hour = date.getHours();
    const displayHour = hour < 10 ? '0' + hour : hour;

    const minutes = date.getMinutes();
    const displayMinutes = minutes < 10 ? '0' + minutes : minutes;

    const seconds = date.getSeconds();
    const displaySeconds = seconds < 10 ? '0' + seconds : seconds;

    const milliseconds = date.getMilliseconds();
    const displayMillis = ('00' + milliseconds).slice(-3);

    return `[${displayHour}:${displayMinutes}:${displaySeconds}.${displayMillis}]`;
}
