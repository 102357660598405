import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { StepComponent } from './step.component';

@Component({
    selector: 'jmcc-stepper',
    styleUrls: ['./stepper.component.scss'],
    templateUrl: './stepper.component.html',
})
export class StepperComponent {

    @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

    @Output() validateNext: EventEmitter<any> = new EventEmitter<any>();

    @Input() nextButtonText: string;
    @Input() nextButtonEnabled = true;
    @Input() previousButtonText: string;
    @Input() previousButtonEnabled = true;

    protected _activeIndex = 0;
    @Input()
    get activeIndex() {
        return this._activeIndex;
    }
    set activeIndex(index: number) {
        if (!this.steps?.length) {
            return;
        }
        this.steps.toArray()[this._activeIndex].visited = true;
        this._activeIndex = index;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Input()
    get active(): StepComponent {
        return this.steps.length ? this.steps.toArray()[this.activeIndex] : undefined;
    }
    set active(step: StepComponent) {
        if (!this.steps.length) {
            return;
        }
        this.activeIndex = this.steps.toArray().indexOf(step);
    }

    isActiveStep(step: StepComponent): boolean {
        return this.active === step;
    }
}
