import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusinessUnitGroupResponse, IBusinessUnitGroup } from '../interfaces/business-unit-group.interface';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { Observable } from 'rxjs';
import { ICountry } from '../interfaces/country.interface';

@Injectable()
export class BusinessUnitService {

    constructor(private http: HttpClient) {
    }

    getBusinessUnitGroups(): Observable<{ businessUnitGroups: IBusinessUnitGroup[] }> {
        return this.http.get<{ businessUnitGroups: IBusinessUnitGroup[] }>('app/businessUnits/groups');
    }

    getBusinessUnits(): Observable<{ businessUnits: IBusinessUnit[] }> {
        return this.http.get<{ businessUnits: IBusinessUnit[] }>('app/businessUnits/all');
    }

    getCountries(): Observable<{ countries: ICountry[] }> {
        return this.http.get<{ countries: ICountry[] }>('app/businessUnits/countries');
    }

    createBusinessUnitGroup(businessUnitGroupId: string, businessUnitGroupName: string, businessUnits: IBusinessUnit[]): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>('app/businessUnits/groups/create', { businessUnitGroupId, businessUnitGroupName, businessUnits });
    }

    deleteBusinessUnitGroup(businessUnitGroupId: string): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>('app/businessUnits/groups/delete', { businessUnitGroupId });
    }

    updateBusinessUnitGroup(businessUnitGroupId: string, businessUnitGroupName: string, businessUnits: IBusinessUnit[]): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>('app/businessUnits/groups/update', { businessUnitGroupId, businessUnitGroupName, businessUnits });
    }

}
