import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ValidatePromotionResponse } from '../interfaces/promotion/promotion-validation.interface';
import { PromotionDashboardStateModel } from '../state/promotion-dashboard.state';
import { ISaveStagedPromotionResponse, IStagedPromotion, SparkTypeCode } from '../interfaces/promotion/staged-promotion.interface';
import { IGetOfferTemplatesResponse, IGetRewardCategoryConfigsResponse } from '../interfaces/promotion/promotion-offers.interface';
import { ISparkOption } from '../interfaces/promotion/spark-option.interface';
import { PromotionHistoryStateModel } from '../state/promotion-history.state';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { IGetPromotionGroupsResponse } from '../interfaces/promotion/promotion-group.interface';
import { AdvancedSearchRule } from '../interfaces/promotion/advanced-search-rule.interface';
import { AttributeLevel, GetAllQualificationAttributeConfigsResponse, IQualificationAttributeConfig } from '../interfaces/promotion/promotion-qualification-attribute.interface';
import { SelectOption } from '../../@forms/forms.interface';
import { Moment } from 'moment';
import { ObjectTypeCode } from '../interfaces/promotion/object-type-code';


@Injectable()
export class PromotionService {

    readonly promotionServicePrefix = 'app/promotion';

    constructor(private http: HttpClient) {
    }

    getDashboardData(searchRules?: AdvancedSearchRule[], matchType?: string): Observable<PromotionDashboardStateModel> {
        return this.http.put<PromotionDashboardStateModel>('app/promotion/dashboardData', { searchRules, matchType });
    }

    getHistoricalData(startDate: Moment, endDate: Moment): Observable<PromotionHistoryStateModel> {
        return this.http.post<PromotionHistoryStateModel>(this.promotionServicePrefix + '/historicalData', { startDate, endDate });
    }

    validatePromotion(stagedPromotion: IStagedPromotion): Observable<ValidatePromotionResponse> {
        return this.http.post<ValidatePromotionResponse>(this.promotionServicePrefix + '/validate', { stagedPromotion });
    }

    saveStagedPromotion(stagedPromotion: IStagedPromotion, create: boolean): Observable<ISaveStagedPromotionResponse> {
        return this.http.put<ISaveStagedPromotionResponse>(this.promotionServicePrefix + '/stagedPromotion', { stagedPromotion, create });
    }

    submitForApproval(stagedPromotion: IStagedPromotion): Observable<ISaveStagedPromotionResponse> {
        return this.http.put<ISaveStagedPromotionResponse>(this.promotionServicePrefix + '/submitForApproval', { stagedPromotion });
    }

    backToDraft(stagedPromotion: IStagedPromotion): Observable<ISaveStagedPromotionResponse> {
        return this.http.put<ISaveStagedPromotionResponse>(this.promotionServicePrefix + '/backToDraft', { stagedPromotion });
    }

    reviewsCompleted(stagedPromotion: IStagedPromotion): Observable<ISaveStagedPromotionResponse> {
        return this.http.put<ISaveStagedPromotionResponse>(this.promotionServicePrefix + '/reviewsCompleted', { stagedPromotion });
    }

    deletePromotion(id: string): Observable<IGenericServerResponse> {
        return this.http.delete<IGenericServerResponse>(`${this.promotionServicePrefix}/delete/${id}`);
    }

    findStagedPromotion(id: string): Observable<IStagedPromotion> {
        return this.http.get<IStagedPromotion>(`${this.promotionServicePrefix}/find/${id}`);
    }

    getSparkOptions(): Observable<ISparkOption[]> {
        return this.getAllQualificationAttributeConfigs().pipe(map(response => {
            const sparkOptions: ISparkOption[] = [
                {
                    type: SparkTypeCode.LOYALTY, displayName: 'Loyalty', fields: [], limit: 1
                },
                {
                    type: SparkTypeCode.COUPON, displayName: 'Coupon', fields: [
                        {
                            fieldId: 'code', fieldType: 'text',
                            label: 'Code', placeholder: 'Code',
                            required: true
                        },
                    ],
                    limit: 1
                },
                {
                    type: SparkTypeCode.SINGLE_USE_PROMO_CODE, displayName: 'Single Use Promo Code Import', fields: [
                        {
                            fieldId: 'singleUse', fieldType: 'upload',
                            label: 'Code(s)', placeholder: 'File must be a .csv format to be parsed correctly. See the file content example. ' +
                                'Note: CODE is a required field, but LOYALTY_NUMBER is not.',
                            required: true, fileType: '.csv'
                        },
                    ],
                    limit: 1
                },
                {
                    type: SparkTypeCode.SINGLE_USE_PROMO_CODE_GENERATE, displayName: 'Single Use Promo Code Generate', fields: [
                        {
                            fieldId: 'promoCodeType', fieldType: 'select',
                            label: 'Type', placeholder: 'Choose the promo code type',
                            required: true, multiSelect: false,
                            options: [{ key: 'universal', displayValue: 'Universal' }, { key: 'unique', displayValue: 'Unique' }]
                        },
                        {
                            fieldId: 'numCodes', fieldType: 'number',
                            label: 'Number of Codes', placeholder: 'Enter number of codes',
                            required: true,
                        },
                        {
                            fieldId: 'characterSet', fieldType: 'select',
                            label: 'Character Set', placeholder: 'Choose the character set',
                            required: true, multiSelect: false,
                            options: [
                                { key: 'numeric', displayValue: 'Numeric' },
                                { key: 'alphabetic', displayValue: 'Alphabetic' },
                                { key: 'alphanumeric', displayValue: 'Alphanumeric' }
                            ]
                        },
                        {
                            fieldId: 'pattern', fieldType: 'text',
                            label: 'Pattern', placeholder: 'Code pattern',
                            required: true, hintText: 'Use # to represent a random character'
                        },
                        {
                            fieldId: 'loyalty', fieldType: 'upload',
                            label: 'Loyalty Numbers', placeholder: 'File must be a .txt format and loyalty numbers must be one per line for the file to be parsed correctly',
                            required: true
                        },
                        {
                            fieldId: 'downloadAvailable', fieldType: 'display',
                            label: 'Download Codes'
                        }
                    ],
                    limit: 1
                },
                {
                    type: SparkTypeCode.EMPLOYEE, displayName: 'Employee', fields: [], limit: 1
                }
            ];
            const transactionAttributeOptions: SelectOption[] = response.qualificationAttributeConfigs
                ?.filter(attributeConfig => attributeConfig.attributeLevel === AttributeLevel.TRANS)
                .map(attributeConfig => ({ key: attributeConfig.attributeId, displayValue: attributeConfig.displayName }));
            if (transactionAttributeOptions?.length) {
                sparkOptions.push({
                    type: SparkTypeCode.TRANSACTION_ATTRIBUTE, displayName: 'Transaction Attribute', fields: [
                        {
                            fieldId: 'attribute', fieldType: 'select',
                            label: 'Attribute', placeholder: 'Choose an attribute',
                            required: true, multiSelect: false, options: transactionAttributeOptions
                        }
                    ]
                });
            }
            return sparkOptions;
        }));
    }

    savePromoCodeFile(filename: string, url: string, promotionId: string): Observable<number> {
        return this.http.get(url, { responseType: 'text' }).pipe(switchMap(fileContent =>
            this.http.put<IGenericServerResponse>(this.promotionServicePrefix + '/codes/save', {
                promotionId,
                filename,
                fileContent
            }))).pipe(map(response => response.success ? 1 : -1));
    }

    getOfferTemplates(): Observable<IGetOfferTemplatesResponse> {
        return this.http.get<IGetOfferTemplatesResponse>(this.promotionServicePrefix + '/offerTemplates');
    }

    getPromotionGroups(): Observable<IGetPromotionGroupsResponse> {
        return this.http.get<IGetPromotionGroupsResponse>(this.promotionServicePrefix + '/promotionGroups');
    }

    getAllQualificationAttributeConfigs(): Observable<GetAllQualificationAttributeConfigsResponse> {
        return this.http.get<GetAllQualificationAttributeConfigsResponse>(this.promotionServicePrefix + '/qualificationAttributeConfig/getAll');
    }

    saveQualificationAttributeConfig(qualificationAttributeConfig: IQualificationAttributeConfig): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.promotionServicePrefix + '/qualificationAttributeConfig/save', { qualificationAttributeConfig });
    }

    deleteQualificationAttributeConfig(attributeId: string): Observable<IGenericServerResponse> {
        return this.http.delete<IGenericServerResponse>(`${this.promotionServicePrefix}/qualificationAttributeConfig/delete?attributeId=${attributeId}`);
    }

    getRewardCategoryConfigs(): Observable<IGetRewardCategoryConfigsResponse> {
        return this.http.get<IGetRewardCategoryConfigsResponse>(this.promotionServicePrefix + '/rewardCategoryConfig/getAll');
    }

    initializeWorkflowStep(step: number, objectId: string): Observable<string> {
        return this.http.put<string>(this.promotionServicePrefix + '/initializeStep', { step, objectId, objectType: ObjectTypeCode.PROMOTION });
    }

    downloadPromoCodesForPromotion(promotionId: string): void {
        this.http.get(`/rest/promotion/promoCodes/download/${promotionId}`, { responseType: 'blob' }).subscribe((response) => {
            const file = document.createElement('a');
            file.href = URL.createObjectURL(response);
            file.download = promotionId + 'PromoCodes';
            file.click();
        });
    }
}
