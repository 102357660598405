import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../../@core/interfaces/address.interface';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(address: IAddress, includeAttention: boolean = false, includeCountry: boolean = true): string {
        if (address) {
            return this.formatUSAddress(address, includeAttention, includeCountry);
        }
        return '';
    }

    formatUSAddress(address: IAddress, includeAttention: boolean, includeCountry: boolean) {
        let addressString = '';
        if (includeAttention && address.attention) {
            addressString += `${address.attention}`;
        }
        if (address.line1) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.line1}`;
        }
        if (address.line2) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.line2}`;
        }
        if (address.line3) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.line3}`;
        }
        if (address.line4) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.line4}`;
        }
        if (address.city) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.city}`;
        }
        if (address.stateId) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.stateId}`;
        }
        if (address.postalCode) {
            addressString = this.addSpacing(addressString, false);
            addressString += `${address.postalCode}`;
        }
        if (includeCountry && address.countryId) {
            addressString = this.addSpacing(addressString, true);
            addressString += `${address.countryId}`;
        }
        return addressString;
    }

    private addSpacing(addressString: string, includeComma: boolean) {
        if (addressString.length !== 0) {
            if (includeComma) {
                addressString += ',';
            }
            addressString += ' ';
        }
        return addressString;
    }
}
