import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDatepickerDirective } from '@nebular/theme';
import { FormsService } from '../../forms.service';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'jmcc-date-timepicker',
    templateUrl: './date-timepicker.component.html',
    styleUrls: ['./date-timepicker.component.scss']
})
export class DateTimepickerComponent implements OnInit, OnChanges {

    @ViewChild(NbDatepickerDirective) datePickerDirective: NbDatepickerDirective<Date>;

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    control: FormControl<Moment | string>;

    @Input()
    placeholder: string;

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    required: boolean;

    @Input()
    minDate: Moment;

    @Input()
    maxDate: Moment;

    constructor(private formsService: FormsService) { }

    ngOnInit(): void {
        this.errorMessages = this.formsService.generateErrorMessages(this.errorMessages, this.label);
        if (typeof this.control.value === 'string') {
            const d = moment(new Date(this.control.value));
            if (d.isValid()) {
                this.control.setValue(d);
            }
        }
    }

    ngOnChanges(): void {
        if (!this.minDate?.isValid()) {
            this.minDate = undefined;
        }
        if (!this.maxDate?.isValid()) {
            this.maxDate = undefined;
        }
        if (this.datePickerDirective) {
            setTimeout(() => {
                this.control.setErrors(this.datePickerDirective.validate());
            });
        }
    }

    getStatus(): string {
        return this.formsService.getStatus(this.control);
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.control, this.errorMessages);
    }

    focusInput(): void {
        const input: HTMLInputElement = document.querySelector('#' + this.id);
        input?.focus();
    }
}
