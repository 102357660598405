<nb-card class="local-nav">
    <nb-card-body class="local-nav-body">
        <div class="main-section">
            <a *ngIf="logoImage" class="logo" [ngClass]="{'clickable': !!logoImageNavLink}" (click)="navigateLogo()">
                <img class="logo-img" [src]="logoImage" alt='jmc-central-local-nav-image'>
            </a>
            <nb-actions>
                <nb-action *ngFor="let menuItem of navMenu; let index = index">
                    <a *ngIf="menuItem?.children" class="local-nav-menu-item" [nbContextMenu]="menuItem.children"
                       [ngClass]="{'active': activeMenuItem === menuItem}" [id]="'navBarMenuItem'+index">
                        <nb-icon *ngIf="menuItem.icon" class="{{menuItem.icon}}" [icon]="menuItem.icon"></nb-icon>
                        <span>{{ menuItem.title }}</span>
                    </a>
                    <a *ngIf="!menuItem?.children" class="local-nav-menu-item" (click)="menuItemClicked(menuItem)"
                       [ngClass]="{'active': activeMenuItem === menuItem}" [id]="'navBarMenuItem'+index">
                        <nb-icon *ngIf="menuItem?.icon" class="nav-icon {{menuItem.icon}}"
                                 [icon]="menuItem.icon"></nb-icon>
                        <span>{{ menuItem?.title }}</span>
                    </a>
                </nb-action>
            </nb-actions>
        </div>
        <div *ngIf="options?.length > 0" class="options-section">
            <span>{{ optionTitle }}:</span>
            <nb-select id="navBarOptions" (selectedChange)="onSelectChange($event)" [selected]="selectedOption ? selectedOption : options[0].key" [size]="'small'">
                <nb-option [id]="'navBarOption' + index" *ngFor="let option of options; let index = index" [value]="option.key">{{option.displayValue}}</nb-option>
            </nb-select>
        </div>
    </nb-card-body>
</nb-card>
