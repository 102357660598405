import { AfterViewChecked, Component, Input, OnChanges } from '@angular/core';
import { ITimelineEvent } from './timeline-event.interface';

@Component({
    selector: 'jmcc-timeline',
    styleUrls: ['./timeline.component.scss'],
    templateUrl: './timeline.component.html',
})
export class TimelineComponent implements OnChanges, AfterViewChecked {

    @Input()
    events: ITimelineEvent[];

    @Input()
    scrollToBottom: boolean;

    needsScroll = true;

    ngOnChanges(): void {
        this.events?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    ngAfterViewChecked(): void {
        if (this.needsScroll && this.events?.length && this.scrollToBottom) {
            const container = document.querySelector(`#element${this.events.length - 1}`);
            if (container) {
                container.scrollIntoView();
                this.needsScroll = false;
            }
        }
    }
}
