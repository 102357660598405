import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { IGetPromotionGroupsResponse, IPromotionGroup } from '../interfaces/promotion/promotion-group.interface';
import { Injectable } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import { PromotionGroupsActions } from './promotion-groups.actions';
import { tap } from 'rxjs/operators';

export class PromotionGroupsStateModel {
    groups: IPromotionGroup[];
    searchString: string;
}

const PROMOTION_GROUPS_STATE_TOKEN = new StateToken<PromotionGroupsStateModel>('promotionGroups');
@State<PromotionGroupsStateModel>({
    name: PROMOTION_GROUPS_STATE_TOKEN,
    defaults: {
        groups: [],
        searchString: null,
    }
})
@Injectable()
export class PromotionGroupsState {

    constructor(private promotionService: PromotionService) { }

    private static shouldShowGroup(state: PromotionGroupsStateModel, group: IPromotionGroup): boolean {
        if (state.searchString) {
            return group.groupName?.toLowerCase().includes(state.searchString);
        }
        return true;
    }

    @Selector()
    static groups(state: PromotionGroupsStateModel): IPromotionGroup[] {
        return state.groups?.filter(group => this.shouldShowGroup(state, group))
            .sort((group1, group2) => group1.priority - group2.priority);
    }

    @Selector()
    static searchString(state: PromotionGroupsStateModel): string {
        return state.searchString;
    }

    @Action(PromotionGroupsActions.GetPromotionGroups)
    getPromotionGroups(ctx: StateContext<PromotionGroupsStateModel>) {
        return this.promotionService.getPromotionGroups().pipe(
            tap((result: IGetPromotionGroupsResponse) => {
                if (result.promotionGroups) {
                    ctx.patchState({
                        groups: result.promotionGroups
                    });
                }
            })
        );
    }

    @Action(PromotionGroupsActions.SearchPromotionGroups)
    searchGroups(ctx: StateContext<PromotionGroupsStateModel>, action: PromotionGroupsActions.SearchPromotionGroups) {
        ctx.patchState({
            searchString: action.searchString?.toLowerCase()
        });
    }

    @Action(PromotionGroupsActions.ClearSearch)
    clearSearch(ctx: StateContext<PromotionGroupsStateModel>) {
        ctx.patchState({
            searchString: null
        });
    }
}
