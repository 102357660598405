import { Pipe, PipeTransform } from '@angular/core';
import { TransStatusCode } from '../../@core/interfaces/transaction.interface';
import { TransUtil } from '../../@core/utils/trans.util';

@Pipe({ name: 'transStatusCodeIcon' })
export class TransStatusCodeIconPipe implements PipeTransform {
    transform(transStatusCode: TransStatusCode): string {
        return TransUtil.getStatusCodeIcon(transStatusCode);
    }
}
