import { IUser } from '../interfaces/user.interface';

export namespace TeamActions {

    export class LoadTeam {
        static readonly type = '[Team] Load Team';
        constructor(public teamId: string) {
        }
    }

    export class UpdateAppAccessList {
        static readonly type = '[Team] Update App Access List';
        constructor(public applicationId: string, public accessType: string) {
        }
    }

    export class UpdateTeamMemberRole {
        static readonly type = '[Team] Update Team Member Role';
        constructor(public userId: string, public teamRole: string) {
        }
    }

    export class RemoveTeamMember {
        static readonly type = '[Team] Remove Team Member';
        constructor(public userId: string) {
        }
    }

    export class AddTeamMember {
        static readonly type = '[Team] Add Team Member';
        constructor(public user: IUser) {
        }
    }

    export class DeleteTeam {
        static readonly type = '[Team] Delete Team';
    }

    export class SaveChanges {
        static readonly type = '[Team] Save Changes';
    }

    export class CreateTeam {
        static readonly type = '[Team] Create Team';
    }
}
