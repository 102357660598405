import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormsService } from '../../forms.service';

@Component({
    selector: 'jmcc-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    control: FormControl;

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    tooltip: string;

    @Input()
    tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';

    constructor(private formsService: FormsService) { }

    ngOnInit(): void {
        this.errorMessages = this.formsService.generateErrorMessages(this.errorMessages, this.label);
    }

    getStatus(): string {
        return this.formsService.getStatus(this.control);
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.control, this.errorMessages);
    }

}
