import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxPlural' })
export class PluralPipe implements PipeTransform {

    transform(input: number, label: string, pluralLabel: string = ''): string {
        input = input || 0;
        if (pluralLabel) {
            pluralLabel = `${input} ${pluralLabel}`;
        } else if (label) {
            pluralLabel = `${input} ${label}s`;
        } else {
            pluralLabel = label;
        }
        return input === 1 ? `${input} ${label}` : pluralLabel;
    }
}
