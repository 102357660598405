<div class="nb-form-field" id="{{id}}">
    <label *ngIf="label" for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <jmcc-tooltip-icon-button *ngIf="label && tooltip" [icon]="'question-mark-circle-outline'" [tooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"></jmcc-tooltip-icon-button>
    <div [ngClass]="displayOrientation">
        <nb-checkbox *ngFor="let option of options" (checkedChange)="toggle(option.key)"
            [checked]="isLabelSelected(option.key)">
            {{option.displayValue}}
        </nb-checkbox>
    </div>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
