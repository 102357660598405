import { Action, Selector, State, StateContext, StateToken, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import { tap } from 'rxjs/operators';
import { AttributeFormModel, AttributeLevel, GetAllQualificationAttributeConfigsResponse, IQualificationAttributeConfig } from '../interfaces/promotion/promotion-qualification-attribute.interface';
import { PromotionQualificationAttributeConfigurationActions } from './promotion-qualification-attribute-configuration.action';
import { Observable, of } from 'rxjs';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { NgxsForm } from '../interfaces/ngxs-form.interface';

export class PromotionQualificationAttributeConfigurationStateModel {
    attributes: IQualificationAttributeConfig[];
    searchString: string;
    attributeForm: NgxsForm<AttributeFormModel>;
}

const PROMOTION_QUALIFICATION_ATTRIBUTE_CONFIGURATION_STATE_TOKEN = new StateToken<PromotionQualificationAttributeConfigurationStateModel>('promotionQualificationAttributeConfiguration');

@State<PromotionQualificationAttributeConfigurationStateModel>({
    name: PROMOTION_QUALIFICATION_ATTRIBUTE_CONFIGURATION_STATE_TOKEN,
    defaults: {
        attributes: [],
        searchString: null,
        attributeForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        }
    }
})
@Injectable()
export class PromotionQualificationAttributeConfigurationState {

    constructor(private promotionService: PromotionService, private store: Store) {
    }

    static attributeById(attributeId: string) {
        return createSelector([PromotionQualificationAttributeConfigurationState], (state: PromotionQualificationAttributeConfigurationStateModel): IQualificationAttributeConfig =>
            state.attributes?.find(q => q.attributeId.toLowerCase() === attributeId.toLowerCase()));
    }

    private static shouldShowAttribute(state: PromotionQualificationAttributeConfigurationStateModel, attribute: IQualificationAttributeConfig): boolean {
        if (state.searchString) {
            return attribute.attributeId?.toLowerCase().includes(state.searchString) ||
                attribute.displayName?.toLowerCase().includes(state.searchString);
        }
        return true;
    }

    @Selector()
    static allAttributes(state: PromotionQualificationAttributeConfigurationStateModel): IQualificationAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config));
    }

    @Selector()
    static transactionAttributes(state: PromotionQualificationAttributeConfigurationStateModel): IQualificationAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config))
            .filter(config => config.attributeLevel === AttributeLevel.TRANS);
    }

    @Selector()
    static itemAttributes(state: PromotionQualificationAttributeConfigurationStateModel): IQualificationAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config))
            .filter(config => config.attributeLevel === AttributeLevel.ITEM);
    }

    @Selector()
    static searchString(state: PromotionQualificationAttributeConfigurationStateModel): string {
        return state.searchString;
    }

    @Action(PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs)
    getPromotionQualificationAttributeConfigs(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>, action: PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs): Observable<GetAllQualificationAttributeConfigsResponse> {
        if (action.forceReload || !ctx.getState().attributes?.length) {
            return this.promotionService.getAllQualificationAttributeConfigs().pipe(
                tap((result: GetAllQualificationAttributeConfigsResponse) => {
                    if (result.qualificationAttributeConfigs) {
                        ctx.patchState({
                            attributes: result.qualificationAttributeConfigs
                        });
                    }
                })
            );
        }
        return of({ qualificationAttributeConfigs: ctx.getState().attributes });
    }

    @Action(PromotionQualificationAttributeConfigurationActions.SearchQualificationAttributeConfigs)
    searchQualificationAttributeConfigs(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>, action: PromotionQualificationAttributeConfigurationActions.SearchQualificationAttributeConfigs): void {
        ctx.patchState({
            searchString: action.searchString?.toLowerCase()
        });
    }

    @Action(PromotionQualificationAttributeConfigurationActions.ClearSearch)
    clearSearch(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>): void {
        ctx.patchState({
            searchString: null
        });
    }

    @Action(PromotionQualificationAttributeConfigurationActions.SaveQualificationAttributeConfig)
    savePromotionQualificationAttributeConfig(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>, action: PromotionQualificationAttributeConfigurationActions.SaveQualificationAttributeConfig): Observable<IGenericServerResponse> {
        if (action.attribute) {
            return this.promotionService.saveQualificationAttributeConfig(action.attribute).pipe(
                tap((response: IGenericServerResponse) => {
                    if (response.success) {
                        this.store.dispatch(new PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs(true));
                    } else {
                        throw new Error(response.message);
                    }
                })
            );
        }
    }

    @Action(PromotionQualificationAttributeConfigurationActions.DeleteQualificationAttributeConfig)
    deletePromotionQualificationAttributeConfig(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>, action: PromotionQualificationAttributeConfigurationActions.DeleteQualificationAttributeConfig): Observable<IGenericServerResponse> {
        if (action.attribute?.attributeId) {
            return this.promotionService.deleteQualificationAttributeConfig(action.attribute.attributeId).pipe(
                tap((response: IGenericServerResponse) => {
                    if (response.success) {
                        this.store.dispatch(new PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs(true));
                    } else {
                        throw new Error(response.message);
                    }
                })
            );
        }
    }

    @Action(PromotionQualificationAttributeConfigurationActions.LoadQualificationAttributeConfigIntoForm)
    loadQualificationAttributeConfigIntoForm(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>, action: PromotionQualificationAttributeConfigurationActions.LoadQualificationAttributeConfigIntoForm): Observable<GetAllQualificationAttributeConfigsResponse> {
        return this.store.dispatch(PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs).pipe(
            tap(() => {
                const attribute = this.store.selectSnapshot(PromotionQualificationAttributeConfigurationState.attributeById(action.attributeId));
                if (attribute) {
                    ctx.patchState({
                        attributeForm: {
                            model: {
                                attributeId: attribute.attributeId,
                                displayName: attribute.displayName,
                                attributeLevel: attribute.attributeLevel,
                                attributeType: attribute.attributeTypeCode,
                                valueOptions: attribute.attributeValueOptions?.map(valueOption => valueOption.attributeValue) || []
                            }
                        }
                    });
                }
            })
        );
    }

    @Action(PromotionQualificationAttributeConfigurationActions.ClearForm)
    clearForm(ctx: StateContext<PromotionQualificationAttributeConfigurationStateModel>): void {
        ctx.patchState({
            attributeForm: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {}
            }
        });
    }
}
