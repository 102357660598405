import { AdvancedSearchRule } from '../interfaces/promotion/advanced-search-rule.interface';

export namespace PromotionDashboardActions {
    export class GetDashboardData {
        static readonly type = '[Promotion API] Dashboard Data';
        constructor(public forceReload?: boolean, public searchParams?: AdvancedSearchRule[], public matchType?: string) {}
    }

    export class ClearAllFiltersAndSearchRules {
        static readonly type = '[Promotion Dashboard] Clear All Filters and Search Rules';
    }

    export class DeletePromotion {
        static readonly type = '[Promotion Dashboard] Delete Promotion';
        constructor(public id: string) { }
    }
}
