import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
    NbAuthComponent,
    NbLogoutComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
} from '@nebular/auth';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { Oauth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';

export const routes: Routes = [
    {
        path: 'auth',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'register',
                component: RegisterComponent,
            },
            {
                path: 'logout',
                component: NbLogoutComponent,
            },
            {
                path: 'request-password',
                component: NbRequestPasswordComponent,
            },
            {
                path: 'reset-password',
                component: NbResetPasswordComponent,
            },
            {
                path: ':originPage/pending-approval',
                component: PendingApprovalComponent
            },
            {
                path: 'callback',
                component: Oauth2CallbackComponent,
            },
        ],
    },
];

const config: ExtraOptions = {
    useHash: true,
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}
