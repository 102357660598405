import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { RegisterComponent } from './register/register.component';
import { JMCCFormsModule } from '../@forms/forms.module';
import { LoginComponent } from './login/login.component';
import { Oauth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        Oauth2CallbackComponent,
        PendingApprovalComponent,
        RegisterComponent,
    ],
    imports: [
        AuthRoutingModule,
        CommonModule,
        JMCCFormsModule,
        ThemeModule
    ]
})
export class AuthModule {
}
