import { IPromotion, QualificationApplicationTypeCode, RewardApplicationTypeCode } from './promotion.interface';
import { ISpark } from './spark.interface';
import { IUser } from '../user.interface';
import { ILabel } from '../label.interface';
import { IGenericServerResponse } from '../generic-server-response';

export namespace PromoStatusCode {
    export const DRAFT = 'DRAFT';
    export const PENDING_APPROVAL = 'PENDING_APPROVAL';
    export const PENDING_PUBLISH = 'PENDING_PUBLISH';
    export const PENDING_PUBLISH_IMMEDIATE = 'PENDING_PUBLISH_IMMEDIATE';
    export const PUBLISHED = 'PUBLISHED';
    export const PENDING_RECALL_IMMEDIATE = 'PENDING_RECALL_IMMEDIATE';
    export const ARCHIVED = 'ARCHIVED';
    export const DELETED = 'DELETED';
}

export namespace SparkTypeCode {
    export const LOYALTY = 'LOYALTY';
    export const COUPON = 'COUPON';
    export const SINGLE_USE_PROMO_CODE = 'SINGLE_USE_PROMO_CODE';
    export const SINGLE_USE_PROMO_CODE_GENERATE = 'SINGLE_USE_PROMO_CODE_GENERATE';
    export const EMPLOYEE = 'EMPLOYEE';
    export const TRANSACTION_ATTRIBUTE = 'TRANSACTION_ATTRIBUTE';
}

export namespace RewardApplicationOptionType {
    export const LOWEST_PRICE = 'LOWEST_PRICE';
    export const HIGHEST_PRICE = 'HIGHEST_PRICE';
    export const MIXED_LOWEST_PRICE = 'MIXED_LOWEST_PRICE';
    export const MIXED_HIGHEST_PRICE = 'MIXED_HIGHEST_PRICE';
}

export function getRewardApplicationOptionType(stagedPromotion: IStagedPromotion): string {
    if (stagedPromotion?.rewardApplicationTypeCode?.length) {
        if (RewardApplicationTypeCode.LOWEST_PRICE === stagedPromotion.rewardApplicationTypeCode
            && (!stagedPromotion.qualifyApplicationTypeCode?.length || QualificationApplicationTypeCode.LOWEST_PRICE === stagedPromotion.qualifyApplicationTypeCode)) {
            return RewardApplicationOptionType.MIXED_LOWEST_PRICE;
        } else if (RewardApplicationTypeCode.HIGHEST_PRICE === stagedPromotion.rewardApplicationTypeCode
            && (!stagedPromotion.qualifyApplicationTypeCode?.length || QualificationApplicationTypeCode.HIGHEST_PRICE === stagedPromotion.qualifyApplicationTypeCode)) {
            return RewardApplicationOptionType.MIXED_HIGHEST_PRICE;
        } else if (RewardApplicationTypeCode.LOWEST_PRICE === stagedPromotion.rewardApplicationTypeCode
            && QualificationApplicationTypeCode.HIGHEST_PRICE === stagedPromotion.qualifyApplicationTypeCode) {
            return RewardApplicationOptionType.LOWEST_PRICE;
        } else if (RewardApplicationTypeCode.HIGHEST_PRICE === stagedPromotion.rewardApplicationTypeCode
            && QualificationApplicationTypeCode.LOWEST_PRICE === stagedPromotion.qualifyApplicationTypeCode) {
            return RewardApplicationOptionType.HIGHEST_PRICE;
        }
    }
    return undefined;
}

export interface IStagedPromotion extends IPromotion {
    promoStatusCode?: string;
    campaignId?: string;
    templateOfferId?: string;
    effectiveStartTime?: string;
    effectiveEndTime?: string;
    effectiveSchedule?: string;
    estimatedPublishDate?: Date;
    sparks?: ISpark[];
    labels?: ILabel[];
    collaborators?: IUser[];
    includedBusinessUnitGroupIds?: string[];
    excludedBusinessUnitGroupIds?: string[];
    includedBusinessUnitIds?: string[];
    excludedBusinessUnitIds?: string[];
}

export interface ISaveStagedPromotionResponse extends IGenericServerResponse {
    stagedPromotion: IStagedPromotion;
}
