import { IGenericServerResponse } from './generic-server-response';
import { IUser } from './user.interface';

export interface IReview {
    reviewId: string;
    workflowConfigId: string;
    objectId: string;
    objectType: string;
    reviewSequence: number;
    reviewStatusTypeCode: string;
    reviewingUser?: IUser;
    reviewedDate?: Date;
}

export interface ITestEnvironmentDeploy {
    testEnvDeployId: string;
    workflowConfigId: string;
    objectId: string;
    objectType: string;
    testEnvDeployStatus: string;
    testEnvDeploySequence: number;
    deployedDate?: Date;
}

export namespace ReviewStatusTypeCode {
    export const OPEN = 'OPEN';
    export const CLOSED = 'CLOSED';
    export const APPROVED = 'APPROVED';
    export const REJECTED = 'REJECTED';
}

export namespace TestEnvironmentDeployStatusTypeCode {
    export const DEPLOYED = 'DEPLOYED';
    export const AWAITING_DEPLOY = 'AWAITING_DEPLOY';
    export const AWAITING_RECALL = 'AWAITING_RECALL';
    export const FAILED_DEPLOY = 'FAILED_DEPLOY';
    export const RECALLED = 'RECALLED';
}

export interface IWorkflowConfig {
    workflowConfigId: string;
    workflowStatus: string;
    workflowStepSequence: number;
    objectType: string;
    workflowType: string;
    assignmentId: string;
    assignmentName?: string;
    numberOfReviewsRequired?: number;
}

export interface IWorkflowDetails {
    reviews: IReview[];
    testEnvironmentDeploys: ITestEnvironmentDeploy[];
    submittedByName?: string;
    submittedTime?: Date;
}

export namespace WorkflowStatusTypeCode {
    export const ACTIVE = 'ACTIVE';
    export const INACTIVE = 'INACTIVE';
}

export namespace WorkflowTypeCode {
    export const USER = 'USER';
    export const TEAM = 'TEAM';
    export const TEST_ENVIRONMENT_DEPLOY = 'TEST_ENVIRONMENT_DEPLOY';
}

export interface GetWorkflowItemsRequest {
    objectId?: string;
    objectIds?: string[];
    objectType: string;
}

export interface GetWorkflowItemsResponse {
    workflowDetails: { [x: string]: IWorkflowDetails };
}

export interface GetWorkflowConfigsResponse {
    workflowConfigs: IWorkflowConfig[];
}

export interface ReviewActionResponse extends IGenericServerResponse {
    review: IReview;
}

export interface GenerateOpenReviewsResponse {
    reviews: IReview[];
}

export interface UIWorkflow {
    name: string;
    type: string;
    requiredReviews?: number;
    pendingReviews?: number;
    completedReviews?: number;
    approvals: IApproval[];
    testEnvironmentDeployStatus?: string;
    workflowConfigId: string;
}

export interface UIWorkflowStep {
    stepSequence: number;
    workflowItems: UIWorkflow[];
}

export interface IApproval {
    name: string;
    time: Date;
}
