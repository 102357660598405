<nb-form-field>
    <label for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <jmcc-tooltip-icon-button *ngIf="tooltip" [icon]="'question-mark-circle-outline'" [tooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"></jmcc-tooltip-icon-button>
    <textarea *ngIf="textArea" nbInput id="{{id}}" shape="semi-round" fieldSize="large"
        [formControl]="control" placeholder="{{placeholder}}"
        [status]="control.dirty && !control.valid ? 'danger' : 'basic'" (input)="onInputChange()"></textarea>
    <input *ngIf="!textArea" nbInput id="{{id}}" shape="semi-round" [fieldSize]="size" [formControl]="control"
        placeholder="{{placeholder}}" [status]="getStatus()" (input)="onInputChange()"
        [autocomplete]="getBrowserAutocomplete()" [type]="type" [nbAutocomplete]="auto" [scrollStrategy]="'noop'">
    <span *ngIf="hint" id="{{id}}Hint" class="hint">{{hint}}</span>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</nb-form-field>
<nb-autocomplete #auto (selectedChange)="onSelect($event)">
    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{option}}
    </nb-option>
</nb-autocomplete>
