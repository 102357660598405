import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { ICampaignDetails } from '../interfaces/promotion/campaign-details.interface';
import { PromotionHistoryFormModel } from '../interfaces/promotion/promotion-history.interface';
import { IStagedPromotion } from '../interfaces/promotion/staged-promotion.interface';
import { PromotionService } from '../services/promotion.service';
import { PromotionHistoryActions } from './promotion-history.actions';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

export class PromotionHistoryStateModel {
    historyForm: NgxsForm<PromotionHistoryFormModel>;
    campaigns: ICampaignDetails[];
    stagedPromotions: IStagedPromotion[];
    isoCurrencyCode: string;
}

const PROMOTION_HISTORY_STATE_TOKEN = new StateToken<PromotionHistoryStateModel>('promotionHistory');

@State<PromotionHistoryStateModel>({
    name: PROMOTION_HISTORY_STATE_TOKEN,
    defaults: {
        historyForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        campaigns: [],
        stagedPromotions: [],
        isoCurrencyCode: 'USD',
    }
})
@Injectable()
export class PromotionHistoryState {

    constructor(private promotionService: PromotionService) {
    }

    static promotionsInCampaign(campaignId: string) {
        return createSelector([PromotionHistoryState], (state: PromotionHistoryStateModel) =>
            state.stagedPromotions?.filter(p => p.campaignId === campaignId && this.shouldShowPromotion(state, p)).sort((a: IStagedPromotion, b: IStagedPromotion) =>
                new Date(b.lastUpdateTime).getTime() - new Date(a.lastUpdateTime).getTime()
            ));
    }

    private static shouldShowPromotion(state: PromotionHistoryStateModel, promotion: IStagedPromotion): boolean {
        const searchString = state.historyForm.model?.searchString;
        const matchesSearchString = !searchString
            || promotion.promotionId?.toLowerCase().includes(searchString)
            || promotion.promotionName?.toLowerCase().includes(searchString)
            || promotion.longDescription?.toLowerCase().includes(searchString);
        const matchesDateFilter = this.matchesMinStartDateFilter(state.historyForm.model?.startDate, promotion)
            && this.matchesMaxEndDateFilter(state.historyForm.model?.endDate, promotion);
        return matchesSearchString && matchesDateFilter;
    }

    private static matchesMinStartDateFilter(startDateFilter: Moment | string, promotion: IStagedPromotion): boolean {
        if (startDateFilter) {
            const promotionStart = moment(new Date(promotion.effectiveStartTime));
            return promotionStart.isSameOrAfter(startDateFilter);
        }
        return true;
    }

    private static matchesMaxEndDateFilter(endDateFilter: Moment | string, promotion: IStagedPromotion): boolean {
        if (endDateFilter) {
            const promotionEnd = moment(new Date(promotion.effectiveEndTime));
            return promotionEnd.isSameOrBefore(endDateFilter);
        }
        return true;
    }

    private static shouldShowCampaign(state: PromotionHistoryStateModel, campaign: ICampaignDetails): boolean {
        return state.stagedPromotions?.some(p => campaign?.campaign?.campaignId === p.campaignId && this.shouldShowPromotion(state, p));
    }

    @Selector()
    static unassignedPromotions(state: PromotionHistoryStateModel): IStagedPromotion[] {
        return state.stagedPromotions?.filter(p => !p.campaignId && this.shouldShowPromotion(state, p));
    }

    @Selector()
    static historicCampaigns(state: PromotionHistoryStateModel): ICampaignDetails[] {
        return cloneDeep(state.campaigns)
            .filter((c: ICampaignDetails) => this.shouldShowCampaign(state, c))
            .sort((a: ICampaignDetails, b: ICampaignDetails) => {
                if (!a.campaign || !b.campaign) {
                    return 0;
                }
                return new Date(b.campaign.lastUpdateTime).getTime() - new Date(a.campaign.lastUpdateTime).getTime();
            });
    }

    @Selector()
    static historyForm(state: PromotionHistoryStateModel): NgxsForm<PromotionHistoryFormModel> {
        return state.historyForm;
    }

    @Selector()
    static hasActiveFilter(state: PromotionHistoryStateModel): boolean {
        return !!state.historyForm.model?.searchString
            || !!state.historyForm.model?.startDate
            || !!state.historyForm.model?.endDate;
    }

    @Action(PromotionHistoryActions.GetHistoryData)
    getHistoryData(ctx: StateContext<PromotionHistoryStateModel>): Observable<PromotionHistoryStateModel> {
        return this.promotionService.getHistoricalData(null, null).pipe(
            tap((result: PromotionHistoryStateModel) =>
                ctx.patchState({
                    ...result
                })
            )
        );
    }

    @Action(PromotionHistoryActions.ClearSearch)
    clearSearch(ctx: StateContext<PromotionHistoryStateModel>) {
        ctx.patchState({
            historyForm: {
                model: {
                    searchString: null,
                    startDate: null,
                    endDate: null
                }
            }
        });
    }
}
