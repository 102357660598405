import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthResult, NbAuthService, NbPasswordAuthStrategyOptions, getDeepFromObject } from '@nebular/auth';
import { ContextService } from '../../@core/services/context.service';
import { forkJoin } from 'rxjs';
import { PasswordValidator } from '../../@core/utils/password.validator';

@Component({
    selector: 'jmcc-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

    redirectDelay = 0;
    strategy = '';
    submitted = false;
    isSuccess: boolean;
    messages: string[] = [];
    registerForm: FormGroup;
    passwordRequirementMessage: string;
    passwordErrorMessages = new Map<string, string>([['mustMatch', 'Passwords must match'], ['pattern', 'Password does not meet requirements']]);

    constructor(
        private contextService: ContextService,
        private authService: NbAuthService,
        private router: Router,
        @Inject(NB_AUTH_OPTIONS) protected options: NbPasswordAuthStrategyOptions
    ) {
        this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
        this.strategy = this.getConfigValue('forms.register.strategy');
    }

    ngOnInit(): void {
        const fullName = new FormControl('', Validators.required);
        const email = new FormControl('', [Validators.required, Validators.email]);
        const password = new FormControl('', [Validators.required]);
        const confirmPassword = new FormControl('', [Validators.required, PasswordValidator.mustMatch(password)]);
        this.registerForm = new FormGroup({ fullName, email, password, confirmPassword });
    }

    ngAfterViewInit(): void {
        forkJoin({
            pattern: this.contextService.getConfigString('jmcc.user.authentication.passwordPattern'),
            message: this.contextService.getConfigString('jmcc.user.authentication.passwordRequirementsMessage')
        }).subscribe(passwordPattern => {
            this.registerForm.controls.password.setValidators([Validators.required, Validators.pattern(passwordPattern.pattern?.value)]);
            this.passwordRequirementMessage = passwordPattern.message?.value;
        });
    }

    register(): void {
        if (this.registerForm.valid) {
            this.messages = [];
            this.submitted = true;

            this.authService.register(this.strategy, this.registerForm.value).subscribe((result: NbAuthResult) => {
                this.submitted = false;
                this.isSuccess = result.isSuccess();
                this.messages = result.getErrors().concat(result.getMessages());

                if (this.isSuccess) {
                    if (result.getResponse().body?.resultCode === 'PENDING_APPROVAL') {
                        setTimeout(() => {
                            this.router.navigate(['auth/register/pending-approval']);
                        }, this.redirectDelay);
                    } else {
                        setTimeout(() => {
                            this.router.navigate(['/']);
                        }, this.redirectDelay);
                    }
                }
            });
        } else {
            Object.keys(this.registerForm.controls).forEach((key) => this.registerForm.controls[key].markAsDirty());
            window.scrollTo(0, 0);
        }
    }

    private getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
