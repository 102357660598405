import { ICampaign } from '../interfaces/promotion/campaign.interface';

export namespace CampaignActions {

    export class LoadCampaign {
        static readonly type = '[Campaign] Load Campaign';
        constructor(public id: string) { }
    }

    export class LoadActiveCampaigns {
        static readonly type = '[Campaign] Load Active Campaigns';
    }

    export class ClearCampaign {
        static readonly type = '[Campaign] Clear Campaign';
    }

    export class SaveCampaign {
        static readonly type = '[Campaign] Save Campaign';
        constructor(public campaign: ICampaign, public create: boolean) { }
    }

    export class DeleteCampaign {
        static readonly type = '[Campaign] Delete Campaign';
        constructor(public id: string) { }
    }

    export class ArchiveCampaign {
        static readonly type = '[Campaign] Archive Campaign';
        constructor(public id: string) { }
    }

    export class CampaignCardExpanded {
        static readonly type = '[Campaign] Card Expanded';
        constructor(public id: string, public expanded: boolean) { }
    }

}
