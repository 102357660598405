import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard {

    constructor(
        private authService: NbAuthService,
        private router: Router,
        private logger: NGXLogger
    ) { }

    canActivate(): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']).then().catch(error => {
                            this.logger.error('Failed to navigate back to login', error);
                        });
                    }
                })
            );
    }
}
