<div class="timeline-container" id="timelineContainer">
    <div *ngFor="let event of events; let last = last; let index = index" class="timeline-event"  id="element{{index}}">
        <div class="event-marker">
            <div class="marker-container">
                <div class="marker"></div>
            </div>
            <div *ngIf="!last" class="connector"></div>
        </div>
        <div class="content">
            <span><strong>{{event.date | date : 'E MMM d, y, h:mm a'}}</strong></span>
            <span>{{event.detail}}</span>
        </div>
    </div>
</div>
