<div class="nb-form-field">
    <label *ngIf="label" for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <jmcc-tooltip-icon-button *ngIf="tooltip" [icon]="'question-mark-circle-outline'" [tooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"></jmcc-tooltip-icon-button>
    <nb-radio-group [formControl]="control" [status]="getStatus()" id="{{id}}">
        <nb-radio *ngFor="let option of options"
            [value]="option.key">{{option.displayValue}}</nb-radio>
    </nb-radio-group>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
