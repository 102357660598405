import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IUploadedFile } from '../../../@core/interfaces/promotion/uploaded-file.interface';
import { FormsService } from '../../forms.service';

@Component({
    selector: 'jmcc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    instructions: string;

    @Input()
    required: boolean;

    @Input()
    fileControl: FormControl = new FormControl([]);

    @Input()
    uploadedFiles: IUploadedFile[];

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    acceptedFileType = 'text/*';

    @Output()
    fileSubmitted: EventEmitter<File> = new EventEmitter();

    @Output()
    fileRemoved: EventEmitter<string> = new EventEmitter();

    loading = false;

    constructor(private formsService: FormsService) {
    }

    ngOnInit(): void {
        if (this.required) {
            this.fileControl.setValidators(Validators.required);
            this.fileControl.updateValueAndValidity();
            this.errorMessages.set('required', 'At least one valid file is required');
        }
    }

    ngOnChanges(): void {
        const controlValue = [...this.fileControl.value];
        this.uploadedFiles.forEach(file => {
            if (!file.uploading && !this.fileControl.value.find(e => e.filename === file.filename)) {
                controlValue.push(file);
                this.fileControl.markAsDirty();
            }
        });
        this.fileControl.setValue(controlValue);
        this.fileControl.updateValueAndValidity();
    }

    onUploadClick() {
        document.getElementById('input' + this.id).click();
    }

    onUpload(event: any) {
        for (const fileToSave of event.target.files) {
            this.fileSubmitted.emit(fileToSave);
        }
        event.target.value = [];
    }

    onFileRemove(fileToRemove: string) {
        this.fileRemoved.emit(fileToRemove);
        this.fileControl.setValue(this.fileControl.value.filter(e => e.filename !== fileToRemove));
        this.fileControl.updateValueAndValidity();
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.fileControl, this.errorMessages);
    }

}
