import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetNotificationsResponse } from '../interfaces/notification.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';

@Injectable()
export class NotificationService {

    constructor(private http: HttpClient) { }

    getNotifications(userId: string): Observable<GetNotificationsResponse> {
        return this.http.put<GetNotificationsResponse>('app/notifications/get', { userId });
    }

    markNotificationsAsViewed(notificationIds: string[], userId: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>('app/notifications/markAsViewed', { notificationIds, userId });
    }
}
