import { Injectable } from '@angular/core';
import { IQualification, IReward, IRewardCategory, ITEM, QualificationTypeCode, RewardTypeCode, TRANS } from '../interfaces/promotion/promotion.interface';
import { IOfferSelectOptions, IQualificationForm, IRewardCategoryForm, IRewardForm, isRewardCategory, isRewardCategoryForm, OfferRewardCategoryFormModel, OfferRewardFormModel } from '../interfaces/promotion/promotion-offers.interface';
import { Store } from '@ngxs/store';
import { PromotionOffersState } from '../state/promotion-offers.state';
import { PromotionOffersActions } from '../state/promotion-offers.actions';
import { NbAuthService } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class PromotionOfferService {

    offerSelectOptions: IOfferSelectOptions;

    constructor(private store: Store, private authService: NbAuthService) {
        const offerSelectOptionsLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
        authService.onAuthenticationChange()
            .pipe(takeUntil(offerSelectOptionsLoaded$))
            .subscribe((loggedIn: boolean) => {
                if (loggedIn) {
                    setTimeout(() => store.dispatch(PromotionOffersActions.LoadRewardCategoryConfigs).subscribe(
                        () => {
                            this.offerSelectOptions = this.store.selectSnapshot(PromotionOffersState.offerSelectOptions);
                            offerSelectOptionsLoaded$.next(true);
                        }
                    ));
                }
            });
    }

    getQualificationCriteria(qualification: IQualificationForm): string {
        if (qualification?.form?.model) {
            let displayLevel = this.offerSelectOptions?.qualificationLevelOptions?.find(option => option.key === qualification.form.model.qualificationLevel)?.displayValue;
            if (!displayLevel) {
                displayLevel = qualification.form.model.qualificationLevel;
            }
            let displayType = this.offerSelectOptions?.qualificationTypeOptions?.find(option => option.key === qualification.form.model.qualificationType)?.displayValue;
            if (!displayType) {
                displayType = qualification.form.model.qualificationType;
            }
            return this.buildQualificationCriteria(displayLevel, displayType, qualification.form.model.qualificationType, qualification.form.model.qualificationAmount);
        }
        return '';
    }

    getSavedQualificationCriteria(qualification: IQualification): string {
        if (qualification) {
            const level = this.translateQualificationLevel(qualification.qualificationTypeCode);
            let displayLevel = this.offerSelectOptions?.qualificationLevelOptions?.find(option => option.key === level)?.displayValue;
            if (!displayLevel) {
                displayLevel = level;
            }
            const type = this.translateQualificationType(qualification.qualificationTypeCode);
            let displayType = this.offerSelectOptions?.qualificationTypeOptions.find(option => option.key === type)?.displayValue;
            if (!displayType) {
                displayType = type;
            }
            return this.buildQualificationCriteria(displayLevel, displayType, type, qualification.qualification.toString());
        }
        return '';
    }

    getRewardCriteria(reward: IRewardForm | IRewardCategoryForm): string {
        if (reward?.form?.model) {
            if (isRewardCategoryForm(reward)) {
                const category = this.offerSelectOptions?.rewardCategoryOptions?.find(option =>
                    option.key === reward.form.model.rewardCategory)?.displayValue || reward.form.model.rewardCategory;
                const displayType = this.offerSelectOptions?.rewardCategoryTypeOptions?.find(option =>
                    option.key === reward.form.model.rewardType)?.displayValue || reward.form.model.rewardType;
                return this.buildCategoryRewardCriteria(category, displayType, reward.form.model.rewardType, reward.form.model.rewardAmount);
            } else {
                const displayLevel = this.offerSelectOptions?.rewardLevelOptions?.find(option =>
                    option.key === reward.form.model.rewardLevel)?.displayValue || reward.form.model.rewardLevel;
                const displayType = this.offerSelectOptions?.rewardTypeOptions?.find(option =>
                    option.key === reward.form.model.rewardType)?.displayValue || reward.form.model.rewardType;
                return this.buildRewardCriteria(displayLevel, displayType, reward.form.model.rewardType, reward.form.model.rewardAmount);
            }
        }
        return '';
    }

    getRewardFrequency(reward: IRewardForm | IRewardCategoryForm): string {
        if (reward?.form?.model && !isRewardCategoryForm(reward)) {
            return 'Reward Frequency: ' + reward.form.model.rewardQuantity;
        }
        return '';
    }

    getSavedRewardCriteria(reward: IReward | IRewardCategory): string {
        if (reward) {
            if (isRewardCategory(reward)) {
                const category = this.offerSelectOptions?.rewardCategoryOptions?.find(option =>
                    option.key === reward.categoryId)?.displayValue || reward.categoryId;
                const displayType = this.offerSelectOptions?.rewardCategoryTypeOptions?.find(option =>
                    option.key === reward.rewardCategoryType)?.displayValue || reward.rewardCategoryType;
                const rewardAmount = this.isPercentType(reward.rewardCategoryType) ? reward.reward * 100 : reward.reward;
                return this.buildCategoryRewardCriteria(category, displayType, reward.rewardCategoryType, rewardAmount.toString());
            } else {
                const level = this.translateRewardLevel(reward.rewardType);
                const displayLevel = this.offerSelectOptions?.rewardLevelOptions?.find(option =>
                    option.key === level)?.displayValue || level;
                const type = this.translateRewardType(reward.rewardType);
                const displayType = this.offerSelectOptions?.rewardTypeOptions?.find(option =>
                    option.key === type)?.displayValue || type;
                const rewardAmount = this.isPercentType(reward.rewardType) ? reward.reward * 100 : reward.reward;
                return this.buildRewardCriteria(displayLevel, displayType, type, rewardAmount.toString());
            }
        }
        return '';
    }

    translateReward(level: string, type: string): string {
        if (level === TRANS && type === RewardTypeCode.AMT) {
            return RewardTypeCode.TRN_AMT;
        } else if (level === TRANS && type === RewardTypeCode.PCT) {
            return RewardTypeCode.TRN_PCT;
        } else if (type === RewardTypeCode.AMT) {
            return RewardTypeCode.AMT;
        } else if (type === RewardTypeCode.PCT) {
            return RewardTypeCode.PCT;
        } else if (type === RewardTypeCode.PRICE) {
            return RewardTypeCode.PRICE;
        } else if (type === RewardTypeCode.PRICE_TOTAL) {
            return RewardTypeCode.PRICE_TOTAL;
        } else {
            throw new Error(`Unsupported combo of level:${level} and type:${type}`);
        }
    }

    translateRewardLevel(typeCode: string): string {
        return this.translateLevelBase(typeCode);
    }

    translateRewardType(typeCode: string): string {
        if (typeCode) {
            if (typeCode.endsWith(RewardTypeCode.AMT)) {
                return RewardTypeCode.AMT;
            } else if (typeCode.endsWith(RewardTypeCode.PCT)) {
                return RewardTypeCode.PCT;
            }
        }
        return typeCode;
    }

    translateQualification(level: string, type: string): string {
        if (level === TRANS && type === QualificationTypeCode.AMT) {
            return QualificationTypeCode.TRN_AMT;
        } else if (level === TRANS && type === QualificationTypeCode.QTY) {
            return QualificationTypeCode.TRN_QTY;
        } else if (type === QualificationTypeCode.AMT) {
            return QualificationTypeCode.AMT;
        } else if (type === QualificationTypeCode.AMT_TOTAL) {
            return QualificationTypeCode.AMT_TOTAL;
        } else {
            return QualificationTypeCode.QTY;
        }
    }

    translateQualificationLevel(typeCode: string): string {
        return this.translateLevelBase(typeCode);
    }

    translateQualificationType(typeCode: string): string {
        if (typeCode) {
            if (typeCode.endsWith(QualificationTypeCode.AMT)) {
                return QualificationTypeCode.AMT;
            } else if (typeCode.startsWith(QualificationTypeCode.AMT)) {
                return QualificationTypeCode.AMT_TOTAL;
            }
        }
        return QualificationTypeCode.QTY;
    }

    translateRewardAmount(rewardForm: OfferRewardFormModel | OfferRewardCategoryFormModel): number {
        let reward = +rewardForm?.rewardAmount;
        if (this.isPercentType(rewardForm?.rewardType)) {
            reward = reward / 100;
        }
        return reward;
    }

    translateMaxRewardAmount(rewardForm: OfferRewardFormModel): number {
        const maxReward = +rewardForm?.rewardMaxAmount;
        return isNaN(maxReward) || maxReward === 0 ? null : maxReward;
    }

    private translateLevelBase(typeCode: string): string {
        if (typeCode?.startsWith('TRN')) {
            return TRANS;
        } else {
            return ITEM;
        }
    }

    private buildQualificationCriteria(displayLevel: string, displayType: string, type: string, amount: string): string {
        const amountPrefix = type?.startsWith(QualificationTypeCode.AMT) ? '$' : '';
        return `${displayLevel} ${displayType}: ${amountPrefix}${amount}`;
    }

    private buildRewardCriteria(displayLevel: string, displayType: string, type: string, amount: string): string {
        const isPct = this.isPercentType(type);
        const amountPrefix = !isPct ? '$' : '';
        const amountSuffix = isPct ? '%' : '';
        return `${displayLevel} ${displayType}: ${amountPrefix}${amount}${amountSuffix}`;
    }

    private buildCategoryRewardCriteria(category: string, displayType: string, type: string, amount: string): string {
        const isPct = this.isPercentType(type);
        const amountSuffix = isPct ? '%' : '';
        return `${category} - ${displayType}: ${amount}${amountSuffix}`;
    }

    private isPercentType(rewardType: string): boolean {
        return RewardTypeCode.PCT === rewardType ||
            RewardTypeCode.TRN_PCT === rewardType;
    }
}
