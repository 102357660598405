import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetAppsResponse } from '../interfaces/app.interface';
import { GetCommentsRequest, GetCommentsResponse, IComment } from '../interfaces/comment.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { GetLabelsResponse, ILabel } from '../interfaces/label.interface';
import { GetObjectHistoryResponse } from '../interfaces/object-history-event.interface';
import { GenerateOpenReviewsResponse, GetWorkflowConfigsResponse, GetWorkflowItemsRequest, GetWorkflowItemsResponse, ITestEnvironmentDeploy, IWorkflowConfig, ReviewActionResponse } from '../interfaces/review.interface';
import { GetConfigStringResponse } from '../interfaces/context.interface';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class ContextService {

    readonly contextServicePrefix = 'app/context/';
    getConfigStringCache: { [configName: string]: Observable<GetConfigStringResponse> } = {};
    stringConfigExistsCache: { [configName: string]: Observable<boolean> } = {};
    getConfigBooleanCache: { [configName: string]: Observable<boolean> } = {};
    getConfigListCache: { [configName: string]: Observable<string[]> } = {};
    getConfigMapCache: { [configName: string]: Observable<{ [x: string]: string }> } = {};

    constructor(private http: HttpClient) {
    }

    getLabelsByType(objectType: string): Observable<GetLabelsResponse> {
        return this.http.post<GetLabelsResponse>(this.contextServicePrefix + 'getLabelsByType', { objectType });
    }

    applyLabels(objectId: string, objectType: string, labelsToLink: ILabel[], labelsToUnlink: ILabel[]): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.contextServicePrefix + 'applyLabels', { objectId, objectType, labelsToLink, labelsToUnlink });
    }

    createLabel(labelId: string, objectType: string, labelName: string, color: string, description: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.contextServicePrefix + 'createLabel', { labelId, objectType, labelName, color, description });
    }

    getEnabledApps(): Observable<GetAppsResponse> {
        return this.http.get<GetAppsResponse>(this.contextServicePrefix + 'apps/enabled');
    }

    getComments(request: GetCommentsRequest): Observable<GetCommentsResponse> {
        return this.http.put<GetCommentsResponse>(this.contextServicePrefix + 'comments/get', request);
    }

    saveComment(comment: IComment, saveType: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.contextServicePrefix + 'comments/save', { comment, saveType });
    }

    deleteComment(commentId: string, objectId: string, objectType: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.contextServicePrefix + 'comments/delete', { commentId, objectId, objectType });
    }

    getObjectHistory(objectId: string, objectType: string): Observable<GetObjectHistoryResponse> {
        return this.http.put<GetObjectHistoryResponse>(this.contextServicePrefix + 'objectHistory/get', { objectId, objectType });
    }

    getWorkflowItems(request: GetWorkflowItemsRequest): Observable<GetWorkflowItemsResponse> {
        return this.http.put<GetWorkflowItemsResponse>(this.contextServicePrefix + 'workflowItems/get', request);
    }

    getWorkflowConfigs(objectType: string): Observable<GetWorkflowConfigsResponse> {
        return this.http.put<GetWorkflowConfigsResponse>(this.contextServicePrefix + 'workflowConfigs/get', { objectType });
    }

    saveWorkflowConfigs(workflowConfigurationModels: IWorkflowConfig[], objectType: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.contextServicePrefix + 'workflowConfigs/save', { workflowConfigurationModels, objectType });
    }

    approveReview(reviewId: string, workflowConfigId: string): Observable<ReviewActionResponse> {
        return this.http.put<ReviewActionResponse>(this.contextServicePrefix + 'reviews/approve', { reviewId, workflowConfigId });
    }

    rejectReview(reviewId: string, workflowConfigId: string): Observable<ReviewActionResponse> {
        return this.http.put<ReviewActionResponse>(this.contextServicePrefix + 'reviews/reject', { reviewId, workflowConfigId });
    }

    generateOpenReviews(workflowConfig: IWorkflowConfig, objectId: string, objectType: string, reviewSequence: number): Observable<GenerateOpenReviewsResponse> {
        return this.http.put<GenerateOpenReviewsResponse>(this.contextServicePrefix + 'reviews/generate', { workflowConfig, objectId, objectType, reviewSequence });
    }

    saveTestEnvironmentDeploy(testEnvironmentDeploy: ITestEnvironmentDeploy): Observable<{ testEnvironmentDeploys: ITestEnvironmentDeploy[] }> {
        return this.http.put<{ testEnvironmentDeploys: ITestEnvironmentDeploy[] }>(this.contextServicePrefix + 'testEnvironmentDeploy/save', { testEnvironmentDeploy });
    }

    getConfigString(configName: string): Observable<GetConfigStringResponse> {
        if (!this.getConfigStringCache[configName]) {
            this.getConfigStringCache[configName] = this.http.get<GetConfigStringResponse>(this.contextServicePrefix + 'config/getString?configName=' + configName)
                .pipe(shareReplay());
        }
        return this.getConfigStringCache[configName];
    }

    stringConfigExists(configName: string): Observable<boolean> {
        if (!this.stringConfigExistsCache[configName]) {
            this.stringConfigExistsCache[configName] = this.http.get<boolean>(this.contextServicePrefix + 'config/stringConfigExists?configName=' + configName)
                .pipe(shareReplay());
        }
        return this.stringConfigExistsCache[configName];
    }

    getConfigBoolean(configName: string): Observable<boolean> {
        if (!this.getConfigBooleanCache[configName]) {
            this.getConfigBooleanCache[configName] = this.http.get<boolean>(this.contextServicePrefix + 'config/getBoolean?configName=' + configName)
                .pipe(shareReplay());
        }
        return this.getConfigBooleanCache[configName];
    }

    getConfigList(configName: string): Observable<string[]> {
        if (!this.getConfigListCache[configName]) {
            this.getConfigListCache[configName] = this.http.get<string[]>(this.contextServicePrefix + 'config/getList?configName=' + configName)
                .pipe(shareReplay());
        }
        return this.getConfigListCache[configName];
    }

    getConfigMap(configName: string): Observable<{ [x: string]: string }> {
        if (!this.getConfigMapCache[configName]) {
            this.getConfigMapCache[configName] = this.http.get<{ [x: string]: string }>(this.contextServicePrefix + 'config/getMap?configName=' + configName)
                .pipe(shareReplay());
        }
        return this.getConfigMapCache[configName];
    }
}
