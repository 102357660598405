import { Component, Input } from '@angular/core';
import { ITeamMember } from '../../../@core/interfaces/team-member.interface';
import { IUser } from '../../../@core/interfaces/user.interface';

@Component({
    selector: 'jmcc-user-bubbles',
    templateUrl: './user-bubbles.component.html',
    styleUrls: ['./user-bubbles.component.scss']
})
export class UserBubblesComponent {

    @Input()
    users: IUser[] | ITeamMember[];

    @Input()
    maxBubbles = 2;

    @Input()
    size = 'tiny';

    getOverflowUsersNames(): string {
        return this.users.slice(this.maxBubbles).map((u: IUser | ITeamMember) => u.fullName).join(', ');
    }
}
