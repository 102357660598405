import { Injectable } from '@angular/core';
import { StateContext, Store } from '@ngxs/store';
import {
    getRewardApplicationOptionType,
    IStagedPromotion,
    PromoStatusCode,
    RewardApplicationOptionType,
    SparkTypeCode
} from '../interfaces/promotion/staged-promotion.interface';
import { CreatePromotionStateModel } from '../state/create-promotion.state';
import { cloneDeep } from 'lodash';
import { LabelsState } from '../state/labels.state';
import {
    IncludeTypeCode,
    INearMiss,
    IQualification,
    IQualificationItem,
    IReward,
    IRewardCategory,
    IRewardItem,
    QualificationApplicationTypeCode,
    QualificationItemsRewardPreference,
    RewardApplicationTypeCode,
    RewardBasePriceTypeCode,
    RewardTypeCode
} from '../interfaces/promotion/promotion.interface';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import {
    BusinessUnitGroupIdsModel,
    BusinessUnitIdsModel,
    DetailsFormModel,
    SparksFormModel
} from '../interfaces/promotion/create-promotion.interface';
import {
    INearMissForm,
    IQualificationForm,
    IRewardCategoryForm,
    IRewardForm,
    isRewardCategoryForm,
    OfferDetailsFormModel,
    OfferRewardCategoryFormModel,
    OfferRewardFormModel
} from '../interfaces/promotion/promotion-offers.interface';
import { ISpark } from '../interfaces/promotion/spark.interface';
import { PromotionOfferService } from './promotion-offer.service';
import * as moment from 'moment';
import { ItemOrGroup } from '../interfaces/item-group.interface';

@Injectable()
export class StagedPromotionBuilderService {

    readonly DATE_TIME_RESTRICTION_CRONS = {
        WEEKDAY_ONLY: '* * * * MON-FRI',
        WEEKENDS_ONLY: '* * * * SAT,SUN',
        HAPPY_HOUR: '* 17 * * *'
    };

    constructor(
        private store: Store,
        private promotionOfferService: PromotionOfferService
    ) {
    }

    updatedStagedPromotion(ctx: StateContext<CreatePromotionStateModel>): IStagedPromotion {
        const state = ctx.getState();
        const detailsFormModel = state.detailsForm?.model;
        let stagedPromotion: IStagedPromotion = cloneDeep(state.stagedPromotion);
        if (!stagedPromotion) {
            stagedPromotion = {};
        }
        if (!stagedPromotion.promoStatusCode) {
            stagedPromotion.promoStatusCode = PromoStatusCode.DRAFT;
        }
        stagedPromotion.promotionName = detailsFormModel?.promotionName;
        const maxUses = parseFloat(state.offerDetailsForm?.model?.maximumUses);
        if (!isNaN(maxUses)) {
            stagedPromotion.maxUses = maxUses;
        } else {
            stagedPromotion.maxUses = undefined;
        }
        stagedPromotion.vendorFunded = detailsFormModel?.vendorFunded;
        if (detailsFormModel?.rewardApplicationType?.length) {
            if (RewardApplicationOptionType.HIGHEST_PRICE === detailsFormModel.rewardApplicationType) {
                stagedPromotion.rewardApplicationTypeCode = RewardApplicationTypeCode.HIGHEST_PRICE;
                stagedPromotion.qualifyApplicationTypeCode = QualificationApplicationTypeCode.LOWEST_PRICE;
            } else if (RewardApplicationOptionType.LOWEST_PRICE === detailsFormModel.rewardApplicationType) {
                stagedPromotion.rewardApplicationTypeCode = RewardApplicationTypeCode.LOWEST_PRICE;
                stagedPromotion.qualifyApplicationTypeCode = QualificationApplicationTypeCode.HIGHEST_PRICE;
            } else if (RewardApplicationOptionType.MIXED_HIGHEST_PRICE === detailsFormModel.rewardApplicationType) {
                stagedPromotion.rewardApplicationTypeCode = RewardApplicationTypeCode.HIGHEST_PRICE;
                stagedPromotion.qualifyApplicationTypeCode = QualificationApplicationTypeCode.HIGHEST_PRICE;
            } else {
                stagedPromotion.rewardApplicationTypeCode = RewardApplicationTypeCode.LOWEST_PRICE;
                stagedPromotion.qualifyApplicationTypeCode = QualificationApplicationTypeCode.LOWEST_PRICE;
            }
        } else {
            stagedPromotion.rewardApplicationTypeCode = undefined;
            stagedPromotion.qualifyApplicationTypeCode = undefined;
        }
        stagedPromotion.rewardBasePriceTypeCode = detailsFormModel?.rewardBasePrice;
        stagedPromotion.campaignId = detailsFormModel?.assignCampaign;
        const allPromotionLabels = this.store.selectSnapshot(LabelsState.promotionLabels);
        stagedPromotion.labels = detailsFormModel?.addLabels
            .map((tag: string) => allPromotionLabels?.find(label => label.labelId === tag));

        stagedPromotion.effectiveStartTime = detailsFormModel?.startDate.format('YYYY-MM-DDTHH:mm:ss');
        stagedPromotion.effectiveEndTime = detailsFormModel?.endDate?.format('YYYY-MM-DDTHH:mm:ss');
        stagedPromotion.effectiveSchedule = detailsFormModel?.otherLimitations ? this.DATE_TIME_RESTRICTION_CRONS[detailsFormModel.otherLimitations] : undefined;
        stagedPromotion.includedBusinessUnitIds = state.businessUnitIds?.includedBusinessUnitIds;
        stagedPromotion.excludedBusinessUnitIds = state.businessUnitIds?.excludedBusinessUnitIds;
        stagedPromotion.includedBusinessUnitGroupIds = state.businessUnitGroupIds?.includedBusinessUnitGroupIds;
        stagedPromotion.excludedBusinessUnitGroupIds = state.businessUnitGroupIds?.excludedBusinessUnitGroupIds;
        stagedPromotion.promotionType = state.offerDetailsForm?.model?.promotionType;
        stagedPromotion.rewardOperatorTypeCode = state.rewardOperatorType;
        stagedPromotion.autoApply = true;
        stagedPromotion.externalId = detailsFormModel?.externalId;
        stagedPromotion.longDescription = detailsFormModel?.longDescription;
        const sparksForms = state.sparksForms;
        const sparks: ISpark[] = [];
        if (sparksForms) {
            sparksForms.forEach((s: NgxsForm<SparksFormModel>) => {
                s = cloneDeep(s);
                const originalSpark = stagedPromotion.sparks?.find(orig => orig.sparkId === s.model.sparkId);
                if ([SparkTypeCode.SINGLE_USE_PROMO_CODE, SparkTypeCode.SINGLE_USE_PROMO_CODE_GENERATE].includes(s.model.sparkType)) {
                    stagedPromotion.autoApply = false;
                } else if (s.model.sparkType.startsWith(SparkTypeCode.TRANSACTION_ATTRIBUTE)) {
                    s.model.sparkType = SparkTypeCode.TRANSACTION_ATTRIBUTE;
                }
                sparks.push({
                    sparkId: s.model.sparkId,
                    sparkTypeCode: s.model.sparkType,
                    promotionId: stagedPromotion.promotionId,
                    content: JSON.stringify(s),
                    createTime: originalSpark?.createTime,
                    createBy: originalSpark?.createBy
                });
            });
        }
        stagedPromotion.sparks = sparks;

        stagedPromotion.templateOfferId = state.offerTemplate;

        this.buildQualifications(stagedPromotion, state);
        this.buildRewards(stagedPromotion, state);

        return stagedPromotion;
    }

    updateFormsFromStagedPromotion(ctx: StateContext<CreatePromotionStateModel>, stagedPromotion: IStagedPromotion, isEditMode: boolean): void {
        let otherLimitations = '';
        if (stagedPromotion.effectiveSchedule && isEditMode) {
            Object.entries(this.DATE_TIME_RESTRICTION_CRONS).forEach(([key, value]) => {
                if (value === stagedPromotion.effectiveSchedule) {
                    otherLimitations = key;
                }
            });
        }
        const promotionName = isEditMode ? stagedPromotion.promotionName : '';
        const assignCampaign = isEditMode ? stagedPromotion.campaignId : '';
        const addLabels = isEditMode ? stagedPromotion.labels.map(l => l.labelId) : [];
        const startDate = isEditMode ? moment(new Date(stagedPromotion.effectiveStartTime)) : null;
        const endDate = isEditMode && stagedPromotion.effectiveEndTime ? moment(new Date(stagedPromotion.effectiveEndTime)) : null;


        const detailsForm: NgxsForm<DetailsFormModel> = {
            model: {
                promotionName,
                vendorFunded: stagedPromotion.vendorFunded,
                rewardApplicationType: getRewardApplicationOptionType(stagedPromotion),
                rewardBasePrice: stagedPromotion.rewardBasePriceTypeCode,
                assignCampaign,
                addLabels,
                startDate,
                endDate,
                otherLimitations,
                externalId: stagedPromotion.externalId,
                longDescription: stagedPromotion.longDescription
            }
        };

        const offerDetailsForm: NgxsForm<OfferDetailsFormModel> = {
            model: {
                promotionType: stagedPromotion.promotionType,
                maximumUses: stagedPromotion.maxUses?.toString(),
            }
        };

        const businessUnits: BusinessUnitIdsModel = {
            includedBusinessUnitIds: stagedPromotion.includedBusinessUnitIds,
            excludedBusinessUnitIds: stagedPromotion.excludedBusinessUnitIds
        };

        const businessUnitGroups: BusinessUnitGroupIdsModel = {
            includedBusinessUnitGroupIds: stagedPromotion.includedBusinessUnitGroupIds,
            excludedBusinessUnitGroupIds: stagedPromotion.excludedBusinessUnitGroupIds
        };

        const offerTemplate = stagedPromotion.templateOfferId;
        const rewardOperatorType = stagedPromotion.rewardOperatorTypeCode;
        const qualificationForms = this.buildQualificationForms(stagedPromotion, isEditMode);

        ctx.patchState({
            stagedPromotion: isEditMode ? stagedPromotion : { promoStatusCode: stagedPromotion.promoStatusCode },
            businessUnitIds: businessUnits,
            businessUnitGroupIds: businessUnitGroups,
            detailsForm,
            sparksForms: this.buildSparksForms(stagedPromotion.sparks, isEditMode),
            offerDetailsForm,
            offerQualificationsForms: qualificationForms,
            offerRewardsForms: this.buildRewardForms(stagedPromotion, isEditMode),
            offerNearMissForms: this.buildNearMissForms(stagedPromotion, qualificationForms, isEditMode),
            offerTemplate,
            rewardOperatorType,
            editMode: isEditMode,
        });

    }

    private getNearMissConfig(state: CreatePromotionStateModel, tier: number, qualificationIndex: number, referenceId?: string): INearMiss {
        if (+state.offerNearMissForms?.[tier]?.form.model?.nearMissQualification === qualificationIndex) {
            const nearMissModel = state.offerNearMissForms[tier].form.model;
            return {
                referenceId: referenceId,
                referenceType: 'QUALIFICATION',
                triggerAmount: nearMissModel.nearMissAmount,
                message: nearMissModel.nearMissMessage
            };
        }
    }

    private buildQualifications(stagedPromotion: IStagedPromotion, state: CreatePromotionStateModel): void {
        const originalQualifications = stagedPromotion.qualifications;
        stagedPromotion.qualifications = [];
        Object.values(state.offerQualificationsForms).forEach((qualificationForms: IQualificationForm[]) => {
            qualificationForms.forEach((q, index) => {
                const originalQualification = originalQualifications?.find(orig => orig.qualificationId === q.qualificationId);
                const qualificationItems: IQualificationItem[] = [];
                q.form.model?.includedItems?.map((i: ItemOrGroup): IQualificationItem => ({
                    qualificationId: q.qualificationId,
                    qualificationMerchandiseId: i.id,
                    description: i.name,
                    qualificationItemType: IncludeTypeCode.INCLUDE,
                    qualificationItemAttributes: i.attributes || []
                })).forEach(i => qualificationItems.push(i));

                q.form.model?.excludedItems?.map((i: ItemOrGroup): IQualificationItem => ({
                    qualificationId: q.qualificationId,
                    qualificationMerchandiseId: i.id,
                    description: i.name,
                    qualificationItemType: IncludeTypeCode.EXCLUDE,
                    qualificationItemAttributes: i.attributes || []
                })).forEach(i => qualificationItems.push(i));


                stagedPromotion.qualifications.push({
                    qualificationId: q.qualificationId,
                    promotionId: stagedPromotion.promotionId,
                    qualificationTypeCode: this.promotionOfferService.translateQualification(
                        q.form.model?.qualificationLevel,
                        q.form.model?.qualificationType),
                    qualification: +q.form.model?.qualificationAmount,
                    qualItemsRewardPreference: q.form.model?.qualificationItemsRewardPreference,
                    qualificationItems: qualificationItems,
                    createTime: originalQualification?.createTime,
                    createBy: originalQualification?.createBy,
                    tier: q.form.model?.tier,
                    nearMissConfiguration: this.getNearMissConfig(state, q.form.model?.tier, index, q.qualificationId)
                });
            });
        });
    }

    private buildRewards(stagedPromotion: IStagedPromotion, state: CreatePromotionStateModel): void {
        const originalRewards = stagedPromotion.rewards;
        stagedPromotion.rewards = [];
        Object.values(state.offerRewardsForms).forEach((rewardsForms: (IRewardForm | IRewardCategoryForm)[]) => {
            rewardsForms.forEach((r: IRewardForm | IRewardCategoryForm) => {
                const rewardItems: IRewardItem[] = [];
                r.form.model?.includedItems?.map(i => ({
                    rewardId: r.rewardId,
                    rewardMerchandiseId: i.id,
                    description: i.name,
                    rewardItemType: IncludeTypeCode.INCLUDE,
                })).forEach(i => rewardItems.push(i));

                r.form.model?.excludedItems?.map(i => ({
                    rewardId: r.rewardId,
                    rewardMerchandiseId: i.id,
                    description: i.name,
                    rewardItemType: IncludeTypeCode.EXCLUDE,
                })).forEach(i => rewardItems.push(i));

                const originalReward = originalRewards?.find(orig => orig.rewardId === r.rewardId);
                if (isRewardCategoryForm(r)) {
                    const offerRewardCategoryFormModel: OfferRewardCategoryFormModel = r.form.model;
                    stagedPromotion.rewards.push({
                        rewardId: r.rewardId,
                        promotionId: stagedPromotion.promotionId,
                        categoryId: offerRewardCategoryFormModel.rewardCategory,
                        rewardCategoryType: offerRewardCategoryFormModel.rewardType,
                        rewardType: RewardTypeCode.CATEGORY,
                        reward: this.promotionOfferService.translateRewardAmount(offerRewardCategoryFormModel),
                        maxRewardAmount: null,
                        rewardQuantity: 1,
                        qualificationEligible: false,
                        qualificationProrate: false,
                        vendorFunded: false,
                        rewardItems: rewardItems,
                        rewardBasePriceCode: RewardBasePriceTypeCode.RETAIL,
                        createTime: originalReward?.createTime,
                        createBy: originalReward?.createBy,
                        __modelType: 'REWARD_CATEGORY',
                        tier: offerRewardCategoryFormModel?.tier
                    });
                } else {
                    const offerRewardFormModel: OfferRewardFormModel = r.form.model;
                    let rewardType: string;
                    try {
                        rewardType = this.promotionOfferService.translateReward(
                            offerRewardFormModel?.rewardLevel,
                            offerRewardFormModel?.rewardType
                        );
                    } catch (e) {
                        rewardType = undefined;
                    }
                    stagedPromotion.rewards.push({
                        rewardId: r.rewardId,
                        promotionId: stagedPromotion.promotionId,
                        rewardType,
                        reward: this.promotionOfferService.translateRewardAmount(offerRewardFormModel),
                        maxRewardAmount: this.promotionOfferService.translateMaxRewardAmount(offerRewardFormModel),
                        rewardQuantity: +offerRewardFormModel?.rewardQuantity,
                        qualificationEligible: offerRewardFormModel?.rewardQualificationEligible,
                        qualificationProrate: offerRewardFormModel?.rewardQualificationProrate,
                        vendorFunded: offerRewardFormModel?.vendorFunded,
                        rewardItems: rewardItems,
                        rewardBasePriceCode: RewardBasePriceTypeCode.RETAIL,
                        createTime: originalReward?.createTime,
                        createBy: originalReward?.createBy,
                        tier: offerRewardFormModel?.tier,
                    });
                }
            });
        });

    }

    private buildNearMissForms(stagedPromotion: IStagedPromotion, qualificationFormsByTier: {
        [tier: number]: IQualificationForm[];
    }, isEditMode: boolean): { [tier: number]: INearMissForm } {
        const nearMissFormsByTier = {};
        stagedPromotion.qualifications.map(q => q.nearMissConfiguration).filter(nm => !!nm).forEach(nearMiss => {
            const tier = stagedPromotion.qualifications.find(q => q.qualificationId === nearMiss.referenceId)?.tier || 1;
            nearMissFormsByTier[tier] = {
                qualificationId: isEditMode ? nearMiss.referenceId : undefined,
                form: {
                    dirty: false,
                    status: 'VALID',
                    model: {
                        // Only one near miss config per tier. So we look for the first qualification flagged for a link in that tier
                        nearMissQualification: `${qualificationFormsByTier[tier].indexOf(qualificationFormsByTier[tier].find(q => q.linkToNearMiss))}`,
                        nearMissAmount: nearMiss.triggerAmount,
                        nearMissMessage: nearMiss.message,
                    }
                },
            };
        });
        return nearMissFormsByTier;
    }

    private buildQualificationForms(stagedPromotion: IStagedPromotion, isEditMode: boolean): {
        [tier: number]: IQualificationForm[];
    } {
        const offerQualificationsForms = {};
        stagedPromotion.qualifications.forEach((q: IQualification) => {
            const tier = q.tier || 1;
            const qualificationForm: IQualificationForm = {
                qualificationId: isEditMode ? q.qualificationId : undefined,
                linkToNearMiss: !!q.nearMissConfiguration,
                form: {
                    dirty: false,
                    status: 'VALID',
                    model: {
                        qualificationLevel: this.promotionOfferService.translateQualificationLevel(q.qualificationTypeCode),
                        qualificationType: this.promotionOfferService.translateQualificationType(q.qualificationTypeCode),
                        qualificationAmount: q.qualification?.toString(),
                        qualificationItemsRewardPreference: this.getQualificationItemsRewardPreferenceForForm(q),
                        includedItems: q.qualificationItems.filter((i: IQualificationItem) => i.qualificationItemType === IncludeTypeCode.INCLUDE)
                            .map((i: IQualificationItem) => ({
                                id: i.qualificationMerchandiseId,
                                name: i.description,
                                attributes: i.qualificationItemAttributes || []
                            })),
                        excludedItems: q.qualificationItems.filter((i: IQualificationItem) => i.qualificationItemType === IncludeTypeCode.EXCLUDE)
                            .map((i: IQualificationItem) => ({
                                id: i.qualificationMerchandiseId,
                                name: i.description,
                                attributes: i.qualificationItemAttributes || []
                            })),
                        tier
                    }
                },
            };
            const qualificationsForTier = offerQualificationsForms[tier] || [];
            qualificationsForTier.push(qualificationForm);
            offerQualificationsForms[tier] = qualificationsForTier;
        });
        return offerQualificationsForms;
    }

    private getQualificationItemsRewardPreferenceForForm(qualification: IQualification): string {
        if (qualification.qualItemsRewardPreference) {
            return qualification.qualItemsRewardPreference;
        } else if (qualification.rewardEligible) {
            return QualificationItemsRewardPreference.ALLOW;
        } else {
            return QualificationItemsRewardPreference.PREVENT;
        }
    }

    private buildRewardForms(stagedPromotion: IStagedPromotion, isEditMode: boolean): {
        [tier: number]: (IRewardForm | IRewardCategoryForm)[];
    } {
        const offerRewardForms: { [tier: number]: (IRewardForm | IRewardCategoryForm)[] } = {};
        stagedPromotion.rewards.forEach((r: IReward | IRewardCategory) => {
            const tier = r.tier || 1;
            if ('categoryId' in r) {
                let rewardAmount = r.reward;
                if (rewardAmount && r.rewardCategoryType === 'PCT') {
                    rewardAmount *= 100;
                }
                const rewardCategoryForm: IRewardCategoryForm = {
                    rewardId: isEditMode ? r.rewardId : undefined,
                    form: {
                        dirty: false,
                        status: 'VALID',
                        model: {
                            rewardCategory: r.categoryId,
                            rewardType: r.rewardCategoryType,
                            rewardAmount: rewardAmount?.toString(),
                            includedItems: r.rewardItems.filter((i: IRewardItem) => i.rewardItemType === 'INCLUDE')
                                .map((i: IRewardItem) => ({
                                    id: i.rewardMerchandiseId,
                                    name: i.description,
                                })),
                            excludedItems: r.rewardItems.filter((i: IRewardItem) => i.rewardItemType === 'EXCLUDE')
                                .map((i: IRewardItem) => ({
                                    id: i.rewardMerchandiseId,
                                    name: i.description,
                                })),
                            tier
                        }
                    }
                };
                const rewardsForTier = offerRewardForms[tier] || [];
                rewardsForTier.push(rewardCategoryForm);
                offerRewardForms[tier] = rewardsForTier;
            } else {
                const rewardType = this.promotionOfferService.translateRewardType(r.rewardType);
                let rewardAmount = r.reward;
                if (rewardAmount && rewardType === RewardTypeCode.PCT) {
                    rewardAmount *= 100;
                }
                const rewardForm: IRewardForm = {
                    rewardId: isEditMode ? r.rewardId : undefined,
                    form: {
                        dirty: false,
                        status: 'VALID',
                        model: {
                            rewardLevel: this.promotionOfferService.translateRewardLevel(r.rewardType),
                            rewardType: rewardType,
                            rewardQuantity: r.rewardQuantity?.toString(),
                            rewardAmount: rewardAmount?.toString(),
                            rewardMaxAmount: r.maxRewardAmount?.toString(),
                            rewardQualificationEligible: r.qualificationEligible,
                            rewardQualificationProrate: r.qualificationProrate,
                            vendorFunded: r.vendorFunded,
                            includedItems: r.rewardItems.filter((i: IRewardItem) => i.rewardItemType === IncludeTypeCode.INCLUDE)
                                .map((i: IRewardItem) => ({
                                    id: i.rewardMerchandiseId,
                                    name: i.description,
                                })),
                            excludedItems: r.rewardItems.filter((i: IRewardItem) => i.rewardItemType === IncludeTypeCode.EXCLUDE)
                                .map((i: IRewardItem) => ({
                                    id: i.rewardMerchandiseId,
                                    name: i.description,
                                })),
                            tier
                        }
                    }
                };
                const rewardsForTier = offerRewardForms[tier] || [];
                rewardsForTier.push(rewardForm);
                offerRewardForms[tier] = rewardsForTier;
            }
        });
        return offerRewardForms;
    }

    private buildSparksForms(sparks: ISpark[], isEditMode: boolean): NgxsForm<SparksFormModel>[] {
        return sparks.map((spark: ISpark) => {
            const model: NgxsForm<SparksFormModel> = JSON.parse(spark.content);
            model.model.sparkId = isEditMode ? spark.sparkId : undefined;
            model.model.sparkType = spark.sparkTypeCode === SparkTypeCode.TRANSACTION_ATTRIBUTE && !!model.model.attribute ?
                `${SparkTypeCode.TRANSACTION_ATTRIBUTE}-${model.model.attribute}` : spark.sparkTypeCode;
            return model;
        });
    }
}
