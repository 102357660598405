import { ITeam } from './team.interface';
import { IUser } from './user.interface';

export namespace AppAccessType {
    export const VIEW = 'VIEW';
    export const REVIEW = 'REVIEW';
    export const WRITE = 'WRITE';
}

export interface IAppAccess {
    applicationId: string;
    accessType: string;
}

export interface GetUsersAndTeamsWithAppAccessResponse {
    users: IUser[];
    teams: ITeam[];
}
