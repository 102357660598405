import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizedStateModel } from '../state/authorized.state';
import { UserConfigurationStateModel } from '../state/user-configuration.state';
import {
    CreateTeamRequest,
    CreateTeamResponse,
    GetTeamsResponse,
    UpdateTeamResponse
} from '../interfaces/team.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { ITeamMember } from '../interfaces/team-member.interface';
import { GetUsersAndTeamsWithAppAccessResponse, IAppAccess } from '../interfaces/app-access.interface';
import { AdminChangeRequest, EditUserRequest, UpdatePasswordRequest } from '../interfaces/user-request.interface';
import { IUser } from '../interfaces/user.interface';
import { IUserResponse } from '../interfaces/user-response.interface';
import { INotificationSetting } from '../interfaces/notification.interface';

@Injectable()
export class UserService {

    readonly userServicePrefix = 'app/user/';
    readonly authServicePrefix = 'app/auth/';

    constructor(private http: HttpClient) {
    }

    getAuthorizedItems(): Observable<AuthorizedStateModel> {
        return this.http.get<AuthorizedStateModel>(this.userServicePrefix + 'authorizedItems');
    }

    inviteUser(email: string, inviter: IUser): Observable<IGenericServerResponse> {
        return this.http.post<IGenericServerResponse>(this.userServicePrefix + 'invite', { emailAddress: email, inviter });
    }

    editUser(request: EditUserRequest): Observable<IUserResponse> {
        return this.http.put<IUserResponse>(this.userServicePrefix + 'edit', request);
    }

    updateUserNotificationSettings(userId: string, notificationSettings: INotificationSetting[]) {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'notificationSettings', { userId, notificationSettings });
    }

    userAdminChange(request: AdminChangeRequest): Observable<IUserResponse> {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'admin', request);
    }

    updatePassword(request: UpdatePasswordRequest): Observable<IUserResponse> {
        return this.http.post<IUserResponse>(this.authServicePrefix + 'update-password', request);
    }

    deleteUser(userId: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'delete', { userId });
    }

    getActiveAndPendingUsers(): Observable<UserConfigurationStateModel> {
        return this.http.get<UserConfigurationStateModel>(this.userServicePrefix + 'activeAndPending');
    }

    acceptPendingRequest(userId: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'acceptRequest', { userId });
    }

    rejectPendingRequest(userId: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'rejectRequest', { userId });
    }

    getTeamsManagedByUser(userId: string): Observable<GetTeamsResponse> {
        return this.http.post<GetTeamsResponse>(this.userServicePrefix + 'teams/managed', { userId });
    }

    deleteTeam(teamId: string): Observable<IGenericServerResponse> {
        return this.http.put<IGenericServerResponse>(this.userServicePrefix + 'team/delete', { teamId });
    }

    cancelInvite(email: string): Observable<IGenericServerResponse> {
        return this.http.post<IGenericServerResponse>(this.userServicePrefix + 'invite/delete', { emailAddress: email });
    }

    updateTeam(teamId: string, updatedTeamMembers: ITeamMember[], updatedAppAccessList: IAppAccess[]): Observable<UpdateTeamResponse> {
        return this.http.put<UpdateTeamResponse>(this.userServicePrefix + 'team/update', { teamId, updatedTeamMembers, updatedAppAccessList });
    }

    createTeam(request: CreateTeamRequest): Observable<CreateTeamResponse> {
        return this.http.post<CreateTeamResponse>(this.userServicePrefix + 'team/create', request);
    }

    getUsersAndTeamsWithAppAccess(applicationId: string, accessType: string): Observable<GetUsersAndTeamsWithAppAccessResponse> {
        return this.http.post<GetUsersAndTeamsWithAppAccessResponse>(this.userServicePrefix + 'usersAndTeamsWithAppAccess', { applicationId, accessType });
    }

}
