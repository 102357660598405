import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { GetWorkflowConfigsResponse, GetWorkflowItemsRequest, IReview, ITestEnvironmentDeploy, IWorkflowConfig, IWorkflowDetails, ReviewStatusTypeCode, TestEnvironmentDeployStatusTypeCode, WorkflowStatusTypeCode, WorkflowTypeCode } from '../interfaces/review.interface';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ContextService } from '../services/context.service';
import { PromotionWorkflowActions } from './promotion-workflow.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthorizedState, AuthorizedStateModel } from './authorized.state';
import { ObjectTypeCode } from '../interfaces/promotion/object-type-code';
import produce from 'immer';
import { CreatePromotionActions } from './create-promotion.actions';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { PromotionService } from '../services/promotion.service';
import { PromoStatusCode } from '../interfaces/promotion/staged-promotion.interface';
import { cloneDeep } from 'lodash';

export class PromotionWorkflowStateModel {
    workflowDetailsById: { [x: string]: IWorkflowDetails };
    workflowConfigs: IWorkflowConfig[];
}

const PROMOTION_WORKFLOW_STATE_TOKEN = new StateToken<PromotionWorkflowStateModel>('promotionWorkflow');

@State<PromotionWorkflowStateModel>({
    name: PROMOTION_WORKFLOW_STATE_TOKEN,
    defaults: {
        workflowDetailsById: {},
        workflowConfigs: [],
    }
})
@Injectable()
export class PromotionWorkflowState {

    constructor(
        private contextService: ContextService,
        private promotionService: PromotionService,
        private store: Store
    ) {
    }

    static reviews(promotionId: string) {
        return createSelector([PromotionWorkflowState],
            (state: PromotionWorkflowStateModel): IReview[] => {
                const reviews = state.workflowDetailsById[promotionId]?.reviews;
                if (reviews) {
                    return reviews.filter(review => review.reviewStatusTypeCode !== ReviewStatusTypeCode.CLOSED);
                }
                return [];
            });
    }

    static testEnvironmentDeploys(promotionId: string) {
        return createSelector([PromotionWorkflowState], (state: PromotionWorkflowStateModel): ITestEnvironmentDeploy[] => {
            const testEnvironmentDeploys = state.workflowDetailsById[promotionId]?.testEnvironmentDeploys;
            if (testEnvironmentDeploys) {
                return testEnvironmentDeploys;
            }
            return [];
        });
    }

    static submittedByName(promotionId: string) {
        return createSelector([PromotionWorkflowState],
            (state: PromotionWorkflowStateModel): string => state.workflowDetailsById[promotionId]?.submittedByName || '');
    }

    static submittedTime(promotionId: string) {
        return createSelector([PromotionWorkflowState],
            (state: PromotionWorkflowStateModel): Date => state.workflowDetailsById[promotionId]?.submittedTime);
    }

    static isActiveReviewer(promotionId: string) {
        return createSelector([PromotionWorkflowState.promotionIdsToReview],
            (promotionIds: string[]): boolean => promotionIds.includes(promotionId));
    }

    static openReview(promotionId: string) {
        return createSelector([PromotionWorkflowState, AuthorizedState],
            (state: PromotionWorkflowStateModel, authorizedState: AuthorizedStateModel): IReview => {
                const workflowConfigIds = this.getListOfWorkflowConfigIdsUserSatisfies(state, authorizedState);
                const reviewDetails = state.workflowDetailsById[promotionId];
                if (reviewDetails) {
                    const openReviews = reviewDetails.reviews.filter(review =>
                        workflowConfigIds.includes(review.workflowConfigId) &&
                        review.reviewStatusTypeCode === ReviewStatusTypeCode.OPEN
                    );
                    return openReviews[0];
                }
            });
    }

    static workflowConfigsFromWorkflowItems(reviews: IReview[], testEnvironmentDeploys: ITestEnvironmentDeploy[]) {
        return createSelector([PromotionWorkflowState],
            (state: PromotionWorkflowStateModel): IWorkflowConfig[] => {
                const workflowConfigs = [];
                const workflowConfigIdSet = new Set<string>();
                if (reviews) {
                    reviews.forEach(review => workflowConfigIdSet.add(review.workflowConfigId));
                }
                if (testEnvironmentDeploys) {
                    testEnvironmentDeploys.forEach(testEnvironmentDeploy => workflowConfigIdSet.add(testEnvironmentDeploy.workflowConfigId));
                }
                const workflowConfigIdsSorted = Array.from(workflowConfigIdSet);
                workflowConfigIdsSorted.sort((a: string, b: string): number => {
                    const aParsed = +a;
                    const bParsed = +b;
                    if (aParsed > bParsed) {
                        return 1;
                    }
                    return -1;
                });
                workflowConfigIdsSorted.forEach(configId => workflowConfigs.push(state.workflowConfigs.find(workflowConfig => workflowConfig.workflowConfigId === configId)));
                return workflowConfigs;
            });
    }

    static getListOfWorkflowConfigIdsUserSatisfies(state: PromotionWorkflowStateModel, authorizedState: AuthorizedStateModel): string[] {
        const authorizedUser = authorizedState.authorizedUser;
        const authorizedUserTeams = authorizedState.authorizedUserTeams;
        return state.workflowConfigs.filter(config => {
            if (config.workflowType === WorkflowTypeCode.TEAM) {
                return authorizedUserTeams.some(team => team.teamId === config.assignmentId);
            } else if (config.workflowType === WorkflowTypeCode.USER) {
                return authorizedUser.userId === config.assignmentId;
            }
            return false;
        }).map(config => config.workflowConfigId);
    }

    private static isUserAllowedToReview(state: PromotionWorkflowStateModel, authorizedState: AuthorizedStateModel, review: IReview, reviewDetails: IWorkflowDetails): boolean {
        const assignment = state.workflowConfigs.find(config => config.workflowConfigId === review.workflowConfigId);
        if (assignment.workflowType === WorkflowTypeCode.USER) {
            return review.reviewStatusTypeCode === ReviewStatusTypeCode.OPEN;
        }
        return review.reviewStatusTypeCode === ReviewStatusTypeCode.OPEN
            && !reviewDetails.reviews.some(r => r.workflowConfigId === review.workflowConfigId
                && r.reviewStatusTypeCode === ReviewStatusTypeCode.APPROVED
                && r.reviewingUser?.userId === authorizedState.authorizedUser.userId);
    }

    @Selector([PromotionWorkflowState, AuthorizedState])
    static promotionIdsToReview(state: PromotionWorkflowStateModel, authorizedState: AuthorizedStateModel): string[] {
        const workflowConfigIds = this.getListOfWorkflowConfigIdsUserSatisfies(state, authorizedState);
        const objectIds = [];
        for (const [objectId, workflowDetails] of Object.entries(state.workflowDetailsById)) {
            if (workflowDetails?.reviews?.some(review => workflowConfigIds.includes(review.workflowConfigId)
                && this.isUserAllowedToReview(state, authorizedState, review, workflowDetails))) {
                objectIds.push(objectId);
            }
        }
        return objectIds;
    }

    @Selector()
    static activeWorkflowConfigs(state: PromotionWorkflowStateModel): IWorkflowConfig[] {
        return state.workflowConfigs.filter(config => config?.workflowStatus === WorkflowStatusTypeCode.ACTIVE);
    }

    @Action(PromotionWorkflowActions.LoadWorkflowConfig)
    loadWorkflowConfig(ctx: StateContext<PromotionWorkflowStateModel>): Observable<GetWorkflowConfigsResponse> {
        return this.contextService.getWorkflowConfigs(ObjectTypeCode.PROMOTION)?.pipe(
            tap(result => {
                ctx.patchState({
                    workflowConfigs: result?.workflowConfigs || []
                });
            })
        );
    }

    @Action(PromotionWorkflowActions.GetWorkflowItems)
    getWorkflowItems(ctx: StateContext<PromotionWorkflowStateModel>, action: PromotionWorkflowActions.GetWorkflowItems): Observable<any> {
        if (this.isValidRequest(action.request)) {
            const observables = [this.contextService.getWorkflowItems(action.request).pipe(
                tap(result => {
                    if (result?.workflowDetails) {
                        ctx.setState(produce(draft => {
                            for (const [objectId, workflowDetails] of Object.entries(result.workflowDetails)) {
                                draft.workflowDetailsById[objectId] = workflowDetails;
                            }
                        }));
                    }
                })
            )];
            if (!ctx.getState().workflowConfigs.length) {
                observables.push(this.store.dispatch(PromotionWorkflowActions.LoadWorkflowConfig));
            }
            return forkJoin(observables);
        }
    }

    @Action(PromotionWorkflowActions.SaveWorkflowConfigs)
    saveWorkflows(ctx: StateContext<PromotionWorkflowStateModel>, action: PromotionWorkflowActions.SaveWorkflowConfigs): Observable<IGenericServerResponse> {
        return this.contextService.saveWorkflowConfigs(action.workflowConfigs, action.objectType).pipe(
            tap(result => {
                if (result.success) {
                    this.store.dispatch(PromotionWorkflowActions.LoadWorkflowConfig);
                } else {
                    throw new Error(result.message);
                }
            }));
    }

    @Action(PromotionWorkflowActions.ApproveReview)
    approveReview(ctx: StateContext<PromotionWorkflowStateModel>, action: PromotionWorkflowActions.ApproveReview): Observable<any> {
        if (action.review) {
            return this.contextService.approveReview(action.review.reviewId, action.review.workflowConfigId).pipe(
                mergeMap(result => {
                    const updatedReview = result.review;
                    if (updatedReview && result.success) {
                        ctx.setState(produce((draft: PromotionWorkflowStateModel) => {
                            const reviews = draft.workflowDetailsById[updatedReview.objectId]?.reviews;
                            if (reviews) {
                                reviews.splice(reviews.findIndex(review => review.reviewId === updatedReview.reviewId && review.workflowConfigId === updatedReview.workflowConfigId), 1, updatedReview);
                            }
                        }));
                        const state = ctx.getState();
                        const currentStep = state.workflowConfigs.find(workflowConfig => workflowConfig.workflowConfigId === updatedReview.workflowConfigId);
                        if (this.isStepComplete(state, currentStep, updatedReview)) {
                            return this.initializeNextStep(state, currentStep, updatedReview);
                        }
                    }
                    // mergeMap requires an observable to be returned in all cases so this is a do nothing response
                    return of('');
                })
            );
        }
    }

    @Action(PromotionWorkflowActions.RejectReview)
    rejectReview(ctx: StateContext<PromotionWorkflowStateModel>, action: PromotionWorkflowActions.RejectReview): Observable<any> {
        if (action.review) {
            return this.contextService.rejectReview(action.review.reviewId, action.review.workflowConfigId).pipe(
                mergeMap(result => {
                    if (result.success) {
                        return this.store.dispatch(CreatePromotionActions.BackToDraft);
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(PromotionWorkflowActions.RetryTestEnvironmentDeploy)
    retryTestEnvironmentDeploy(ctx: StateContext<PromotionWorkflowStateModel>, action: PromotionWorkflowActions.RetryTestEnvironmentDeploy): Observable<any> {
        const testEnvironmentDeploys = ctx.getState().workflowDetailsById[action.objectId]?.testEnvironmentDeploys;
        if (testEnvironmentDeploys) {
            let deployToRetry = testEnvironmentDeploys.find(deploy => deploy.workflowConfigId === action.workflowConfigId);
            if (deployToRetry) {
                deployToRetry = cloneDeep(deployToRetry);
                deployToRetry.testEnvDeployStatus = TestEnvironmentDeployStatusTypeCode.AWAITING_DEPLOY;
                return this.contextService.saveTestEnvironmentDeploy(deployToRetry);
            }
        }
    }

    private isValidRequest(request: GetWorkflowItemsRequest): boolean {
        return request && request.objectType === ObjectTypeCode.PROMOTION && (!!request.objectId || !!request.objectIds);
    }

    private isStepComplete(state: PromotionWorkflowStateModel, currentWorkflowConfig: IWorkflowConfig, updatedReview: IReview): boolean {
        const stepSequence = currentWorkflowConfig.workflowStepSequence;
        const objectId = updatedReview.objectId;
        return state.workflowConfigs
            .filter(workflowConfig => workflowConfig.workflowStepSequence === stepSequence && workflowConfig.workflowStatus === WorkflowStatusTypeCode.ACTIVE)
            .every(workflowConfig => this.isWorkflowComplete(state, workflowConfig, objectId));
    }

    private isWorkflowComplete(state: PromotionWorkflowStateModel, workflowConfig: IWorkflowConfig, objectId: string): boolean {
        if (workflowConfig.workflowType === WorkflowTypeCode.TEST_ENVIRONMENT_DEPLOY) {
            return state.workflowDetailsById[objectId]?.testEnvironmentDeploys
                .filter(deploy => deploy.workflowConfigId === workflowConfig.workflowConfigId)
                [0]?.testEnvDeployStatus === TestEnvironmentDeployStatusTypeCode.DEPLOYED;
        }
        return state.workflowDetailsById[objectId]?.reviews
            .filter(review =>
                review.workflowConfigId === workflowConfig.workflowConfigId &&
                review.reviewStatusTypeCode === ReviewStatusTypeCode.APPROVED
            ).length >= workflowConfig.numberOfReviewsRequired;
    }

    private initializeNextStep(state: PromotionWorkflowStateModel, currentStep: IWorkflowConfig, updatedReview: IReview): Observable<any> {
        const activeConfigs = state.workflowConfigs
            .filter(workflowConfig => workflowConfig.workflowStatus === WorkflowStatusTypeCode.ACTIVE);
        const nextStep = currentStep.workflowStepSequence + 1;
        if (activeConfigs.some(workflowConfig => workflowConfig.workflowStepSequence === nextStep)) {
            return this.promotionService.initializeWorkflowStep(nextStep, updatedReview.objectId).pipe(
                mergeMap(newStatus => {
                    if (newStatus === PromoStatusCode.PENDING_PUBLISH) {
                        return this.store.dispatch(CreatePromotionActions.WorkflowCompleted);
                    }
                    return of(newStatus);
                })
            );
        } else {
            return this.store.dispatch(CreatePromotionActions.WorkflowCompleted);
        }
    }

}
