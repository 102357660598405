import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetItemGroupsResponse, GetStagedItemGroupsResponse, IStagedItemGroup, StagedItemGroupActionResponse } from '../interfaces/item-group.interface';
import { GetItemResponse, GetItemsResponse } from '../interfaces/item.interface';
import { IUploadedFile } from '../interfaces/promotion/uploaded-file.interface';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class ItemService {

    readonly itemServicePrefix = 'app/items/';
    getItemByIdCache: { [itemId: string]: Observable<GetItemResponse> } = {};

    constructor(private http: HttpClient) {
    }

    getItemGroups(): Observable<GetItemGroupsResponse> {
        return this.http.get<GetItemGroupsResponse>(this.itemServicePrefix + 'groups');
    }

    getItemsByGroup(groupId: string): Observable<GetItemsResponse> {
        return this.http.get<GetItemsResponse>(this.itemServicePrefix + 'itemsByGroup/' + groupId);
    }

    getItemById(itemId: string): Observable<GetItemResponse> {
        if (!this.getItemByIdCache[itemId]) {
            this.getItemByIdCache[itemId] = this.http.get<GetItemResponse>(this.itemServicePrefix + 'search/' + itemId)
                .pipe(shareReplay());
        }
        return this.getItemByIdCache[itemId];
    }

    extractItemOrGroupIdsFromFile(uploadedFile: IUploadedFile, separatorExp: RegExp): Observable<string[]> {
        return this.http.get(uploadedFile.url, { responseType: 'text' }).pipe(map(data =>
            data ? data.split(separatorExp).filter(e => e.length !== 0) : []));
    }

    searchItemsByIds(itemIds: string[]): Observable<GetItemsResponse> {
        return this.http.post<GetItemsResponse>(this.itemServicePrefix + 'searchItems', { itemIds });
    }

    getStagedItemGroups(): Observable<GetStagedItemGroupsResponse> {
        return this.http.get<GetStagedItemGroupsResponse>(this.itemServicePrefix + 'groups/staged/all');
    }

    saveStagedItemGroup(stagedItemGroup: IStagedItemGroup, create: boolean): Observable<StagedItemGroupActionResponse> {
        return this.http.put<StagedItemGroupActionResponse>(this.itemServicePrefix + 'groups/staged/save', { stagedItemGroup, create });
    }

    deleteStagedItemGroup(stagedItemGroupId: string): Observable<StagedItemGroupActionResponse> {
        return this.http.put<StagedItemGroupActionResponse>(this.itemServicePrefix + 'groups/staged/delete', { stagedItemGroupId });
    }
}
