import { IOfferTemplate } from '../interfaces/promotion/promotion-offers.interface';
import { SparksFormModel } from '../interfaces/promotion/create-promotion.interface';

export namespace CreatePromotionActions {

    export class LoadPromotion {
        static readonly type = '[Create Promotion] Load Promotion';
        constructor(public id: string) {
        }
    }

    export class ClonePromotion {
        static readonly type = '[Create Promotion] Clone Promotion';
        constructor(public id: string) {
        }
    }

    export class UpdateStagedPromotion {
        static readonly type = '[Create Promotion] Update Staged Promotion';
    }

    export class UpdateStagedPromotionStatus {
        static readonly type = '[Create Promotion] Update Staged Promotion Status';
        constructor(public newStatus: string) {
        }
    }

    export class UpdatePublishedPromotion {
        static readonly type = '[Create Promotion] Update Published Promotion';
    }

    export class SubmitForApproval {
        static readonly type = '[Create Promotion] Submit For Approval';
    }

    export class BackToDraft {
        static readonly type = '[Create Promotion] Back To Draft';
    }

    export class WorkflowCompleted {
        static readonly type = '[Create Promotion] Reviews Completed';
    }

    export class AddToIncludedBusinessUnitGroups {
        static readonly type = '[Create Promotion] Add to Included Business Unit Groups';
        constructor(public groupName: string) {
        }
    }

    export class AddToIncludedBusinessUnits {
        static readonly type = '[Create Promotion] Add to Included Business Units';
        constructor(public businessUnitIds: string[]) {
        }
    }

    export class RemoveFromIncludedBusinessUnitGroups {
        static readonly type = '[Create Promotion] Remove from Included Business Unit Groups';
        constructor(public groupId: string) {
        }
    }

    export class RemoveFromIncludedBusinessUnits {
        static readonly type = '[Create Promotion] Remove from Included Business Units';
        constructor(public businessUnitId: string) {
        }
    }

    export class AddToExcludedBusinessUnitGroups {
        static readonly type = '[Create Promotion] Add to Excluded Business Unit Groups';
        constructor(public groupName: string) {
        }
    }

    export class AddToExcludedBusinessUnits {
        static readonly type = '[Create Promotion] Add to Excluded Business Units';
        constructor(public businessUnitIds: string[]) {
        }
    }

    export class RemoveFromExcludedBusinessUnitGroups {
        static readonly type = '[Create Promotion] Remove from Excluded Business Unit Groups';
        constructor(public groupId: string) {
        }
    }

    export class RemoveFromExcludedBusinessUnits {
        static readonly type = '[Create Promotion] Remove from Excluded Business Units';
        constructor(public businessUnitId: string) {
        }
    }

    export class GetSparkOptions {
        static readonly type = '[Create Promotion] Get Spark Options';
    }

    export class SaveSparks {
        static readonly type = '[Create Promotion] Save Sparks';
        constructor(public sparks: SparksFormModel[]) {
        }
    }

    export class ValidatePromotion {
        static readonly type = '[Create Promotion] Validate Promotion';
    }

    export class UpdateOfferTemplate {
        static readonly type = '[Create Promotion] Update offer template';
        constructor(public templateValue: string, public offerTemplate?: IOfferTemplate) {
        }
    }

    export class AddQualificationTier {
        static readonly type = '[Create Promotion] Add Qualification Tier';
        constructor(public tier?: number) { }
    }

    export class RemoveQualificationTier {
        static readonly type = '[Create Promotion] Remove Qualification Tier';
        constructor(public tier: number) { }
    }

    export class RemoveQualification {
        static readonly type = '[Create Promotion] Remove Qualification';
        constructor(public tier: number, public qualificationIndex: number) { }
    }

    export class ClearRemovedQualifications {
        static readonly type = '[Create Promotion] Clear Removed Qualifications';
    }

    export class AddRewardTier {
        static readonly type = '[Create Promotion] Add Reward Tier';
        constructor(public tier?: number) { }
    }

    export class RemoveRewardTier {
        static readonly type = '[Create Promotion] Remove Reward Tier';
        constructor(public tier: number) { }
    }

    export class RemoveReward {
        static readonly type = '[Create Promotion] Remove Reward';
        constructor(public tier: number, public rewardIndex: number) { }
    }

    export class ClearRemovedRewards {
        static readonly type = '[Create Promotion] Clear Removed Rewards';
    }

    export class RemoveNearMiss {
        static readonly type = '[Create Promotion] Remove Near Miss';
        constructor(public tier: number) { }
    }

    export class CalculateNewChanges {
        static readonly type = '[Craete Promotion] Calculate New Changes';
    }

    export class SavePromoCodeFile {
        static readonly type = '[Create Promotion] Save Promo Code File';
        constructor(public filename: string, public url: string) { }
    }

    export class DeletePromoCodeFile {
        static readonly type = '[Create Promotion] Delete Promo Code File';
        constructor(public filename: string) { }
    }
}
