import { Component } from '@angular/core';
import { FormsService } from '../../forms.service';
import { SelectComponent } from '../select/select.component';

@Component({
    selector: 'jmcc-color-select',
    templateUrl: './color-select.component.html',
    styleUrls: ['./color-select.component.scss']
})
export class ColorSelectComponent extends SelectComponent {

    defaultDataColors: string[] = [
        'red',
        'orange',
        'yellow',
        'green',
        'teal',
        'blue',
        'indigo',
        'violet',
        'purple',
        'magenta',
        'pink'
    ];

    constructor(protected formsService: FormsService) {
        super(formsService);
    }

    isDefaultDataColor(color: string) {
        return this.defaultDataColors.some(c => c === color);
    }
}
