import { AdminChangeRequest, EditUserRequest, IUserRequest } from '../interfaces/user-request.interface';
import { IUserInvitation } from '../interfaces/user-invitation.interface';

export namespace UserConfigurationActions {

    export class LoadActiveAndPendingUsers {
        static readonly type = '[Users] Load all active and pending users';
    }

    export class InviteUser {
        static readonly type = '[User API] Invite User';
        constructor(public email: string) {
        }
    }

    export class EditUser {
        static readonly type = '[User API] Edit User';
        constructor(public request: EditUserRequest) {
        }
    }

    export class DeleteUser {
        static readonly type = '[User API] Delete User';
        constructor(public userId: string) {
        }
    }

    export class UserAdminChange {
        static readonly type = '[User API] Change User Admin Status';
        constructor(public request: AdminChangeRequest) {
        }
    }

    export class AcceptPendingRequests {
        static readonly type = '[Users] Accept pending user requests';

        constructor(public requests: IUserRequest[]) { }
    }

    export class RejectPendingRequests {
        static readonly type = '[Users] Reject pending user requests';

        constructor(public requests: IUserRequest[]) { }
    }

    export class ResendPendingInvitations {
        static readonly type = '[Users] Resend pending user invitations';

        constructor(public invitations: IUserInvitation[]) { }
    }

    export class DeletePendingInvitations {
        static readonly type = '[Users] Delete pending user invitations';

        constructor(public invitations: IUserInvitation[]) { }
    }
}
