import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthResult, NbAuthService, NbPasswordAuthStrategyOptions, getDeepFromObject } from '@nebular/auth';
import { FormsService } from '../../@forms/forms.service';
import { ContextService } from '../../@core/services/context.service';
import { forkJoin } from 'rxjs';
import { NB_WINDOW } from '@nebular/theme';

@Component({
    selector: 'jmcc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    redirectDelay = 0;
    strategy = '';
    submitted = false;
    isSuccess: boolean;
    messages: string[] = [];

    loginForm: FormGroup;
    readonly emailLabel = 'Email address';
    emailErrorMessages: Map<string, string>;
    readonly passwordLabel = 'Password';
    passwordErrorMessages: Map<string, string>;
    readonly loginScreenConfig = 'jmcc.user.authentication.enableLoginScreen';

    isGoogleEnabled = false;
    readonly googleConfig = 'spring.security.oauth2.client.registration.google.client-id';
    readonly googleRedirect = '/oauth2/authorize/google';

    isOktaEnabled = false;
    readonly oktaConfig = 'spring.security.oauth2.client.registration.okta.client-id';
    readonly oktaRedirect = '/oauth2/authorize/okta';

    constructor(
        private authService: NbAuthService,
        private router: Router,
        private formsService: FormsService,
        private contextService: ContextService,
        @Inject(NB_AUTH_OPTIONS) protected options: NbPasswordAuthStrategyOptions,
        @Inject(NB_WINDOW) protected nbWindow: Window
    ) {
        this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
        this.strategy = this.getConfigValue('forms.login.strategy');
    }

    ngOnInit(): void {
        forkJoin({
            loginScreen: this.contextService.getConfigBoolean(this.loginScreenConfig),
            google: this.contextService.stringConfigExists(this.googleConfig),
            okta: this.contextService.stringConfigExists(this.oktaConfig)
        }).subscribe(configResults => {
            this.isGoogleEnabled = configResults.google;
            this.isOktaEnabled = configResults.okta;
            if (this.isGoogleEnabled && !configResults.loginScreen) {
                this.nbWindow.location.href = this.googleRedirect;
            } else if (this.isOktaEnabled && !configResults.loginScreen) {
                this.nbWindow.location.href = this.oktaRedirect;
            } else {
                const email = new FormControl('', [Validators.required, Validators.email]);
                this.emailErrorMessages = this.formsService.generateErrorMessages(new Map(), this.emailLabel);
                const password = new FormControl('', Validators.required);
                this.passwordErrorMessages = this.formsService.generateErrorMessages(new Map(), this.passwordLabel);
                this.loginForm = new FormGroup({ email, password });
            }
        });
    }

    login(): void {
        if (this.loginForm.valid) {
            this.messages = [];
            this.submitted = true;

            this.authService.authenticate(this.strategy, this.loginForm.value).subscribe((result: NbAuthResult) => {
                this.submitted = false;
                this.isSuccess = result.isSuccess();
                this.messages = result.getErrors().concat(result.getMessages());

                if (result.getResponse().error?.resultCode === 'PENDING_APPROVAL') {
                    setTimeout(() => {
                        this.router.navigate(['auth/login/pending-approval']);
                    }, this.redirectDelay);
                } else if (this.isSuccess) {
                    setTimeout(() => {
                        this.router.navigate(['/']);
                    }, this.redirectDelay);
                }
            });
        } else {
            Object.keys(this.loginForm.controls).forEach((key) => this.loginForm.controls[key].markAsDirty());
            window.scrollTo(0, 0);
        }
    }

    getStatus(control: FormControl): string {
        return this.formsService.getStatus(control);
    }

    getErrorMessage(control: FormControl, errorMessages: Map<string, string>): string {
        return this.formsService.getErrorMessage(control, errorMessages);
    }

    private getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
