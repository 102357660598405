import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { IBusinessUnitGroup } from '../interfaces/business-unit-group.interface';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { BusinessUnitService } from '../services/business-unit.service';
import { BusinessUnitActions } from './business-unit.actions';
import { ICountry } from '../interfaces/country.interface';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export class BusinessUnitStateModel {
    businessUnits: IBusinessUnit[];
    businessUnitGroups: IBusinessUnitGroup[];
    countries: ICountry[];
    searchString: string;
}

const BUSINESS_UNIT_STATE_TOKEN = new StateToken<BusinessUnitStateModel>('businessUnits');

@State<BusinessUnitStateModel>({
    name: BUSINESS_UNIT_STATE_TOKEN,
    defaults: {
        businessUnits: [],
        businessUnitGroups: [],
        countries: [],
        searchString: null
    }
})
@Injectable()
export class BusinessUnitState {

    constructor(private businessUnitsService: BusinessUnitService) {
    }

    static allBusinessUnitOptionsByField(fieldName: string) {
        return createSelector([BusinessUnitState], (state: BusinessUnitStateModel) =>
            state.businessUnits?.map(businessUnit => businessUnit?.[fieldName])
        );
    }

    static businessUnitsWithValues(businessUnitSearchForm: FormGroup) {
        return createSelector([BusinessUnitState], (state: BusinessUnitStateModel): IBusinessUnit[] =>
            state.businessUnits?.filter(businessUnit => this.doesBusinessUnitMatch(businessUnitSearchForm, businessUnit))
        );
    }

    static businessUnitById(businessUnitId: string) {
        return createSelector([BusinessUnitState], (state: BusinessUnitStateModel): IBusinessUnit =>
            state.businessUnits?.find(businessUnit => businessUnit.businessUnitId === businessUnitId));
    }

    static businessUnitGroupById(businessUnitGroupId: string) {
        return createSelector([BusinessUnitState], (state: BusinessUnitStateModel): IBusinessUnitGroup =>
            state.businessUnitGroups?.find(businessUnitGroup => businessUnitGroup.businessUnitGroupId === businessUnitGroupId));
    }

    static totalBusinessUnitsInGroups(businessUnitGroupIds: string[]) {
        return createSelector([BusinessUnitState], (state: BusinessUnitStateModel): number => {
            let count = 0;
            if (businessUnitGroupIds?.length && state.businessUnitGroups?.length) {
                businessUnitGroupIds.forEach(businessUnitGroupId => {
                    const businessUnitGroup = state.businessUnitGroups.find(bug => bug.businessUnitGroupId === businessUnitGroupId);
                    count += businessUnitGroup?.businessUnits ? businessUnitGroup.businessUnits.length : 0;
                });
            }
            return count;
        });
    }

    private static doesBusinessUnitMatch(businessUnitSearchForm: FormGroup, businessUnit: IBusinessUnit) {
        let matches = true;
        if (businessUnitSearchForm?.value) {
            if (businessUnitSearchForm.value.businessUnitId) {
                matches = matches && businessUnitSearchForm.value.businessUnitId === businessUnit.businessUnitId;
            }
            if (businessUnitSearchForm.value.businessUnitName) {
                matches = matches && businessUnitSearchForm.value.businessUnitName === businessUnit.businessUnitName;
            }
            if (businessUnitSearchForm.value.businessUnitCountry) {
                matches = matches
                    && businessUnit.addresses?.some(address => address.countryId === businessUnitSearchForm.value.businessUnitCountry);
            }
            if (businessUnitSearchForm.value.businessUnitState) {
                matches = matches
                    && businessUnit.addresses.some(address => address.stateId === businessUnitSearchForm.value.businessUnitState);
            }
        }
        return matches;
    }

    private static searchMatchesBusinessUnitGroup(businessUnitGroup: IBusinessUnitGroup, searchString: string): boolean {
        if (searchString) {
            return businessUnitGroup.businessUnitGroupId?.toLowerCase().includes(searchString)
                || businessUnitGroup.businessUnitGroupName?.toLowerCase().includes(searchString)
                || businessUnitGroup.businessUnits?.some(businessUnit => this.searchMatchesBusinessUnit(businessUnit, searchString));
        }
        return true;
    }

    private static searchMatchesBusinessUnit(businessUnit: IBusinessUnit, searchString: string): boolean {
        return businessUnit.businessUnitId?.toLowerCase().includes(searchString)
            || businessUnit.businessUnitName?.toLowerCase().includes(searchString);
    }

    @Selector()
    static countries(state: BusinessUnitStateModel): ICountry[] {
        return state.countries;
    }

    @Selector()
    static businessUnits(state: BusinessUnitStateModel): IBusinessUnit[] {
        return state.businessUnits;
    }

    @Selector()
    static businessUnitGroups(state: BusinessUnitStateModel): IBusinessUnitGroup[] {
        return state.businessUnitGroups;
    }

    @Selector()
    static allBusinessUnitGroupIds(state: BusinessUnitStateModel): string[] {
        return state.businessUnitGroups?.map(group => group.businessUnitGroupId);
    }

    @Selector()
    static filteredBusinessUnitGroups(state: BusinessUnitStateModel): IBusinessUnitGroup[] {
        return state.businessUnitGroups?.filter(group => this.searchMatchesBusinessUnitGroup(group, state.searchString));
    }

    @Selector()
    static allBusinessUnitGroupOptions(state: BusinessUnitStateModel): string[] {
        return state.businessUnitGroups?.map(group => group?.businessUnitGroupName);
    }

    @Selector()
    static searchString(state: BusinessUnitStateModel): string {
        return state.searchString;
    }

    @Action(BusinessUnitActions.SearchBusinessUnitGroups)
    searchBusinessUnitGroups(ctx: StateContext<BusinessUnitStateModel>, action: BusinessUnitActions.SearchBusinessUnitGroups): void {
        if (action.searchString) {
            ctx.patchState({
                searchString: action.searchString.toLowerCase()
            });
        }
    }

    @Action(BusinessUnitActions.ClearSearch)
    clearSearch(ctx: StateContext<BusinessUnitStateModel>): void {
        ctx.patchState({
            searchString: null
        });
    }

    @Action(BusinessUnitActions.AllBusinessUnitGroups)
    searchAllBusinessUnitGroups(ctx: StateContext<BusinessUnitStateModel>, action: BusinessUnitActions.AllBusinessUnitGroups): Observable<{ businessUnitGroups: IBusinessUnitGroup[] }> {
        if (!ctx.getState().businessUnitGroups?.length || action.forceReload) {
            return this.businessUnitsService.getBusinessUnitGroups().pipe(
                tap(result =>
                    ctx.patchState({
                        businessUnitGroups: result.businessUnitGroups,
                    })
                ));
        }
        return of({ businessUnitGroups: ctx.getState().businessUnitGroups });
    }

    @Action(BusinessUnitActions.AllBusinessUnits)
    searchAllBusinessUnits(ctx: StateContext<BusinessUnitStateModel>, action: BusinessUnitActions.AllBusinessUnits): Observable<{ businessUnits: IBusinessUnit[] }> {
        if (!ctx.getState().businessUnits?.length || action.forceReload) {
            return this.businessUnitsService.getBusinessUnits().pipe(
                tap(result =>
                    ctx.patchState({
                        businessUnits: result.businessUnits,
                    })
                ));
        }
    }

    @Action(BusinessUnitActions.AllCountries)
    loadAllCountries(ctx: StateContext<BusinessUnitStateModel>): Observable<{ countries: ICountry[] }> {
        if (ctx.getState().countries.length === 0) {
            return this.businessUnitsService.getCountries().pipe(
                tap(result =>
                    ctx.patchState({
                        countries: result.countries,
                    })
                ));
        }
    }

}
