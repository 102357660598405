<div class="nb-form-field">
    <div class="checkbox-container">
        <nb-checkbox id="{{id}}" [formControl]="control" [status]="getStatus()">
            <span class="label">{{label}}</span>
        </nb-checkbox>
        <jmcc-tooltip-icon-button *ngIf="tooltip" [icon]="'question-mark-circle-outline'" [tooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"></jmcc-tooltip-icon-button>
    </div>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
