import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { EmojiSelectedEvent, IComment } from '../../../@core/interfaces/comment.interface';

@Component({
    selector: 'jmcc-emojis',
    templateUrl: './emojis.component.html',
    styleUrls: ['./emojis.component.scss']
})
export class EmojisComponent {

    @Input()
    callback: Subject<EmojiSelectedEvent>;

    @Input()
    comment: IComment;

    emojis = ['😀', '😃', '😆', '😂', '🤣', '👍', '👎', '🫠', '🤯', '😟', '😡', '💜'];

    clicked(event: Event, emoji: string): void {
        event.stopPropagation();
        this.callback.next({ comment: this.comment, emoji: emoji });
    }

}
