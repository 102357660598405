import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { NB_AUTH_OPTIONS, NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService, NbPasswordAuthStrategyOptions, getDeepFromObject } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
        @Inject(NB_AUTH_OPTIONS) protected options: NbPasswordAuthStrategyOptions,
        @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter: (req: HttpRequest<any>) => boolean
    ) { }

    protected get authService(): NbAuthService {
        return this.injector.get(NbAuthService);
    }

    protected get toastrService(): NbToastrService {
        return this.injector.get(NbToastrService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized && (!this.filter(req) || req.url.includes('refresh-token'))) {
                this.authService.logout(this.getConfigValue('forms.login.strategy')).pipe(
                    tap(() => this.toastrService.warning('For your security, you have been logged out.', 'Auth Session Token Expired', { duration: 60000, preventDuplicates: true }))
                ).subscribe();
            }
            return throwError(error);
        }));
    }

    private getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
