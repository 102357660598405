import { FormControl, ValidatorFn } from '@angular/forms';

export class PasswordValidator {

    static mustMatch(passwordControl: FormControl): ValidatorFn {
        return (confirmPassword: FormControl) => {
            if (passwordControl.value !== confirmPassword.value) {
                return { mustMatch: { valid: false } };
            }
            return null;
        };
    }
}
