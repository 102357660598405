import { GetCommentsRequest, IComment } from '../interfaces/comment.interface';

export namespace CommentsActions {
    export class GetComments {
        static readonly type = '[Comments] Get Comments';
        constructor(public request: GetCommentsRequest, public forceReload?: boolean) { }
    }
    export class SaveComment {
        static readonly type = '[Comments] Save Comment';
        constructor(public comment: IComment, public saveType: string) { }
    }
    export class DeleteComment {
        static readonly type = '[Comments] Delete Comment';
        constructor(public comment: IComment) { }
    }
}
