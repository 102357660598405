import { Pipe, PipeTransform } from '@angular/core';
import { LineItemTypeCode } from '../../@core/interfaces/transaction.interface';
import { TransUtil } from '../../@core/utils/trans.util';

@Pipe({ name: 'orderTypeIcon' })
export class OrderTypeIconPipe implements PipeTransform {
    transform(type: LineItemTypeCode): string {
        return TransUtil.getOrderTypeIcon(type);
    }
}
