export namespace TeamConfigurationActions {

    export class GetTeamsManagedByUser {
        static readonly type = '[Teams] Get Teams Managed By User';
    }

    export class SearchTeams {
        static readonly type = '[Teams] Search Teams';
        constructor(public searchString: string) {
        }
    }

    export class ClearSearch {
        static readonly type = '[Teams] Clear Search';
    }
}
