<div class="login-container" *ngIf="loginForm">
    <div>
        <h1 id="title" class="title">Login</h1>
        <p class="sub-title">Hello! Log in with your email.</p>
    </div>
    <nb-alert *ngIf="messages?.length && !submitted" [outline]="isSuccess ? 'success' : 'danger'" role="alert">
        <p class="alert-title" id="loginAlertTitle"><strong>{{isSuccess ? 'Hooray!' : 'Oh snap!'}}</strong></p>
        <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
    </nb-alert>
    <form (ngSubmit)="login()" [formGroup]="loginForm" aria-labelledby="title">
        <nb-form-field>
            <label for="loginEmail" class="label required">
                {{emailLabel}}
            </label>
            <input nbInput fullWidth id="loginEmail" shape="semi-round" fieldSize="large"
                [formControl]="loginForm.controls.email" placeholder="{{emailLabel}}"
                [status]="getStatus(loginForm.controls.email)" type="email" autocomplete="username">
            <div class="error-container">
                <span *ngIf="loginForm.controls.email.dirty && !loginForm.controls.email.valid"
                    id="loginEmailErrorMessage" class="status-danger">{{getErrorMessage(loginForm.controls.email,
                    emailErrorMessages)}}</span>
            </div>
        </nb-form-field>
        <nb-form-field>
            <div class="label-container">
                <label for="loginPassword" class="label required">
                    Password
                </label>
                <a class="caption-2" routerLink="../request-password">Forgot Password?</a>
            </div>
            <input nbInput fullWidth id="loginPassword" shape="semi-round" fieldSize="large"
                [formControl]="loginForm.controls.password" placeholder="Password"
                [status]="getStatus(loginForm.controls.password)" type="password" autocomplete="current-password">
            <div class="error-container">
                <span *ngIf="loginForm.controls.password.dirty && !loginForm.controls.password.valid"
                    id="loginPasswordErrorMessage" class="status-danger">{{getErrorMessage(loginForm.controls.password,
                    passwordErrorMessages)}}</span>
            </div>
        </nb-form-field>
        <button nbButton fullWidth type="submit" status="primary" size="large">Login</button>
    </form>
    <a href="{{googleRedirect}}" id="signInWithGoogle" class="link-button" *ngIf="isGoogleEnabled">
        <button nbButton fullWidth outline size="large" class="sso-button">
            <img src="/assets/images/google-icon.svg" alt="google" class="sso-icon">Sign in with Google
        </button>
    </a>
    <a href="{{oktaRedirect}}" id="signInWithOkta" class="link-button" *ngIf="isOktaEnabled">
        <button nbButton fullWidth outline size="large" class="sso-button">
            <img src="/assets/images/okta-icon.svg" alt="okta" class="sso-icon">Sign in with Okta
        </button>
    </a>
    <section class="another-action" aria-label="Register">
        Don't have an account? <a class="text-link" routerLink="../register">Register</a>
    </section>
</div>
