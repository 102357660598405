export namespace NotificationsActions {

    export class LoadNotifications {
        static readonly type = '[Notifications] Initiate process to load notifications on a schedule';
    }

    export class GetNotifications {
        static readonly type = '[Notifications] Get all notifications for the active user';
    }

    export class MarkAllAsViewed {
        static readonly type = '[Notifications] Mark all current notifications as viewed';
    }
}
