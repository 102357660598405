import { TenderTypeCode } from '../interfaces/transaction.interface';

export class TenderUtil {
    private static readonly typeCodeIconMap = {
        [TenderTypeCode.BANK_CHECK]: 'money',
        [TenderTypeCode.CASH]: 'usd-dollar-bill',
        [TenderTypeCode.CREDIT_CARD]: 'credit-card',
        [TenderTypeCode.CHECK]: 'money',
        [TenderTypeCode.DEBIT_CARD]: 'credit-card',
        [TenderTypeCode.GIFT_CARD]: 'gift-card',
        [TenderTypeCode.MANUFACTURER_COUPON]: 'tag-filled',
        [TenderTypeCode.STORE_COUPON]: 'tag-filled',
        [TenderTypeCode.STORE_CREDIT]: 'gift-card',
        [TenderTypeCode.E_WALLET]: 'phone-link-ring',
        [TenderTypeCode.QR_CODE_WALLET]: 'phone-link-ring',
        [TenderTypeCode.MALL_CERTIFICATE]: 'certificate',
        [TenderTypeCode.VOUCHER]: 'finance-chip',
    };

    static getTypeCodeIcon(code: TenderTypeCode): string {
        return this.typeCodeIconMap[code] || 'help';
    }
}
