export namespace BusinessUnitActions {

    export class AllBusinessUnitGroups {
        static readonly type = '[Business Unit] All Business Unit Groups';
        constructor(public forceReload?: boolean) { }
    }

    export class AllBusinessUnits {
        static readonly type = '[Business Unit] All Business Units';
        constructor(public forceReload?: boolean) { }
    }

    export class AllCountries {
        static readonly type = '[Business Unit] All Countries';
    }

    export class SearchBusinessUnitGroups {
        static readonly type = '[Business Unit] Search Business Unit Groups';
        constructor(public searchString: string) { }
    }

    export class ClearSearch {
        static readonly type = '[Business Unit] Clear Search';
    }
}
