import { IQualificationAttributeConfig } from '../interfaces/promotion/promotion-qualification-attribute.interface';

export namespace PromotionQualificationAttributeConfigurationActions {
    const typePrefix = '[Promotion Qualification Attribute Configuration] ';
    export class GetAllQualificationAttributeConfigs {
        static readonly type = typePrefix + 'Get All Qualification Attribute Configs';

        constructor(public forceReload: boolean) { }
    }

    export class SearchQualificationAttributeConfigs {
        static readonly type = typePrefix + 'Search Qualification Attribute Configs';

        constructor(public searchString: string) { }
    }

    export class ClearSearch {
        static readonly type = typePrefix + 'Clear Search';
    }

    export class SaveQualificationAttributeConfig {
        static readonly type = typePrefix + 'Save Qualification Attribute Config';

        constructor(public attribute: IQualificationAttributeConfig) { }
    }

    export class DeleteQualificationAttributeConfig {
        static readonly type = typePrefix + 'Delete Qualification Attribute Config';

        constructor(public attribute: IQualificationAttributeConfig) { }
    }

    export class LoadQualificationAttributeConfigIntoForm {
        static readonly type = typePrefix + 'Load Qualification Attribute Config Into Form';

        constructor(public attributeId: string) { }
    }

    export class ClearForm {
        static readonly type = typePrefix + 'Clear Form';
    }
}
