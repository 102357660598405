import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { MoneyUtil } from '../../@core/utils/money.util';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: any): string {
        if (!value) {
            return '';
        }

        const money = MoneyUtil.parse(value.toString());
        const currencyFormat = Intl.NumberFormat(this.localeId, {
            style: 'currency',
            currency: money.currency
        });

        return currencyFormat.format(money.amount);
    }
}
