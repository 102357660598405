import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertForTimezone' })
export class ConvertForTimezonePipe implements PipeTransform {

    transform(date: Date | string): Date {
        if (date && date.toString() !== '') {
            date = new Date(date);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            return date;
        } else {
            return null;
        }
    }
}
