import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetTeamsResponse, ITeam } from '../interfaces/team.interface';
import { UserService } from '../services/users.service';
import { TeamConfigurationActions } from './team-configuration.actions';
import { AuthorizedState } from './authorized.state';

export class TeamConfigurationStateModel {
    teams: ITeam[];
    searchString: string;
}

const TEAM_CONFIG_STATE_TOKEN = new StateToken<TeamConfigurationStateModel>('teamConfiguration');

@State<TeamConfigurationStateModel>({
    name: TEAM_CONFIG_STATE_TOKEN,
    defaults: {
        teams: [],
        searchString: null,
    },
})
@Injectable()
export class TeamConfigurationState {

    constructor(private userService: UserService, private store: Store) {
    }

    static teamById(teamId: string) {
        return createSelector([TeamConfigurationState], (state: TeamConfigurationStateModel): ITeam =>
            state.teams?.find((team:  ITeam) => team.teamId === teamId));
    }

    private static filterTeamBySearchString(state: TeamConfigurationStateModel, team: ITeam): boolean {
        if (state.searchString) {
            return team.teamId.toLowerCase().includes(state.searchString)
                || team.teamName.toLowerCase().includes(state.searchString)
                || team.description.toLowerCase().includes(state.searchString);
        }
        return true;
    }

    @Selector()
    static configurableTeams(state: TeamConfigurationStateModel): ITeam[] {
        return state.teams?.filter(team => this.filterTeamBySearchString(state, team));
    }

    @Selector()
    static searchString(state: TeamConfigurationStateModel): string {
        return state.searchString;
    }

    @Action(TeamConfigurationActions.GetTeamsManagedByUser)
    getTeamsManagedByUser(ctx: StateContext<TeamConfigurationStateModel>): Observable<GetTeamsResponse> {
        const user = this.store.selectSnapshot(AuthorizedState.authorizedUser);
        return this.userService.getTeamsManagedByUser(user.userId).pipe(
            tap((result: GetTeamsResponse) => ctx.patchState({ teams: result.teams }))
        );
    }

    @Action(TeamConfigurationActions.SearchTeams)
    searchTeams(ctx: StateContext<TeamConfigurationStateModel>, action: TeamConfigurationActions.SearchTeams) {
        ctx.patchState({
            searchString: action.searchString?.toLowerCase()
        });
    }

    @Action(TeamConfigurationActions.ClearSearch)
    clearSearch(ctx: StateContext<TeamConfigurationStateModel>) {
        ctx.patchState({
            searchString: null
        });
    }
}
