import { INotificationSetting } from '../interfaces/notification.interface';
import { UpdatePasswordRequest } from '../interfaces/user-request.interface';

export namespace AuthorizedActions {
    export class LoggedIn {
        static readonly type = '[Authorized] Logged In';
    }

    export class LoggedOut {
        static readonly type = '[Authorized] Logged Out';
    }

    export class RefreshAuthorizedItems {
        static readonly type = '[Authorized] Refresh Authorized Items';
    }

    export class EditAuthorizedUser {
        static readonly type = '[Authorized] Edit Authorized User';
        constructor(public name: string, public email: string) {
        }
    }

    export class UpdateAuthorizedUserNotificationSettings {
        static readonly type = '[Authorized] Update Authorized User Notification Settings';
        constructor(public notificationSettings: INotificationSetting[]) {
        }
    }

    export class UpdateAuthorizedPassword {
        static readonly type = '[Authorized] Update Authorized User\'s Password';
        constructor(public request: UpdatePasswordRequest) {
        }
    }
}
