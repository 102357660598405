import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { GetObjectHistoryResponse, IObjectHistoryEvent } from '../interfaces/object-history-event.interface';
import { Injectable } from '@angular/core';
import { ContextService } from '../services/context.service';
import { ObjectHistoryActions } from './object-history.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITimelineEvent } from '../../@theme/components/timeline/timeline-event.interface';

export class ObjectHistoryStateModel {
    objectHistoryEvents: IObjectHistoryEvent[];
}

const OBJECT_HISTORY_STATE_TOKEN = new StateToken<ObjectHistoryStateModel>('objectHistory');

@State<ObjectHistoryStateModel>({
    name: OBJECT_HISTORY_STATE_TOKEN,
    defaults: {
        objectHistoryEvents: []
    }
})
@Injectable()
export class ObjectHistoryState {

    constructor(private contextService: ContextService) { }

    @Selector()
    static objectHistoryAsTimelineEvents(state: ObjectHistoryStateModel): ITimelineEvent[] {
        return state.objectHistoryEvents.map((event: IObjectHistoryEvent) => ({ date: event.date, detail: event.eventDetail }));
    }

    @Action(ObjectHistoryActions.GetObjectHistory)
    getObjectHistory(ctx: StateContext<ObjectHistoryStateModel>, action: ObjectHistoryActions.GetObjectHistory): Observable<GetObjectHistoryResponse> {
        return this.contextService.getObjectHistory(action.objectId, action.objectType).pipe(
            tap((result: GetObjectHistoryResponse) => {
                if (result.objectHistoryEvents) {
                    ctx.patchState({ objectHistoryEvents: result.objectHistoryEvents });
                }
            })
        );
    }
}
