import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ITeam } from '../interfaces/team.interface';
import { IUser } from '../interfaces/user.interface';
import { PromotionWorkflowConfigurationActions } from './promotion-workflow-configuration.actions';
import { UserService } from '../services/users.service';
import { AppAccessType, GetUsersAndTeamsWithAppAccessResponse } from '../interfaces/app-access.interface';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectOption } from '../../@forms/forms.interface';
import { APP_PROMOTE_ID } from '../interfaces/app-id.constants';

export class PromotionWorkflowConfigurationStateModel {
    reviewAccessTeams: ITeam[];
    reviewAccessUsers: IUser[];
}

const PROMOTION_WORKFLOW_CONFIG_STATE_TOKEN = new StateToken<PromotionWorkflowConfigurationStateModel>('promotionWorkflowConfig');

@State<PromotionWorkflowConfigurationStateModel>({
    name: PROMOTION_WORKFLOW_CONFIG_STATE_TOKEN,
    defaults: {
        reviewAccessTeams: [],
        reviewAccessUsers: [],
    }
})
@Injectable()
export class PromotionWorkflowConfigurationState {

    constructor(private userService: UserService) {
    }

    @Selector()
    static reviewAccessUsersAsSelectOptions(state: PromotionWorkflowConfigurationStateModel): SelectOption[] {
        return state.reviewAccessUsers?.map((user) => ({ displayValue: user.fullName, key: user.userId })) || [];
    }

    @Selector()
    static reviewAccessTeamsAsSelectOptions(state: PromotionWorkflowConfigurationStateModel): SelectOption[] {
        return state.reviewAccessTeams?.map((team) => ({ displayValue: team.teamName, key: team.teamId })) || [];
    }

    @Action(PromotionWorkflowConfigurationActions.LoadUsersAndTeamsWithReviewAccess)
    loadReviewUsersAndTeams(ctx: StateContext<PromotionWorkflowConfigurationStateModel>): Observable<GetUsersAndTeamsWithAppAccessResponse> {
        return this.userService.getUsersAndTeamsWithAppAccess(APP_PROMOTE_ID, AppAccessType.REVIEW)?.pipe(
            tap(result => {
                ctx.patchState({
                    reviewAccessUsers: result?.users || [],
                    reviewAccessTeams: result?.teams || [],
                });
            })
        );
    }

}
