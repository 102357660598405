<nb-form-field>
    <div class="file-upload">
        <div class="label-and-instruction">
            <label for="{{id}}" [ngClass]="{required}" class="label">
                {{ label }}
            </label>
            <span *ngIf="instructions" class="caption">
                {{ instructions }}
            </span>
        </div>
        <input id="{{'input' + id}}" nbInput class="file-input" type="file" fullWidth (input)="onUpload($event)"
               [accept]="acceptedFileType"/>
        <button status="primary" nbButton id="{{'uploadButton' + id}}" (click)="onUploadClick()">
            <span>{{ label }}</span>
            <nb-icon icon="plus-outline"></nb-icon>
        </button>
        <div class="tag-section">
            <div *ngFor="let file of uploadedFiles">
                <nb-spinner *ngIf="file.uploading" class="upload-spinner"
                            [message]="'Uploading ' + file.filename + '...'" [size]="'tiny'"></nb-spinner>
            </div>
            <div *ngFor="let file of fileControl.value">
                <nb-tag (remove)="onFileRemove(file.filename)" class="file-tag" [size]="'tiny'" removable
                        appearance="outline" [text]="file.filename"></nb-tag>
            </div>
        </div>
    </div>
    <div class="error-container">
        <span *ngIf="fileControl.dirty && !fileControl.valid" id="{{id}}ErrorMessage"
              class="status-danger">{{ getErrorMessage() }}</span>
    </div>
</nb-form-field>
