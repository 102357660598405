import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { GetItemGroupsResponse, GetStagedItemGroupsResponse, IItemGroup, IStagedItemGroup, ItemOrGroup, StagedItemGroupActionResponse, StagedItemGroupFormModel, StagedItemGroupStatusTypeCode } from '../interfaces/item-group.interface';
import { ItemService } from '../services/item.service';
import { ItemActions } from './item.actions';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { GetItemResponse, GetItemsResponse, IItem } from '../interfaces/item.interface';
import { NgxsForm } from '../interfaces/ngxs-form.interface';

export type ItemOrGroupOrArray = ItemOrGroup | ItemOrGroup[];

export class ItemStateModel {
    itemGroups: IItemGroup[];
    stagedItemGroups: IStagedItemGroup[];
    stagedItemGroupForm: NgxsForm<StagedItemGroupFormModel>;
    selectedItemOrItems: ItemOrGroupOrArray;
    searchString: string;
}

const ITEM_STATE_TOKEN = new StateToken<ItemStateModel>('items');

@State<ItemStateModel>({
    name: ITEM_STATE_TOKEN,
    defaults: {
        itemGroups: [],
        stagedItemGroups: [],
        stagedItemGroupForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        selectedItemOrItems: null,
        searchString: null
    }
})
@Injectable()
export class ItemState {

    constructor(private itemService: ItemService, private store: Store) {
    }

    static groupById(groupId: string) {
        return createSelector([ItemState], (state: ItemStateModel): IItemGroup =>
            state.itemGroups?.find(i => i.id.toLowerCase() === groupId.toLowerCase()));
    }

    static stagedItemGroupById(groupId: string) {
        return createSelector([ItemState], (state: ItemStateModel): IStagedItemGroup =>
            state.stagedItemGroups?.find(stagedItemGroup => stagedItemGroup.stagedItemGroupId === groupId));
    }

    static activeStagedItemGroupByName(groupName: string) {
        return createSelector([ItemState], (state: ItemStateModel): IStagedItemGroup =>
            state.stagedItemGroups?.find(stagedItemGroup => stagedItemGroup.stagedItemGroupName === groupName && stagedItemGroup.stagedItemGroupStatus === StagedItemGroupStatusTypeCode.ACTIVE));
    }

    static groups(includeStaged: boolean) {
        return createSelector([ItemState], (state: ItemStateModel): IItemGroup[] =>
            includeStaged ? state.itemGroups : state.itemGroups.filter(group => !state.stagedItemGroups.some(stagedGroup => stagedGroup.stagedItemGroupId === group.id))
        );
    }

    private static shouldShowItemGroup(itemGroup: IStagedItemGroup, searchString: string): boolean {
        if (searchString) {
            return itemGroup.stagedItemGroupId?.toLowerCase().includes(searchString) ||
                itemGroup.stagedItemGroupName?.toLowerCase().includes(searchString) ||
                itemGroup.description?.toLowerCase().includes(searchString);
        }
        return true;
    }

    @Selector()
    static activeFilteredStagedGroups(state: ItemStateModel): IStagedItemGroup[] {
        if (!state.stagedItemGroups) {
            return [];
        }
        return state.stagedItemGroups.filter(stagedItemGroup =>
            stagedItemGroup.stagedItemGroupStatus === StagedItemGroupStatusTypeCode.ACTIVE &&
            this.shouldShowItemGroup(stagedItemGroup, state.searchString)
        );
    }


    @Selector()
    static foundItem(state: ItemStateModel): IItem {
        return Array.isArray(state.selectedItemOrItems) ? state.selectedItemOrItems[0] : state.selectedItemOrItems;
    }

    @Selector()
    static foundItems(state: ItemStateModel): ItemOrGroup[] {
        return Array.isArray(state.selectedItemOrItems) ? state.selectedItemOrItems : [state.selectedItemOrItems];
    }

    @Selector()
    static isItemFound(state: ItemStateModel): boolean {
        return Array.isArray(state.selectedItemOrItems) ? !!state.selectedItemOrItems.length : !!state.selectedItemOrItems;
    }

    @Selector()
    static searchString(state: ItemStateModel): string {
        return state.searchString;
    }


    @Action(ItemActions.AllItemGroups)
    getAllItemGroups(ctx: StateContext<ItemStateModel>, action: ItemActions.AllItemGroups): Observable<GetItemGroupsResponse> {
        if (!ctx.getState().itemGroups?.length || action.forceReload) {
            return this.itemService.getItemGroups().pipe(
                tap((result: GetItemGroupsResponse) => {
                    ctx.patchState({
                        itemGroups: result.groups.map(group => ({ id: group.itemGroupId, name: group.description }))
                    });
                })
            );
        }
        return of({} as GetItemGroupsResponse);
    }

    @Action(ItemActions.AllItemGroups)
    getAllStagedItemGroups(ctx: StateContext<ItemStateModel>, action: ItemActions.AllItemGroups): Observable<GetStagedItemGroupsResponse> {
        if (!ctx.getState().stagedItemGroups?.length || action.forceReload) {
            return this.itemService.getStagedItemGroups().pipe(
                tap((result: GetStagedItemGroupsResponse) => {
                    ctx.patchState({
                        stagedItemGroups: result.stagedItemGroups
                    });
                })
            );
        }
        return of({ stagedItemGroups: ctx.getState().stagedItemGroups });
    }

    @Action(ItemActions.FindItem)
    searchItem(ctx: StateContext<ItemStateModel>, action: ItemActions.FindItem): Observable<GetItemResponse> {
        return this.itemService.getItemById(action.itemId).pipe(
            tap((result: GetItemResponse) => {
                ctx.patchState({
                    selectedItemOrItems: result.item
                });
            })
        );
    }

    @Action(ItemActions.FindItemsByImport)
    searchItemsByImport(ctx: StateContext<ItemStateModel>, action: ItemActions.FindItemsByImport): Observable<GetItemsResponse> {
        const selectedItemGroups: IItemGroup[] = [];
        return this.itemService.extractItemOrGroupIdsFromFile(action.uploadedFile, action.separatorExp).pipe(
            switchMap((itemOrGroupIds: string[]) => {
                const itemGroups = ctx.getState().itemGroups;
                const itemIdsToSearch: string[] = [];
                for (const itemOrGroupId of itemOrGroupIds) {
                    const possibleItemGroup = itemGroups.find(group => group.id === itemOrGroupId);
                    if (possibleItemGroup) {
                        selectedItemGroups.push(possibleItemGroup);
                    } else {
                        itemIdsToSearch.push(itemOrGroupId);
                    }
                }
                return this.itemService.searchItemsByIds(itemIdsToSearch);
            }),
            tap((result: GetItemsResponse) => {
                ctx.patchState({
                    selectedItemOrItems: result.items.concat(selectedItemGroups)
                });
            })
        );
    }

    @Action(ItemActions.SearchStagedItemGroups)
    searchStagedItemGroups(ctx: StateContext<ItemStateModel>, action: ItemActions.SearchStagedItemGroups): void {
        if (action.searchString) {
            ctx.patchState({
                searchString: action.searchString.toLowerCase()
            });
        }
    }

    @Action(ItemActions.ClearSearch)
    clearSearch(ctx: StateContext<ItemStateModel>): void {
        ctx.patchState({
            searchString: null
        });
    }

    @Action(ItemActions.ClearForm)
    clearForm(ctx: StateContext<ItemStateModel>): void {
        ctx.patchState({
            stagedItemGroupForm: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {}
            }
        });
    }

    @Action(ItemActions.LoadStagedItemGroup)
    loadStagedItemGroup(ctx: StateContext<ItemStateModel>, action: ItemActions.LoadStagedItemGroup) {
        return this.store.dispatch(new ItemActions.AllItemGroups(false)).pipe(
            tap(() => {
                this.patchStagedItemGroupFormModel(ctx, action.stagedItemGroupName);
            })
        );
    }

    @Action(ItemActions.SaveStagedItemGroup)
    saveStagedItemGroup(ctx: StateContext<ItemStateModel>, action: ItemActions.SaveStagedItemGroup): Observable<any> {
        if (action.stagedItemGroup) {
            return this.itemService.saveStagedItemGroup(action.stagedItemGroup, action.create).pipe(
                mergeMap(result => {
                    if (result.success) {
                        return this.getAllStagedItemGroups(ctx, new ItemActions.AllItemGroups(true));
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(ItemActions.DeleteStagedItemGroup)
    deleteStagedItemGroup(ctx: StateContext<ItemStateModel>, action: ItemActions.DeleteStagedItemGroup): Observable<StagedItemGroupActionResponse> {
        if (action.stagedItemGroup?.stagedItemGroupId) {
            return this.itemService.deleteStagedItemGroup(action.stagedItemGroup.stagedItemGroupId).pipe(
                tap((result: StagedItemGroupActionResponse) => {
                    if (result.success) {
                        this.getAllStagedItemGroups(ctx, new ItemActions.AllItemGroups(true)).subscribe();
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    private patchStagedItemGroupFormModel(ctx: StateContext<ItemStateModel>, stagedItemGroupName: string): void {
        const stagedItemGroup = this.store.selectSnapshot(ItemState.activeStagedItemGroupByName(stagedItemGroupName));
        if (stagedItemGroup) {
            ctx.patchState({
                stagedItemGroupForm: {
                    model: {
                        groupName: stagedItemGroup.stagedItemGroupName,
                        groupDescription: stagedItemGroup.description,
                        included: (stagedItemGroup.includedItemIds || []).map(itemId => ({ id: itemId, groups: undefined } as IItem))
                            .concat((stagedItemGroup.includedItemGroupIds || []).map(groupId => this.store.selectSnapshot(ItemState.groupById(groupId)))),
                        excluded: (stagedItemGroup.excludedItemIds || []).map(itemId => ({ id: itemId, groups: undefined } as IItem))
                            .concat((stagedItemGroup.excludedItemGroupIds || []).map(groupId => this.store.selectSnapshot(ItemState.groupById(groupId))))
                    }
                }
            });
        }
    }
}
