import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class FormsService {

    getStatus(control: FormControl): string {
        return control?.dirty && !control.valid ? 'danger' : 'basic';
    }

    getErrorMessage(controlOrGroup: FormControl | FormGroup, errorMessages: Map<string, string>): string {
        if (controlOrGroup?.errors) {
            for (const error of Object.entries(controlOrGroup.errors)) {
                if (error[1] && errorMessages.has(error[0])) {
                    return errorMessages.get(error[0]);
                }
            }
            return errorMessages.get('generalError');
        }
        return '';
    }

    generateErrorMessages(errorMessages: Map<string, string>, label: string): Map<string, string> {
        if (!errorMessages.has('required') && label) {
            errorMessages.set('required', `${label} is required`);
        }
        if (!errorMessages.has('generalError') && label) {
            errorMessages.set('generalError', `${label} is invalid`);
        }
        return errorMessages;
    }

    setDisabledState(control: FormControl, isDisabled: boolean): void {
        if (isDisabled) {
            control.disable();
        } else {
            control.enable();
        }
    }
}
