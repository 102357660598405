import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDashboard } from '../interfaces/dashboard.interface';
import { IDashboardWidgetDefinition } from '../interfaces/dashboard-widget-definition.interface';

@Injectable()
export class DashboardService {

    constructor(private http: HttpClient) { }

    getDashboard(dashboardId: string): Observable<{ dashboard: IDashboard }> {
        return this.http.get<{ dashboard: IDashboard }>('app/dashboard/get/' + dashboardId);
    }

    getAvailableWidgets(): Observable<{ availableWidgets: IDashboardWidgetDefinition[] }> {
        return this.http.get<{ availableWidgets: IDashboardWidgetDefinition[] }>('app/dashboard/get/availableWidgets');
    }

    saveDashboard(dashboard: IDashboard): Observable<{ dashboard: IDashboard }> {
        return this.http.put<{ dashboard: IDashboard }>('app/dashboard/save', { dashboard });
    }

}
