import { Pipe, PipeTransform } from '@angular/core';
import { TransTypeCode } from '../../@core/interfaces/transaction.interface';
import { TransUtil } from '../../@core/utils/trans.util';

@Pipe({ name: 'transTypeCodeIcon' })
export class TransTypeCodeIconPipe implements PipeTransform {
    transform(transTypeCode: TransTypeCode): string {
        return TransUtil.getTypeCodeIcon(transTypeCode);
    }
}
