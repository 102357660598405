<h1 id="title" class="title">Register</h1>
<nb-alert *ngIf="messages?.length && !submitted" [outline]="isSuccess ? 'success' : 'danger'" role="alert">
    <p class="alert-title" id="registerAlertTitle"><strong>{{isSuccess ? 'Hooray!' : 'Oh snap!'}}</strong></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>
<form *ngIf="registerForm" (ngSubmit)="register()" [formGroup]="registerForm" aria-labelledby="title">
    <jmcc-input [id]="'registerFullName'" [placeholder]="'Full name'" [label]="'Full name'" [required]="true"
        [control]="registerForm.controls.fullName"></jmcc-input>
    <jmcc-input [id]="'registerEmail'" [placeholder]="'Email address'" [label]="'Email address'" [required]="true"
        [control]="registerForm.controls.email"></jmcc-input>
    <jmcc-input [id]="'registerPassword'" [type]="'password'" [placeholder]="'Password'" [label]="'Password'"
        [required]="true" [control]="registerForm.controls.password" [errorMessages]="passwordErrorMessages"
        [tooltip]="passwordRequirementMessage"></jmcc-input>
    <jmcc-input [id]="'registerConfirmPassword'" [type]="'password'" [placeholder]="'Confirm Password'"
        [label]="'Repeat Password'" [required]="true" [control]="registerForm.controls.confirmPassword"
        [errorMessages]="passwordErrorMessages"></jmcc-input>
    <button nbButton fullWidth type="submit" status="primary" size="large">Register</button>
</form>
<section class="another-action" aria-label="Sign in">
    Already have an account? <a class="text-link" routerLink="../login">Log in</a>
</section>
