import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

export namespace LocalizedDateFormat {
    export const Date: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    };

    export const DateTime: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
}

@Pipe({ name: 'localizedDate' })
export class LocalizedDatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: any, options?: Intl.DateTimeFormatOptions): string {
        if (!value) {
            return '';
        }

        const dateTimeFormat = Intl.DateTimeFormat(this.localeId, options || LocalizedDateFormat.Date);
        return dateTimeFormat.format(new Date(value));
    }
}
