import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule, inject } from '@angular/core';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module')
            .then(m => m.AuthModule)
    },
    {
        path: '',
        canActivate: [() => inject(AuthGuard).canActivate()],
        loadChildren: () => import('./pages/pages.module')
            .then(m => m.PagesModule)
    }
];

const config: ExtraOptions = {
    useHash: true,
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
