import { IDeployment, IDeploymentTemplate, IInstallationGroup } from "../interfaces/deployments/deployment.interface";

export namespace DeploymentsActions {

    export class LoadVersionedBusinessUnits {
        static readonly type = '[Active Deployments] Load Versioned Units';

        constructor(public packageName?: string) {
        }
    }

    export class LoadDeployments {
        static readonly type = '[Active Deployments] Load Deployments';

        constructor(public packageName?: string) {
        }
    }

    export class CancelDeployment {
        static readonly type = '[Active Deployments] Cancel Deployment';

        constructor(public version: string) {
        }
    }

    export class AdvanceDeploymentManually {
        static readonly type = '[Active Deployments] Advance Deployment Manually';

        constructor(public version: string) {
        }
    }


    export class LoadDeploymentStatistics {
        static readonly type = '[Active Deployments] Load Deployment Statistics';

        constructor(public packageName?: string) {
        }
    }

    export class LoadInstallationGroups {
        static readonly type = '[Active Deployments] Load Installation Groups';

    }

    export class LoadTemplates {
        static readonly type = '[Active Deployments] Load Deployment Templates';

        constructor() {
        }
    }


    export class SaveInstallationGroup {
        static readonly type = '[Active Deployments] Save Installation Group';

        constructor(public installationGroup: IInstallationGroup) {
        }
    }

    export class SaveTemplate {
        static readonly type = '[Active Deployments] Save Deployment Template';

        constructor(public deploymentTemplate: IDeploymentTemplate) {
        }
    }

    export class SaveDeployment {
        static readonly type = '[Active Deployments] Save Deployment';

        constructor(public deployment: IDeployment) {
        }
    }

    export class ChangePackage {
        static readonly type = '[Active Deployments] Change Package';

        constructor(public packageName?: string) {
        }
    }

    export class LoadPackages {
        static readonly type = '[Active Deployments] Load Packages';
    }

    export class GetInstallationsForGroup {
        static readonly type = '[Active Deployments] Get Installations for Group';

        constructor(public groupId: string, public packageName?: string) {
        }
    }

    export class LoadAvailableVersions {
        static readonly type = '[Active Deployments] Load Available Versions';

        constructor(public packageName?: string) {
        }
    }
}
