import { Component } from '@angular/core';

@Component({
    selector: 'jmcc-stepper-nav-buttons',
    styleUrls: ['./stepper-nav-buttons.component.scss'],
    templateUrl: './stepper-nav-buttons.component.html',
})
export class StepperNavButtonsComponent {

}
