import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import produce from 'immer';
import { IBusinessUnitGroup } from '../interfaces/business-unit-group.interface';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import {
    BusinessUnitGroupIdsModel,
    BusinessUnitGroupsModel,
    BusinessUnitIdsModel,
    BusinessUnitsModel,
    DetailsFormModel,
    SparksFormModel
} from '../interfaces/promotion/create-promotion.interface';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import {
    ISaveStagedPromotionResponse,
    IStagedPromotion,
    SparkTypeCode
} from '../interfaces/promotion/staged-promotion.interface';
import { PromotionService } from '../services/promotion.service';
import { CreatePromotionActions } from './create-promotion.actions';
import { BusinessUnitState, BusinessUnitStateModel } from './business-unit.state';
import {
    IPromotionValidation,
    ValidatePromotionResponse
} from '../interfaces/promotion/promotion-validation.interface';
import { switchMap, tap } from 'rxjs/operators';
import { ILabel } from '../interfaces/label.interface';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import {
    INearMissForm,
    IOfferForms,
    IQualificationForm,
    IRewardCategoryForm,
    IRewardForm,
    OfferDetailsFormModel
} from '../interfaces/promotion/promotion-offers.interface';
import { LabelsState, LabelsStateModel } from './labels.state';
import { ISparkOption } from '../interfaces/promotion/spark-option.interface';
import { StagedPromotionBuilderService } from '../services/staged-promotion-builder.service';
import { diff } from 'deep-object-diff';
import { IUploadedFile } from '../interfaces/promotion/uploaded-file.interface';
import { cloneDeep } from 'lodash';
import { SelectOption } from '../../@forms/forms.interface';
import {
    AttributeLevel,
    AttributeTypeCode,
    IQualificationAttributeConfig,
    IQualificationAttributeValueOptionConfig,
    ItemAttributeFieldConfig,
    TransactionAttributeSelectOptions
} from '../interfaces/promotion/promotion-qualification-attribute.interface';
import {
    PromotionQualificationAttributeConfigurationActions
} from './promotion-qualification-attribute-configuration.action';
import {
    PromotionQualificationAttributeConfigurationState
} from './promotion-qualification-attribute-configuration.state';

export class CreatePromotionStateModel {
    detailsForm: NgxsForm<DetailsFormModel>;
    stagedPromotion: IStagedPromotion;
    businessUnitIds: BusinessUnitIdsModel;
    businessUnitGroupIds: BusinessUnitGroupIdsModel;
    sparksForms: NgxsForm<SparksFormModel>[];
    sparkOptions: ISparkOption[];
    transactionAttributeOptions: ISparkOption[];
    itemAttributeOptions: { [x: string]: ItemAttributeFieldConfig };
    offerTemplate: string;
    offerDetailsForm: NgxsForm<OfferDetailsFormModel>;
    maximumUses: string;
    rewardOperatorType?: string;
    offerQualificationsForms: { [tier: number]: IQualificationForm[] };
    removedQualificationsForms: { [tier: number]: IQualificationForm[] };
    offerRewardsForms: { [tier: number]: (IRewardForm | IRewardCategoryForm)[] };
    removedRewardsForms: { [tier: number]: (IRewardForm | IRewardCategoryForm)[] };
    offerNearMissForms: { [tier: number]: INearMissForm };
    validations: IPromotionValidation[];
    editMode: boolean;
    newChanges: Partial<IStagedPromotion>;
    uploadedFiles: IUploadedFile[];
}

const CREATE_PROMOTION_STATE_TOKEN = new StateToken<CreatePromotionStateModel>('createPromotion');

@State<CreatePromotionStateModel>({
    name: CREATE_PROMOTION_STATE_TOKEN,
    defaults: {
        detailsForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        stagedPromotion: {
            promotionName: '',
            promotionType: ''
        },
        businessUnitIds: {
            includedBusinessUnitIds: [],
            excludedBusinessUnitIds: [],
        },
        businessUnitGroupIds: {
            includedBusinessUnitGroupIds: [],
            excludedBusinessUnitGroupIds: [],
        },
        sparksForms: [],
        sparkOptions: [],
        transactionAttributeOptions: [],
        itemAttributeOptions: {},
        offerTemplate: '',
        offerDetailsForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        maximumUses: '',
        offerQualificationsForms: {},
        removedQualificationsForms: {},
        offerRewardsForms: {},
        removedRewardsForms: {},
        offerNearMissForms: {},
        validations: [],
        editMode: false,
        newChanges: undefined,
        uploadedFiles: []
    }
})
@Injectable()
export class CreatePromotionState {

    constructor(
        private promotionService: PromotionService,
        private store: Store,
        private stagedPromotionBuilderService: StagedPromotionBuilderService
    ) {
    }

    static sparkOptionByType(type: string) {
        return createSelector([CreatePromotionState], (state: CreatePromotionStateModel): ISparkOption => {
            let sparkOption: ISparkOption = state.sparkOptions?.find((option: ISparkOption) => option.type === type);
            if (!sparkOption) {
                sparkOption = state.transactionAttributeOptions?.find((option: ISparkOption) => option.type === type);
            }
            return sparkOption;
        });
    }

    static isExistingSparkTypeOnStagedPromotion(type: string) {
        return createSelector([CreatePromotionState], (state: CreatePromotionStateModel): boolean =>
            state.stagedPromotion.sparks?.some(s => s.sparkTypeCode === type));
    }

    static itemAttributeOptionById(attributeId: string) {
        return createSelector([CreatePromotionState], (state: CreatePromotionStateModel): ItemAttributeFieldConfig =>
            state.itemAttributeOptions?.[attributeId]);
    }

    @Selector()
    static stagedPromotion(state: CreatePromotionStateModel): IStagedPromotion {
        return state.stagedPromotion;
    }

    @Selector()
    static editMode(state: CreatePromotionStateModel): boolean {
        return state.editMode;
    }

    @Selector()
    static detailsForm(state: CreatePromotionStateModel): NgxsForm<DetailsFormModel> {
        return state.detailsForm;
    }

    @Selector([CreatePromotionState, LabelsState])
    static assignedLabels(state: CreatePromotionStateModel, labelsState: LabelsStateModel): ILabel[] {
        if (state.detailsForm.model?.addLabels) {
            return state.detailsForm.model.addLabels
                .map((tag) => labelsState.promotionLabels?.find((label: ILabel) => label.labelId === tag));
        }
        return [];
    }

    @Selector()
    static sparksForms(state: CreatePromotionStateModel): NgxsForm<SparksFormModel>[] {
        return state.sparksForms;
    }

    @Selector()
    static sparkOptions(state: CreatePromotionStateModel): ISparkOption[] {
        return state.sparkOptions;
    }

    @Selector()
    static businessUnitIds(state: CreatePromotionStateModel): BusinessUnitIdsModel {
        return state.businessUnitIds;
    }

    @Selector()
    static businessUnitGroupIds(state: CreatePromotionStateModel): BusinessUnitGroupIdsModel {
        return state.businessUnitGroupIds;
    }

    @Selector([CreatePromotionState, BusinessUnitState])
    static businessUnits(state: CreatePromotionStateModel, businessUnitState: BusinessUnitStateModel): BusinessUnitsModel {
        const allBusinessUnits: IBusinessUnit[] = businessUnitState.businessUnits;
        return {
            includedBusinessUnits: state.businessUnitIds.includedBusinessUnitIds.map((id: string) => allBusinessUnits.find(b => b.businessUnitId === id)),
            excludedBusinessUnits: state.businessUnitIds.excludedBusinessUnitIds.map((id: string) => allBusinessUnits.find(b => b.businessUnitId === id))
        };
    }

    @Selector([CreatePromotionState, BusinessUnitState])
    static businessUnitGroups(state: CreatePromotionStateModel, businessUnitState: BusinessUnitStateModel): BusinessUnitGroupsModel {
        const allBusinessUnitGroups: IBusinessUnitGroup[] = businessUnitState.businessUnitGroups;
        let includedBusinessUnitGroups: IBusinessUnitGroup[] = [];
        let excludedBusinessUnitGroups: IBusinessUnitGroup[] = [];
        if (allBusinessUnitGroups?.length) {
            includedBusinessUnitGroups = state.businessUnitGroupIds.includedBusinessUnitGroupIds
                .map((id: string) => allBusinessUnitGroups.find(bug => bug.businessUnitGroupId === id))
                .filter((group: IBusinessUnitGroup) => !!group);
            excludedBusinessUnitGroups = state.businessUnitGroupIds.excludedBusinessUnitGroupIds
                .map((id: string) => allBusinessUnitGroups.find(bug => bug.businessUnitGroupId === id))
                .filter((group: IBusinessUnitGroup) => !!group);
        }
        return {
            includedBusinessUnitGroups, excludedBusinessUnitGroups
        };
    }

    @Selector([CreatePromotionState, BusinessUnitState])
    static totalIncludedBusinessUnits(state: CreatePromotionStateModel, businessUnitState: BusinessUnitStateModel): number {
        let count = 0;
        const allBusinessUnitGroups: IBusinessUnitGroup[] = businessUnitState.businessUnitGroups;
        if (allBusinessUnitGroups && state.businessUnitGroupIds) {
            state.businessUnitGroupIds.includedBusinessUnitGroupIds.forEach((groupId: string) => {
                const foundBusinessUnitGroup = allBusinessUnitGroups.find(bug => bug.businessUnitGroupId === groupId);
                if (foundBusinessUnitGroup?.businessUnits) {
                    count += foundBusinessUnitGroup.businessUnits.length;
                }
            });
        }
        if (state.businessUnitIds) {
            count += state.businessUnitIds.includedBusinessUnitIds.length;
        }
        return count;
    }

    @Selector([CreatePromotionState, BusinessUnitState])
    static totalExcludedBusinessUnits(state: CreatePromotionStateModel, businessUnitState: BusinessUnitStateModel): number {
        let count = 0;
        const allBusinessUnitGroups: IBusinessUnitGroup[] = businessUnitState.businessUnitGroups;
        if (allBusinessUnitGroups && state.businessUnitGroupIds) {
            state.businessUnitGroupIds.excludedBusinessUnitGroupIds.forEach((groupId: string) => {
                const foundBusinessUnitGroup = allBusinessUnitGroups.find(bug => bug.businessUnitGroupId === groupId);
                if (foundBusinessUnitGroup?.businessUnits) {
                    count += foundBusinessUnitGroup.businessUnits.length;
                }
            });
        }
        if (state.businessUnitIds) {
            count += state.businessUnitIds.excludedBusinessUnitIds.length;
        }
        return count;
    }

    @Selector()
    static offerForms(state: CreatePromotionStateModel): IOfferForms {
        const removedQualificationsForms: IQualificationForm[] = [];
        Object.values(state.removedQualificationsForms).forEach((forms: IQualificationForm[]) => {
            removedQualificationsForms.push(...forms);
        });
        const removedRewardsForms: (IRewardForm | IRewardCategoryForm)[] = [];
        Object.values(state.removedRewardsForms).forEach((forms: (IRewardForm | IRewardCategoryForm)[]) => {
            removedRewardsForms.push(...forms);
        });
        return {
            offerTemplate: state.offerTemplate,
            promotionType: state.offerDetailsForm?.model?.promotionType,
            maximumUses: state.offerDetailsForm?.model?.maximumUses,
            qualificationsForms: state.offerQualificationsForms,
            removedQualificationsForms,
            rewardsForms: state.offerRewardsForms,
            removedRewardsForms,
            nearMissForms: state.offerNearMissForms
        };
    }

    @Selector()
    static seriousValidations(state: CreatePromotionStateModel): IPromotionValidation[] {
        if (state.validations) {
            return state.validations.filter(v => v.validationSeverityType === 'SERIOUS');
        }
        return [];
    }

    @Selector()
    static warningValidations(state: CreatePromotionStateModel): IPromotionValidation[] {
        if (state.validations) {
            return state.validations.filter(v => v.validationSeverityType === 'WARNING');
        }
        return [];
    }

    @Selector()
    static infoValidations(state: CreatePromotionStateModel): IPromotionValidation[] {
        if (state.validations) {
            return state.validations.filter(v => v.validationSeverityType === 'INFO');
        }
        return [];
    }

    @Selector()
    static newChanges(state: CreatePromotionStateModel): Partial<IStagedPromotion> {
        return state.newChanges;
    }

    @Selector()
    static uploadedFiles(state: CreatePromotionStateModel): IUploadedFile[] {
        return state.uploadedFiles;
    }

    @Action(CreatePromotionActions.LoadPromotion)
    loadPromotion(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.LoadPromotion): Observable<IStagedPromotion> {
        return this.promotionService.findStagedPromotion(action.id).pipe(
            tap((result: IStagedPromotion) => {
                if (result) {
                    this.stagedPromotionBuilderService.updateFormsFromStagedPromotion(ctx, result, true);
                } else {
                    throw new Error(`Could not find promotion [${action.id}]`);
                }
            })
        );
    }

    @Action(CreatePromotionActions.ClonePromotion)
    clonePromotion(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.ClonePromotion): Observable<IStagedPromotion> {
        return this.promotionService.findStagedPromotion(action.id).pipe(
            tap((result: IStagedPromotion) => {
                if (result) {
                    this.stagedPromotionBuilderService.updateFormsFromStagedPromotion(ctx, result, false);
                } else {
                    throw new Error(`Could not find promotion [${action.id}]`);
                }
            })
        );
    }

    @Action(CreatePromotionActions.UpdateStagedPromotion)
    updateStagedPromotion(ctx: StateContext<CreatePromotionStateModel>): Observable<ISaveStagedPromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        return this.promotionService.saveStagedPromotion(stagedPromotion, !stagedPromotion.promotionId).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.UpdateStagedPromotionStatus)
    submitStagedPromotion(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.UpdateStagedPromotionStatus): Observable<ISaveStagedPromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        stagedPromotion.promoStatusCode = action.newStatus;
        return this.promotionService.saveStagedPromotion(stagedPromotion, !stagedPromotion.promotionId).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.UpdatePublishedPromotion)
    updatePublishedPromotion(ctx: StateContext<CreatePromotionStateModel>): Observable<ISaveStagedPromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        return this.promotionService.saveStagedPromotion(stagedPromotion, true).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.SubmitForApproval)
    submitForApproval(ctx: StateContext<CreatePromotionStateModel>): Observable<ISaveStagedPromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        return this.promotionService.submitForApproval(stagedPromotion).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.BackToDraft)
    backToDraft(ctx: StateContext<CreatePromotionStateModel>): Observable<ISaveStagedPromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        return this.promotionService.backToDraft(stagedPromotion).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.WorkflowCompleted)
    workflowCompleted(ctx: StateContext<CreatePromotionStateModel>) {
        const stagedPromotion = ctx.getState().stagedPromotion;
        return this.promotionService.reviewsCompleted(stagedPromotion).pipe(this.saveStagedPromotionResponseCallback(ctx));
    }

    @Action(CreatePromotionActions.AddToIncludedBusinessUnitGroups)
    addToIncludedBusinessUnitGroups(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddToIncludedBusinessUnitGroups): void {
        const group = this.findBusinessUnitGroupByGroupName(action.groupName);
        const savedBusinessUnitGroups = ctx.getState().businessUnitGroupIds;
        const savedBusinessUnits = ctx.getState().businessUnitIds;
        if (group && savedBusinessUnitGroups && !savedBusinessUnitGroups.includedBusinessUnitGroupIds.includes(group.businessUnitGroupId)) {
            ctx.patchState({
                businessUnitGroupIds: {
                    includedBusinessUnitGroupIds: [...savedBusinessUnitGroups.includedBusinessUnitGroupIds, group.businessUnitGroupId],
                    excludedBusinessUnitGroupIds: savedBusinessUnitGroups.excludedBusinessUnitGroupIds,
                },
                businessUnitIds: {
                    includedBusinessUnitIds: savedBusinessUnits.includedBusinessUnitIds.filter((id: string) =>
                        !group.businessUnits?.some((businessUnit: IBusinessUnit) => businessUnit.businessUnitId === id)),
                    excludedBusinessUnitIds: savedBusinessUnits.excludedBusinessUnitIds,
                }
            });
        } else if (!group) {
            throw new Error('Failed to find business unit group.');
        } else {
            throw new Error('Failed to add business unit group to list of included business unit groups.');
        }
    }

    @Action(CreatePromotionActions.AddToIncludedBusinessUnits)
    addToIncludedBusinessUnits(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddToIncludedBusinessUnits): void {
        const savedBusinessUnits = ctx.getState().businessUnitIds;
        const businessUnitsToAdd = [];
        action.businessUnitIds.forEach(businessUnitId => {
            if (savedBusinessUnits && !savedBusinessUnits.includedBusinessUnitIds.includes(businessUnitId)) {
                businessUnitsToAdd.push(businessUnitId);
            }
        });
        if (businessUnitsToAdd.length) {
            ctx.patchState({
                businessUnitIds: {
                    includedBusinessUnitIds: [...ctx.getState().businessUnitIds.includedBusinessUnitIds, ...businessUnitsToAdd],
                    excludedBusinessUnitIds: ctx.getState().businessUnitIds.excludedBusinessUnitIds,
                }
            });
        } else {
            throw new Error('Failed to add business unit(s) to list of included business units.');
        }
    }

    @Action(CreatePromotionActions.RemoveFromIncludedBusinessUnitGroups)
    removeFromIncludedBusinessUnitGroups(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveFromIncludedBusinessUnitGroups): void {
        const index = ctx.getState().businessUnitGroupIds.includedBusinessUnitGroupIds.findIndex((id: string) => id === action.groupId);
        if (index !== -1) {
            ctx.setState(produce((draft) => {
                draft.businessUnitGroupIds.includedBusinessUnitGroupIds.splice(index, 1);
            }));
        } else {
            throw new Error('Failed to remove business unit group from list of included business unit groups.');
        }
    }

    @Action(CreatePromotionActions.RemoveFromIncludedBusinessUnits)
    removeFromIncludedBusinessUnits(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveFromIncludedBusinessUnits): void {
        const index = ctx.getState().businessUnitIds.includedBusinessUnitIds.findIndex((id: string) => id === action.businessUnitId);
        if (index !== -1) {
            ctx.setState(produce((draft) => {
                draft.businessUnitIds.includedBusinessUnitIds.splice(index, 1);
            }));
        } else {
            throw new Error('Failed to remove business unit from list of included business units.');
        }
    }

    @Action(CreatePromotionActions.AddToExcludedBusinessUnitGroups)
    addToExcludedBusinessUnitGroups(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddToExcludedBusinessUnitGroups): void {
        const group = this.findBusinessUnitGroupByGroupName(action.groupName);
        const savedBusinessUnitGroups = ctx.getState().businessUnitGroupIds;
        const savedBusinessUnits = ctx.getState().businessUnitIds;
        if (group && savedBusinessUnitGroups && !savedBusinessUnitGroups.excludedBusinessUnitGroupIds.includes(group.businessUnitGroupId)) {
            ctx.patchState({
                businessUnitGroupIds: {
                    includedBusinessUnitGroupIds: savedBusinessUnitGroups.includedBusinessUnitGroupIds,
                    excludedBusinessUnitGroupIds: [...savedBusinessUnitGroups.excludedBusinessUnitGroupIds, group.businessUnitGroupId],
                },
                businessUnitIds: {
                    includedBusinessUnitIds: savedBusinessUnits.includedBusinessUnitIds,
                    excludedBusinessUnitIds: savedBusinessUnits.excludedBusinessUnitIds.filter((id: string) =>
                        !group.businessUnits?.some((businessUnit: IBusinessUnit) => businessUnit.businessUnitId === id)),
                }
            });
        } else if (!group) {
            throw new Error('Failed to find business unit group.');
        } else {
            throw new Error('Failed to add business unit group to list of excluded business unit groups.');
        }
    }

    @Action(CreatePromotionActions.AddToExcludedBusinessUnits)
    addToExcludedBusinessUnits(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddToExcludedBusinessUnits): void {
        const savedBusinessUnits = ctx.getState().businessUnitIds;
        const businessUnitsToAdd = [];
        action.businessUnitIds.forEach(businessUnitId => {
            if (savedBusinessUnits && !savedBusinessUnits.excludedBusinessUnitIds.includes(businessUnitId)) {
                businessUnitsToAdd.push(businessUnitId);
            }
        });
        if (businessUnitsToAdd.length) {
            ctx.patchState({
                businessUnitIds: {
                    includedBusinessUnitIds: savedBusinessUnits.includedBusinessUnitIds,
                    excludedBusinessUnitIds: [...savedBusinessUnits.excludedBusinessUnitIds, ...businessUnitsToAdd],
                }
            });
        } else {
            throw new Error('Failed to add business unit(s) to list of excluded business units.');
        }
    }

    @Action(CreatePromotionActions.RemoveFromExcludedBusinessUnitGroups)
    removeFromExcludedBusinessUnitGroups(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveFromExcludedBusinessUnitGroups): void {
        const index = ctx.getState().businessUnitGroupIds.excludedBusinessUnitGroupIds.findIndex((id: string) => id === action.groupId);
        if (index !== -1) {
            ctx.setState(produce((draft) => {
                draft.businessUnitGroupIds.excludedBusinessUnitGroupIds.splice(index, 1);
            }));
        } else {
            throw new Error('Failed to remove business unit group from list of excluded business unit groups.');
        }
    }

    @Action(CreatePromotionActions.RemoveFromExcludedBusinessUnits)
    removeFromExcludedBusinessUnits(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveFromExcludedBusinessUnits): void {
        const index = ctx.getState().businessUnitIds.excludedBusinessUnitIds.findIndex((id: string) => id === action.businessUnitId);
        if (index !== -1) {
            ctx.setState(produce((draft) => {
                draft.businessUnitIds.excludedBusinessUnitIds.splice(index, 1);
            }));
        } else {
            throw new Error('Failed to remove business unit from list of excluded business units.');
        }
    }

    @Action(CreatePromotionActions.GetSparkOptions)
    getSparkOptions(ctx: StateContext<CreatePromotionStateModel>): Observable<ISparkOption[]> {
        return this.store.dispatch(PromotionQualificationAttributeConfigurationActions.GetAllQualificationAttributeConfigs).pipe(
            switchMap(() => this.promotionService.getSparkOptions().pipe(
                tap((sparkOptions: ISparkOption[]) => {
                    const transactionAttributeOptions: ISparkOption[] = [];
                    const itemAttributeOptions = {};
                    const attributeConfigs = this.store.selectSnapshot(PromotionQualificationAttributeConfigurationState.allAttributes);
                    if (attributeConfigs?.length) {
                        for (const attributeConfig of attributeConfigs) {
                            if (attributeConfig.attributeLevel === AttributeLevel.TRANS) {
                                transactionAttributeOptions.push(this.convertTransactionAttributeConfigToSparkOption(attributeConfig, sparkOptions));
                            } else if (attributeConfig.attributeLevel === AttributeLevel.ITEM) {
                                itemAttributeOptions[attributeConfig.attributeId] = this.convertItemAttributeConfigToFieldConfig(attributeConfig);
                            }
                        }
                    }
                    ctx.patchState({
                        sparkOptions,
                        transactionAttributeOptions,
                        itemAttributeOptions
                    });
                })
            ))
        );
    }

    @Action(CreatePromotionActions.SaveSparks)
    saveSparks(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.SaveSparks): void {
        if (action.sparks) {
            if (action.sparks.every(spark =>
                spark.sparkType !== SparkTypeCode.SINGLE_USE_PROMO_CODE &&
                (spark.sparkType !== SparkTypeCode.SINGLE_USE_PROMO_CODE_GENERATE
                    || spark.promoCodeType !== 'universal'))) {
                ctx.patchState({ uploadedFiles: [] });
            }
            ctx.patchState({
                sparksForms: action.sparks.map((e) => ({ model: e }))
            });
        }
    }

    @Action(CreatePromotionActions.ValidatePromotion)
    validatePromotion(ctx: StateContext<CreatePromotionStateModel>): Observable<ValidatePromotionResponse> {
        const stagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        return this.promotionService.validatePromotion(stagedPromotion).pipe(
            tap(result =>
                ctx.patchState({
                    validations: result.validations,
                })
            )
        );
    }

    @Action(CreatePromotionActions.UpdateOfferTemplate)
    updateOfferTemplate(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.UpdateOfferTemplate): void {
        if (action.templateValue && action.templateValue !== ctx.getState().offerTemplate) {
            ctx.patchState({
                offerTemplate: action.templateValue,
                rewardOperatorType: action.offerTemplate?.rewardOperatorType,
            });
        }
    }

    @Action(CreatePromotionActions.AddQualificationTier)
    addQualificationTier(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddQualificationTier): void {
        const nextTier = action.tier ?? Object.keys(ctx.getState().offerQualificationsForms).length + 1;
        ctx.setState(produce((draft) => {
            draft.offerQualificationsForms[nextTier] = [];
        }));
    }

    @Action(CreatePromotionActions.RemoveQualificationTier)
    removeQualificationTier(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveQualificationTier): void {
        ctx.setState(produce(draft => {
            delete draft.offerQualificationsForms[action.tier];
            delete draft.offerNearMissForms[action.tier];
        }));
    }

    @Action(CreatePromotionActions.RemoveQualification)
    removeQualification(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveQualification): void {
        if (action.qualificationIndex !== -1) {
            ctx.setState(produce((draft) => {
                const removedQualification = draft.offerQualificationsForms[action.tier].splice(action.qualificationIndex, 1);
                let removedQualificationsForTier = draft.removedQualificationsForms[action.tier] || [];
                removedQualificationsForTier = removedQualificationsForTier.concat(removedQualification);
                draft.removedQualificationsForms[action.tier] = removedQualificationsForTier;
            }));
        }
    }

    @Action(CreatePromotionActions.ClearRemovedQualifications)
    clearRemovedQualifications(ctx: StateContext<CreatePromotionStateModel>): void {
        ctx.patchState({
            removedQualificationsForms: {}
        });
    }

    @Action(CreatePromotionActions.AddRewardTier)
    addRewardTier(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.AddRewardTier): void {
        const nextTier = action.tier ?? Object.keys(ctx.getState().offerRewardsForms).length + 1;
        ctx.setState(produce((draft) => {
            draft.offerRewardsForms[nextTier] = [];
        }));
    }

    @Action(CreatePromotionActions.RemoveRewardTier)
    removeRewardTier(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveRewardTier): void {
        ctx.setState(produce(draft => {
            delete draft.offerRewardsForms[action.tier];
        }));
    }

    @Action(CreatePromotionActions.RemoveReward)
    removeReward(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveReward): void {
        if (action.rewardIndex !== -1) {
            ctx.setState(produce((draft) => {
                const removedReward = draft.offerRewardsForms[action.tier].splice(action.rewardIndex, 1);
                let removedRewardsForTier = draft.removedRewardsForms[action.tier] || [];
                removedRewardsForTier = removedRewardsForTier.concat(removedReward);
                draft.removedRewardsForms[action.tier] = removedRewardsForTier;
            }));
        }
    }

    @Action(CreatePromotionActions.ClearRemovedRewards)
    clearRemovedRewards(ctx: StateContext<CreatePromotionStateModel>): void {
        ctx.patchState({
            removedRewardsForms: {}
        });
    }

    @Action(CreatePromotionActions.RemoveNearMiss)
    removeNearMiss(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.RemoveNearMiss): void {
        ctx.setState(produce(draft => {
            delete draft.offerNearMissForms[action.tier];
        }));
    }


    @Action(CreatePromotionActions.CalculateNewChanges)
    calculateNewChanges(ctx: StateContext<CreatePromotionStateModel>): void {
        const updatedStagedPromotion = this.stagedPromotionBuilderService.updatedStagedPromotion(ctx);
        const differences: Partial<IStagedPromotion> = diff(ctx.getState().stagedPromotion, updatedStagedPromotion);
        ctx.patchState({ newChanges: differences });
    }

    @Action(CreatePromotionActions.SavePromoCodeFile)
    savePromoCodeFile(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.SavePromoCodeFile): Observable<number> {
        ctx.patchState({
            uploadedFiles: [...ctx.getState().uploadedFiles, {
                filename: action.filename,
                url: action.url,
                uploading: true
            }]
        });
        return this.promotionService.savePromoCodeFile(action.filename, action.url, ctx.getState().stagedPromotion.promotionId).pipe(
            tap((response: number) => {
                const updatedFiles = ctx.getState().uploadedFiles.filter(e => e.filename !== action.filename);
                if (response >= 0) {
                    ctx.patchState({
                        uploadedFiles: [...updatedFiles, {
                            filename: action.filename,
                            url: action.url,
                            uploading: false,
                            value: response
                        }]
                    });
                } else {
                    ctx.patchState({
                        uploadedFiles: updatedFiles
                    });
                    throw new Error('Could not read file');
                }
            }, () => {
                const updatedFiles = ctx.getState().uploadedFiles.filter(e => e.filename !== action.filename);
                ctx.patchState({
                    uploadedFiles: updatedFiles
                });
                throw new Error('Could not read file');
            })
        );
    }

    @Action(CreatePromotionActions.DeletePromoCodeFile)
    deletePromoCodeFile(ctx: StateContext<CreatePromotionStateModel>, action: CreatePromotionActions.DeletePromoCodeFile): void {
        ctx.patchState({ uploadedFiles: ctx.getState().uploadedFiles.filter(e => e.filename !== action.filename) });
    }


    private findBusinessUnitGroupByGroupName(groupName: string): IBusinessUnitGroup {
        const allBusinessUnitGroups: IBusinessUnitGroup[] = this.store.selectSnapshot(BusinessUnitState.businessUnitGroups);
        if (allBusinessUnitGroups?.length) {
            return allBusinessUnitGroups.filter(g => g.businessUnitGroupName?.toLowerCase() === groupName.toLowerCase())[0];
        } else {
            throw new Error('Failed to find business unit group. No configured business unit groups exist.');
        }
    }

    private saveStagedPromotionResponseCallback(ctx: StateContext<CreatePromotionStateModel>): MonoTypeOperatorFunction<ISaveStagedPromotionResponse> {
        return tap((result: ISaveStagedPromotionResponse) => {
            if (result.success) {
                ctx.patchState({
                    stagedPromotion: result.stagedPromotion
                });
            } else {
                throw new Error(result.message);
            }
        });
    }

    private convertTransactionAttributeConfigToSparkOption(attributeConfig: IQualificationAttributeConfig, sparkOptions: ISparkOption[]): ISparkOption {
        const attributeSparkOption: ISparkOption = cloneDeep(sparkOptions.find(option => option.type === SparkTypeCode.TRANSACTION_ATTRIBUTE));
        attributeSparkOption.type = `${attributeSparkOption.type}-${attributeConfig.attributeId}`;
        attributeSparkOption.fields.push({
            fieldId: 'operatorType', fieldType: 'select',
            label: 'Operator Type', placeholder: 'Choose an operator type',
            required: true, multiSelect: false, options: this.getOperatorTypeOptions(attributeConfig.attributeTypeCode)
        });
        attributeSparkOption.fields.push({
            fieldId: this.getValueFieldId(attributeConfig.attributeTypeCode), fieldType: this.getValueFieldType(attributeConfig.attributeTypeCode),
            label: 'Value', placeholder: 'Enter a value',
            required: true, multiSelect: true, options: this.getValueFieldOptions(attributeConfig)
        });
        return attributeSparkOption;
    }

    private convertItemAttributeConfigToFieldConfig(attributeConfig: IQualificationAttributeConfig): ItemAttributeFieldConfig {
        return {
            operatorOptions: this.getOperatorTypeOptions(attributeConfig.attributeTypeCode),
            valueFieldType: this.getValueFieldType(attributeConfig.attributeTypeCode),
            valueFieldId: this.getValueFieldId(attributeConfig.attributeTypeCode),
            valueFieldOptions: this.getValueFieldOptions(attributeConfig),
        };
    }

    private getOperatorTypeOptions(attributeTypeCode: string): SelectOption[] {
        let operatorOptions: SelectOption[] = [];
        if (attributeTypeCode === AttributeTypeCode.NUMBER) {
            operatorOptions = [TransactionAttributeSelectOptions.GREATER, TransactionAttributeSelectOptions.LESSER, TransactionAttributeSelectOptions.EQUALS];
        } else if (attributeTypeCode === AttributeTypeCode.TEXT) {
            operatorOptions = [TransactionAttributeSelectOptions.EQUALS];
        } else if (attributeTypeCode === AttributeTypeCode.LIST) {
            operatorOptions = [TransactionAttributeSelectOptions.IN];
        }
        return operatorOptions;
    }

    private getValueFieldType(attributeTypeCode: string): 'text' | 'number' | 'select' {
        if (attributeTypeCode === AttributeTypeCode.LIST) {
            return 'select';
        } else if (attributeTypeCode === AttributeTypeCode.TEXT) {
            return 'text';
        } else {
            return 'number';
        }
    }

    private getValueFieldId(attributeTypeCode: string): 'valueString' | 'valueList' {
        return attributeTypeCode === AttributeTypeCode.LIST ? 'valueList' : 'valueString';
    }

    private getValueFieldOptions(attributeConfig: IQualificationAttributeConfig): SelectOption[] {
        if (attributeConfig.attributeTypeCode === AttributeTypeCode.LIST) {
            return attributeConfig.attributeValueOptions.map(
                (attributeValueOption: IQualificationAttributeValueOptionConfig): SelectOption => ({ key: attributeValueOption.attributeValueOptionId, displayValue: attributeValueOption.attributeValue })
            );
        }
        return [];
    }

}
