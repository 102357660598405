import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { GetNotificationsResponse, INotification } from '../interfaces/notification.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NotificationsActions } from './notifications.actions';
import { AuthorizedState } from './authorized.state';
import { NotificationService } from '../services/notification.service';
import { tap } from 'rxjs/operators';
import produce from 'immer';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { AuthorizedActions } from './authorized.actions';

export class NotificationsStateModel {
    notifications: INotification[];
    interval: NodeJS.Timeout;
}

const NOTIFICATIONS_STATE_TOKEN = new StateToken<NotificationsStateModel>('notifications');

@State<NotificationsStateModel>({
    name: NOTIFICATIONS_STATE_TOKEN,
    defaults: {
        notifications: [],
        interval: undefined
    }
})
@Injectable()
export class NotificationsState {

    constructor(private notificationService: NotificationService, private store: Store) { }

    @Selector()
    static notifications(state: NotificationsStateModel): INotification[] {
        return state.notifications;
    }

    @Selector()
    static hasUnviewedNotifications(state: NotificationsStateModel): boolean {
        return state.notifications?.some(notification => !notification.viewed);
    }

    @Action(NotificationsActions.LoadNotifications)
    loadNotifications(ctx: StateContext<NotificationsStateModel>) {
        this.store.dispatch(NotificationsActions.GetNotifications);
        if (!ctx.getState().interval) {
            ctx.patchState({
                interval: setInterval(() => this.store.dispatch(NotificationsActions.GetNotifications), 60000)
            });
        }
    }

    @Action(AuthorizedActions.LoggedOut)
    clearInterval(ctx: StateContext<NotificationsStateModel>) {
        clearInterval(ctx.getState().interval);
        ctx.patchState({
            interval: undefined
        });
    }

    @Action(NotificationsActions.GetNotifications)
    getNotifications(ctx: StateContext<NotificationsStateModel>): Observable<GetNotificationsResponse> {
        const activeUserId = this.store.selectSnapshot(AuthorizedState.authorizedUser)?.userId;
        return this.notificationService.getNotifications(activeUserId).pipe(
            tap((result: GetNotificationsResponse) =>
                ctx.patchState({
                    notifications: result.notifications
                })
            )
        );
    }

    @Action(NotificationsActions.MarkAllAsViewed)
    markAllAsViewed(ctx: StateContext<NotificationsStateModel>): Observable<IGenericServerResponse> {
        const notificationIds = ctx.getState().notifications?.filter(notification => !notification.viewed)?.map(notification => notification.notificationId);
        if (notificationIds?.length) {
            const activeUserId = this.store.selectSnapshot(AuthorizedState.authorizedUser)?.userId;
            return this.notificationService.markNotificationsAsViewed(notificationIds, activeUserId).pipe(
                tap((result: IGenericServerResponse) => {
                    if (result.success) {
                        ctx.setState(produce((draft) => {
                            draft.notifications.forEach(notification => notification.viewed = true);
                        }));
                    }
                })
            );
        }
        return of({ success: false });
    }
}
