import { Pipe, PipeTransform } from '@angular/core';
import { TenderTypeCode } from '../../@core/interfaces/transaction.interface';
import { TenderUtil } from '../../@core/utils/tender.util';

@Pipe({ name: 'tenderTypeCodeIcon' })
export class TenderTypeCodeIconPipe implements PipeTransform {
    transform(tenderTypeCode: TenderTypeCode): string {
        return TenderUtil.getTypeCodeIcon(tenderTypeCode);
    }
}
