import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {
    DeploymentStatusCode,
    IDeployment,
    IDeploymentStatistics,
    IDeploymentTemplate,
    IInstallation,
    IInstallationGroup,
    IVersionedBusinessUnit
} from "../interfaces/deployments/deployment.interface";
import { DeploymentsService } from "../services/deployments.service";
import { DeploymentsActions } from "./deployments.actions";
import { tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { GetVersionedBusinessUnitsResponse } from "../interfaces/deployments/deployment-api.interface";
import {
    DeploymentsDataAggregatorService,
    MISSING_DATA_LABEL,
    UNKNOWN_DEPLOYMENT
} from "../services/deployments-data-aggregator.service";
import { cloneDeep } from 'lodash';

export class DeploymentsStateModel {
    versionedBusinessUnits: { [packageName: string]: IVersionedBusinessUnit[] };
    packages: string[];
    deploymentStatistics: IDeploymentStatistics[];
    deployments: IDeployment[];
    installGroups: IInstallationGroup[];
    templates: IDeploymentTemplate[];
    currentPackage: string;
    availableVersions: string[];
}

const ACTIVE_DEPLOYMENTS_TOKEN = new StateToken<DeploymentsStateModel>('deployments');

@State<DeploymentsStateModel>({
    name: ACTIVE_DEPLOYMENTS_TOKEN,
    defaults: {
        versionedBusinessUnits: {},
        packages: [],
        deploymentStatistics: [],
        deployments: [],
        installGroups: [],
        templates: [],
        currentPackage: 'point-of-sale',
        availableVersions: []
    }
})
@Injectable()
export class DeploymentsState {

    constructor(
        private store: Store,
        private deploymentsService: DeploymentsService,
        private deploymentsDataAggregatorService: DeploymentsDataAggregatorService
    ) {
    }

    @Selector()
    static deploymentStatistics(state: DeploymentsStateModel): IDeploymentStatistics[] {
        return state.deploymentStatistics;
    }

    static deploymentStatistic(version: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) => {
            return state.deploymentStatistics?.filter((stat) => stat.deployment?.version === version).at(0);
        });
    }

    static deployment(version: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) => {
            return state.deployments?.filter((deployment) => deployment?.version === version).at(0);
        });
    }

    @Selector()
    static deployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments;
    }

    @Selector()
    static activeDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => [DeploymentStatusCode.ACTIVE, DeploymentStatusCode.PAUSED, DeploymentStatusCode.DELAYED].includes(deployment.deploymentStatusCode));
    }

    @Selector()
    static futureDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => [DeploymentStatusCode.PENDING].includes(deployment.deploymentStatusCode));
    }

    @Selector()
    static historicDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => deployment.historical === true);
    }

    @Selector()
    static deploymentTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates;
    }

    @Selector()
    static activeDeploymentTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates?.filter(e => !e.archived);
    }

    @Selector()
    static installGroups(state: DeploymentsStateModel): IInstallationGroup[] {
        return state.installGroups;
    }

    @Selector()
    static installGroupNames(state: DeploymentsStateModel): string[] {
        return state.installGroups.map(e => e.groupName);
    }

    @Selector()
    static templates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates;
    }

    @Selector()
    static activeTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates.filter(e => !e.archived);
    }

    @Selector()
    static currentPackage(state: DeploymentsStateModel): string {
        return state.currentPackage;
    }

    @Selector()
    static allPackages(state: DeploymentsStateModel): string[] {
        return state.packages;
    }

    @Selector()
    static availableVersions(state: DeploymentsStateModel): string[] {
        return state.availableVersions;
    }

    @Action(DeploymentsActions.LoadVersionedBusinessUnits)
    loadVersionedBusinessUnits(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadVersionedBusinessUnits): Observable<GetVersionedBusinessUnitsResponse> {
        console.log('Loading versioned business Units...');
        return this.deploymentsService.getVersionedBusinessUnits(action.packageName ? action.packageName : ctx.getState().currentPackage);
        // We do not save these to the state as with enough business units this will overflow the browser storage.
    }

    @Action(DeploymentsActions.LoadInstallationGroups)
    loadInstallationGroups(ctx: StateContext<DeploymentsStateModel>): Observable<IInstallationGroup[]> {
        return this.deploymentsService.getInstallationGroups().pipe(
            tap(result => {
                ctx.patchState({
                    installGroups: result
                })
            }));
    }

    @Action(DeploymentsActions.LoadTemplates)
    loadTemplates(ctx: StateContext<DeploymentsStateModel>): Observable<IDeploymentTemplate[]> {
        return this.deploymentsService.getAllDeploymentTemplates().pipe(
            tap(result => {
                ctx.patchState({
                    templates: result
                })
            }));
    }

    @Action(DeploymentsActions.SaveInstallationGroup)
    saveInstallationGroup(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveInstallationGroup): Observable<any> {
        return this.deploymentsService.saveInstallationGroups(action.installationGroup);
    }

    @Action(DeploymentsActions.SaveTemplate)
    saveDeploymentTemplate(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveTemplate): Observable<any> {
        console.log(action.deploymentTemplate);
        return this.deploymentsService.saveDeploymentTemplate(action.deploymentTemplate);
    }

    @Action(DeploymentsActions.SaveDeployment)
    saveDeployment(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveDeployment): Observable<any> {
        return this.deploymentsService.saveDeployment(action.deployment, ctx.getState().currentPackage);
    }


    @Action(DeploymentsActions.LoadDeployments)
    loadDeployments(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadDeployments): Observable<IDeployment[]> {
        return this.deploymentsService.getAllDeployments(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                ctx.patchState({
                    deployments: result
                })
            }));
    }

    @Action(DeploymentsActions.LoadAvailableVersions)
    loadAvailableVersions(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadAvailableVersions): Observable<string[]> {
        return this.deploymentsService.getAvailableVersions(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                ctx.patchState({
                    availableVersions: result
                })
            }));
    }

    @Action(DeploymentsActions.LoadPackages)
    loadPackages(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadPackages): Observable<string[]> {
        return this.deploymentsService.getPackages().pipe(
            tap(result => {
                ctx.patchState({
                    packages: result,
                    currentPackage: result?.length > 0 ? result[0] : 'point-of-sale'
                })
            }));
    }

    @Action(DeploymentsActions.ChangePackage)
    changePackage(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.ChangePackage): void {
        ctx.patchState({
            currentPackage: action.packageName
        });
    }

    @Action(DeploymentsActions.GetInstallationsForGroup)
    getInstallationsForGroup(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.GetInstallationsForGroup): Observable<IInstallation[]> {
        return this.deploymentsService.getInstallationsByGroup(action.groupId, ctx.getState().currentPackage);
    }

    @Action(DeploymentsActions.LoadDeploymentStatistics)
    loadDeploymentStatistics(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadDeploymentStatistics): Observable<GetVersionedBusinessUnitsResponse> {
        return this.deploymentsService.getVersionedBusinessUnits(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                const versionMap = this.deploymentsDataAggregatorService.sortVersionedUnitsByVersion(result.versionedBusinessUnits);
                let statsList: IDeploymentStatistics[] = [];
                versionMap.forEach((value: IVersionedBusinessUnit[], key: string) => {
                    if (key !== MISSING_DATA_LABEL) {
                        const deployment = ctx.getState().deployments?.find(depl => depl.version === key);
                        const mockDeployment = cloneDeep(UNKNOWN_DEPLOYMENT);
                        mockDeployment.version = key;
                        statsList.push({
                            deployment: deployment ? deployment : mockDeployment,
                            numberOfStores: value.length,
                            numberOfDevices: value.reduce((sum, unit) => sum + unit.installations?.length, 0),
                            errors: this.deploymentsDataAggregatorService.buildErrors(key, value)
                        })
                    }
                })

                //Get stats for any active deployments that may not have business units yet
                ctx.getState().deployments?.filter((deployment) => !versionMap.has(deployment.version))
                    .forEach(deploymentActiveButNoUnit => {
                        statsList.push({
                            deployment: deploymentActiveButNoUnit,
                            numberOfStores: 0,
                            numberOfDevices: 0,
                            errors: undefined
                        })
                    })
                ctx.patchState({
                    deploymentStatistics: statsList
                })
            })
        );
    }

    @Action(DeploymentsActions.CancelDeployment)
    cancelDeployment(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.CancelDeployment): Observable<void> {
        return this.deploymentsService.cancelDeployment(action.version, ctx.getState().currentPackage);
    }

    @Action(DeploymentsActions.AdvanceDeploymentManually)
    advanceDeploymentManually(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.AdvanceDeploymentManually): Observable<void> {
        return this.deploymentsService.manuallyAdvance(action.version, ctx.getState().currentPackage).pipe(
            tap(result => {
                if (!result.success) {
                    return throwError(new Error(result.message));
                }
            })
        );
    }
}
