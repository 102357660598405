import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SelectOption } from '../../@forms/forms.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { GetLabelsResponse, ILabel } from '../interfaces/label.interface';
import { ContextService } from '../services/context.service';
import { LabelsActions } from './labels.actions';
import { PromotionDashboardActions } from './promotion-dashboard.actions';

export class LabelsStateModel {
    campaignLabels: ILabel[];
    promotionLabels: ILabel[];
    colorOptions: SelectOption[];
}

const LABELS_STATE_TOKEN = new StateToken<LabelsStateModel>('labels');

@State<LabelsStateModel>({
    name: LABELS_STATE_TOKEN,
    defaults: {
        campaignLabels: [],
        promotionLabels: [],
        colorOptions: [
            { key: 'red', displayValue: 'Red', color: 'red' },
            { key: 'orange', displayValue: 'Orange', color: 'orange' },
            { key: 'yellow', displayValue: 'Yellow', color: 'yellow' },
            { key: 'green', displayValue: 'Green', color: 'green' },
            { key: 'teal', displayValue: 'Teal', color: 'teal' },
            { key: 'blue', displayValue: 'Blue', color: 'blue' },
            { key: 'purple', displayValue: 'Purple', color: 'purple' },
            { key: 'pink', displayValue: 'Pink', color: 'pink' },
        ]
    }
})
@Injectable()
export class LabelsState {

    constructor(
        private contextService: ContextService,
        private store: Store
    ) { }

    @Selector()
    static promotionLabels(state: LabelsStateModel): ILabel[] {
        return state.promotionLabels;
    }

    @Selector()
    static campaignLabels(state: LabelsStateModel): ILabel[] {
        return state.campaignLabels;
    }

    @Selector()
    static allPromotionLabelsAsSelectOptions(state: LabelsStateModel): SelectOption[] {
        return state.promotionLabels.map((label: ILabel) => ({ key: label.labelId, displayValue: label.labelName }));
    }

    @Selector()
    static allCampaignLabelsAsSelectOptions(state: LabelsStateModel): SelectOption[] {
        return state.campaignLabels.map((label: ILabel) => ({ key: label.labelId, displayValue: label.labelName }));
    }

    @Selector()
    static colorOptions(state: LabelsStateModel): SelectOption[] {
        return state.colorOptions;
    }

    @Action(LabelsActions.GetPromotionLabels)
    getPromotionLabels(ctx: StateContext<LabelsStateModel>): Observable<GetLabelsResponse> {
        return this.contextService.getLabelsByType('PROMOTION').pipe(
            tap((result: GetLabelsResponse) =>
                ctx.patchState({
                    promotionLabels: result.labels,
                })
            )
        );
    }

    @Action(LabelsActions.GetCampaignLabels)
    getCampaignLabels(ctx: StateContext<LabelsStateModel>): Observable<GetLabelsResponse> {
        return this.contextService.getLabelsByType('CAMPAIGN').pipe(
            tap((result: GetLabelsResponse) =>
                ctx.patchState({
                    campaignLabels: result.labels,
                })
            )
        );
    }

    @Action(LabelsActions.ApplyCampaignLabels)
    applyCampaignLabels(ctx: StateContext<LabelsStateModel>, action: LabelsActions.ApplyCampaignLabels): Observable<IGenericServerResponse> {
        const labelsToLink = ctx.getState().campaignLabels?.filter(label => action.labelsToLink?.some(l => l === label.labelId));
        const labelsToUnlink = ctx.getState().campaignLabels?.filter(label => action.labelsToUnlink?.some(l => l === label.labelId));
        return this.contextService.applyLabels(action.objectId, 'CAMPAIGN', labelsToLink, labelsToUnlink).pipe(
            tap((result: IGenericServerResponse) => {
                if (result.success) {
                    this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }

    @Action(LabelsActions.CreateLabel)
    createLabel(ctx: StateContext<LabelsStateModel>, action: LabelsActions.CreateLabel): Observable<IGenericServerResponse> {
        return this.contextService.createLabel(action.labelId, action.objectType, action.labelName, action.color, action.description);
    }
}
