import {
    ItemTypeCode,
    LineItemTypeCode,
    TaxGroupAuthority,
    TenderTypeCode,
    TransStatusCode,
    TransTypeCode
} from '../interfaces/transaction.interface';

export class TransUtil {
    private static readonly typeCodeIconMap = {
        [TransTypeCode.RETURN]: 'return',
        [TransTypeCode.VOID]: 'block',
        [TransTypeCode.SALE]: 'receipt'
    };

    private static readonly statusCodeIconMap = {
        [TransStatusCode.IN_PROGRESS]: 'auto-renew',
        [TransStatusCode.COMPLETED]: 'checkmark-circled',
        [TransStatusCode.ORPHANED]: 'broken-image',
        [TransStatusCode.CANCELLED]: 'block',
        [TransStatusCode.ABORTED]: 'block',
        [TransStatusCode.SUSPEND_CANCELLED]: 'pause',
        [TransStatusCode.SUSPEND_RETRIEVED]: 'pause',
        [TransStatusCode.SUSPENDED]: 'pause',
        [TransStatusCode.FAILED]: 'error-exclamation-filled',
        [TransStatusCode.VOIDED]: 'block',
    };

    private static orderTypeIconMap = {
        [LineItemTypeCode.ORDER_IN_STORE]: 'order',
        [LineItemTypeCode.REPEAT_DELIVERY]: 'ship'
    };

    static getTypeCodeIcon(type: TransTypeCode): string {
        return this.typeCodeIconMap[type] || 'lightbulb-on-outline';
    }

    static getStatusCodeIcon(status: TransStatusCode): string {
        return this.statusCodeIconMap[status] || 'help';
    }

    static getOrderTypeIcon(type: LineItemTypeCode): string {
        return this.orderTypeIconMap[type] || 'order';
    }

    static isRetail(type: TransTypeCode): boolean {
        return type === TransTypeCode.SALE ||
            type === TransTypeCode.RETURN ||
            type === TransTypeCode.REDEEM;
    }

    static isCardLine(line: any): boolean {
        return [
            TenderTypeCode.CREDIT_CARD,
            TenderTypeCode.DEBIT_CARD,
            TenderTypeCode.PRIVATE_LABEL,
            TenderTypeCode.CO_BRAND,
            TenderTypeCode.UNDETERMINED_CARD,
            TenderTypeCode.PAYMENT_TOKEN,
            TenderTypeCode.GIFT_CARD,
            TenderTypeCode.MANUAL_ENTRY,
            TenderTypeCode.E_WALLET
        ].includes(line.tenderTypeCode);
    }

    static isDeliveryLine(line: any): boolean {
        return [
            LineItemTypeCode.ORDER_IN_STORE,
            LineItemTypeCode.BISPIS
        ].includes(line.lineItemType);
    }

    static isOrderMerchandiseLine(line: any): boolean {
        return this.isDeliveryLine(line) && ItemTypeCode.STORE_ORDER_SHIPPING !== line.itemType;
    }

    static isOrderLine(line: any): boolean {
        return this.isOrderFeeLine(line) || this.isOrderMerchandiseLine(line);
    }

    static isOrderFeeLine(line: any): boolean {
        return this.isDeliveryLine(line) && ItemTypeCode.STORE_ORDER_SHIPPING === line.itemType;
    }

    static isTaxOverrideLine(line: any): boolean {
        return line.authorityId === TaxGroupAuthority.Override;
    }

    static hasItemAmount(itemTypeCode: ItemTypeCode): boolean {
        return [
            ItemTypeCode.STOCK,
            ItemTypeCode.GIFTCARD,
            ItemTypeCode.STORE_ORDER_SHIPPING,
            ItemTypeCode.MANUFACTURER_COUPON,
            ItemTypeCode.STORE_COUPON,
            ItemTypeCode.DONATION,
            ItemTypeCode.WARRANTY,
            ItemTypeCode.FEE,
            ItemTypeCode.STOCK
        ].includes(itemTypeCode);
    }
}
