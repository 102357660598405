import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICampaign, ISaveCampaignResponse } from '../interfaces/promotion/campaign.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';

@Injectable()
export class CampaignService {

    constructor(private http: HttpClient) { }

    loadCampaign(id: string): Observable<{ campaign: ICampaign }> {
        return this.http.get<{ campaign: ICampaign }>('app/campaign/' + id);
    }

    saveCampaign(campaign: ICampaign, create: boolean): Observable<ISaveCampaignResponse> {
        return this.http.put<ISaveCampaignResponse>('app/campaign/save', { campaign, create });
    }

    deleteCampaign(id: string): Observable<IGenericServerResponse> {
        return this.http.delete<IGenericServerResponse>('app/campaign/delete/' + id);
    }

    archiveCampaign(id: string): Observable<IGenericServerResponse> {
        return this.http.delete<IGenericServerResponse>('app/campaign/archive/' + id);
    }

    findActiveCampaigns(): Observable<{ campaigns: ICampaign[] }> {
        return this.http.get<{ campaigns: ICampaign[] }>('app/campaign/active');
    }
}
