import { Component, Input } from '@angular/core';

@Component({
    selector: 'jmcc-color-icon-card',
    templateUrl: './color-icon-card.component.html',
    styleUrls: ['./color-icon-card.component.scss']
})
export class ColorIconCardComponent {

    @Input()
    color: string;

    @Input()
    icon: string;
}
