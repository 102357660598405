import { Injectable } from '@angular/core';
import {
    GetElectronicJournalBusinessUnitsResponse,
    GetElectronicJournalByIdResponse,
    GetElectronicJournalDevicesResponse,
    GetElectronicJournalTenderTypeCodesResponse,
    GetElectronicJournalTransStatusCodesResponse,
    GetElectronicJournalTransTypeCodesResponse,
    SearchElectronicJournalRequest,
    SearchElectronicJournalResponse
} from '../interfaces/electronic-journal.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ElectronicJournalService {
    readonly baseUrl = '/app/electronic-journal/';

    constructor(private http: HttpClient) {
    }

    public getById(businessUnitId: string, deviceId: string, businessDate: Date | string, sequenceNumber: number): Observable<GetElectronicJournalByIdResponse> {
        return this.http.get<GetElectronicJournalByIdResponse>(
            `${this.baseUrl}${businessUnitId}/${deviceId}/${businessDate}/${sequenceNumber}`
        );
    }

    public search(request: SearchElectronicJournalRequest): Observable<SearchElectronicJournalResponse> {
        return this.http.post<SearchElectronicJournalResponse>(`${this.baseUrl}search`, request);
    }

    public getBusinessUnits(): Observable<GetElectronicJournalBusinessUnitsResponse> {
        return this.http.get<GetElectronicJournalBusinessUnitsResponse>(`${this.baseUrl}business-units`);
    }

    public getDevices(): Observable<GetElectronicJournalDevicesResponse> {
        return this.http.get<GetElectronicJournalDevicesResponse>(`${this.baseUrl}devices`);
    }

    public getTenderTypeCodes(): Observable<GetElectronicJournalTenderTypeCodesResponse> {
        return this.http.get<GetElectronicJournalTenderTypeCodesResponse>(`${this.baseUrl}tender-type-codes`);
    }

    public getTransTypeCodes(): Observable<GetElectronicJournalTransTypeCodesResponse> {
        return this.http.get<GetElectronicJournalTransTypeCodesResponse>(`${this.baseUrl}trans-type-codes`);
    }

    public getTransStatusCodes(): Observable<GetElectronicJournalTransStatusCodesResponse> {
        return this.http.get<GetElectronicJournalTransStatusCodesResponse>(`${this.baseUrl}trans-status-codes`);
    }
}
