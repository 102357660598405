import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'jmcc-tooltip-icon-button',
    templateUrl: './tooltip-icon-button.component.html',
})
export class TooltipIconButtonComponent {
    @Input()
    icon: string;

    @Input()
    iconPack = 'eva';

    @Input()
    tooltip: string;

    @Input()
    tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';

    @Input()
    disabled = false;

    @Input()
    ghostButton = true;

    @Input()
    size = 'small';

    @Output()
    onClick = new EventEmitter();

}
