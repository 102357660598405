import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'jmcc-pending-approval',
    templateUrl: './pending-approval.component.html',
    styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements OnInit, OnDestroy {

    private cameFromRegistration: boolean;
    private subscription = new Subscription();

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.subscription.add(this.route.params.subscribe((params: Params) => {
            this.cameFromRegistration = params?.originPage === 'register';
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getIcon(): string {
        return this.cameFromRegistration ? 'checkmark-circle-2-outline' : 'alert-triangle-outline';
    }

    getStatus(): string {
        return this.cameFromRegistration ? 'success' : 'warning';
    }

    getSubtext(): string {
        return this.cameFromRegistration ?
            'Account has successfully been registered but requires approval. An email will be sent when your account is approved or denied.' :
            'Log in is disabled while the account is pending approval. An email will be sent when your account is approved or denied.';
    }

}
