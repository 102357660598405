import { ItemOrGroup } from '../item-group.interface';
import { SelectOption } from '../../../@forms/forms.interface';
import { NgxsForm } from '../ngxs-form.interface';
import { IReward, IRewardCategory } from './promotion.interface';
import { FormControl } from '@angular/forms';

export interface OfferDetailsFormModel {
    promotionType: string;
    maximumUses: string;
}

export interface CreatePromotionOfferDetailsFormModel {
    promotionType: FormControl<string>;
    maximumUses: FormControl<string>;
}

export interface OfferQualificationFormModel {
    qualificationLevel: string;
    qualificationType: string;
    qualificationAmount: string;
    qualificationItemsRewardPreference?: string;
    includedItems: ItemOrGroup[];
    excludedItems: ItemOrGroup[];
    isNew?: boolean;
    tier: number;
}

export interface OfferQualificationFormGroupModel {
    qualificationLevel: FormControl<string>;
    qualificationType: FormControl<string>;
    qualificationAmount: FormControl<string>;
    qualificationItemsRewardPreference: FormControl<string>;
    includedItems: FormControl<ItemOrGroup[]>;
    excludedItems: FormControl<ItemOrGroup[]>;
    isNew: FormControl<boolean>;
    tier: FormControl<number>;
    isNearMiss: FormControl<boolean>;
}

export interface OfferRewardFormModel {
    rewardLevel: string;
    rewardType: string;
    rewardAmount: string;
    rewardQualificationEligible?: boolean;
    rewardQualificationProrate?: boolean;
    rewardQuantity: string;
    rewardMaxAmount: string;
    vendorFunded?: boolean;
    includedItems: ItemOrGroup[];
    excludedItems: ItemOrGroup[];
    isNew?: boolean;
    tier: number;
}

export interface OfferRewardFormGroupModel {
    rewardLevel: FormControl<string>;
    rewardType: FormControl<string>;
    rewardAmount: FormControl<string>;
    rewardQualificationEligible?: FormControl<boolean>;
    rewardQualificationProrate?: FormControl<boolean>;
    rewardQuantity: FormControl<string>;
    rewardMaxAmount: FormControl<string>;
    vendorFunded?: FormControl<boolean>;
    includedItems: FormControl<ItemOrGroup[]>;
    excludedItems: FormControl<ItemOrGroup[]>;
    isNew: FormControl<boolean>;
    tier: FormControl<number>;
}

export interface OfferRewardCategoryFormModel {
    rewardCategory: string;
    rewardType: string;
    rewardAmount: string;
    includedItems: ItemOrGroup[];
    excludedItems: ItemOrGroup[];
    isNew?: boolean;
    tier: number;
}

export interface OfferRewardCategoryFormGroupModel {
    rewardCategory: FormControl<string>;
    rewardType: FormControl<string>;
    rewardAmount: FormControl<string>;
    includedItems: FormControl<ItemOrGroup[]>;
    excludedItems: FormControl<ItemOrGroup[]>;
    isNew: FormControl<boolean>;
    tier: FormControl<number>;
}

export interface NearMissFormGroupModel {
    nearMissQualification: FormControl<string>;
    nearMissAmount: FormControl<number>;
    nearMissMessage: FormControl<string>;
}

export interface NearMissFormModel {
    nearMissQualification: string;
    nearMissAmount: number;
    nearMissMessage: string;
}

export interface IOfferForms {
    offerTemplate: string;
    promotionType: string;
    maximumUses: string;
    qualificationsForms: { [tier: number]: IQualificationForm[] };
    removedQualificationsForms: IQualificationForm[];
    rewardsForms: { [tier: number]: (IRewardForm | IRewardCategoryForm)[] };
    removedRewardsForms: (IRewardForm | IRewardCategoryForm)[];
    nearMissForms: { [tier: number]: INearMissForm };
}

export interface IQualificationForm {
    qualificationId: string;
    linkToNearMiss?: boolean;
    form: NgxsForm<OfferQualificationFormModel>;
}

export interface IRewardForm {
    rewardId: string;
    form: NgxsForm<OfferRewardFormModel>;
}

export interface INearMissForm {
    qualificationId: string;
    form: NgxsForm<NearMissFormModel>;
}

export interface IRewardCategoryForm {
    rewardId: string;
    form: NgxsForm<OfferRewardCategoryFormModel>;
}

export interface IItemIncludedExcluded {
    includedItems: ItemOrGroup[];
    excludedItems: ItemOrGroup[];
}

export interface IOfferItems {
    qualificationItems: IItemIncludedExcluded;
    rewardItems: IItemIncludedExcluded;
}

export interface IOfferTemplates {
    [x: string]: IOfferTemplate;
}

export interface IOfferTemplate {
    templateId: string;
    templateName: string;
    templateDescription?: string;
    rewardOperatorType?: string;
    defaultPromotionType?: string;
    defaultMaxUses?: number;
    validPromotionTypes: string[];
    templateQualificationsByTier: { [tier: number]: IOfferTemplateQualification[] };
    templateRewardsByTier: { [tier: number]: IOfferTemplateReward[] };
}

export interface IOfferTemplateQualification {
    templateId: string;
    templateQualificationId: string;
    displayName: string;
    showAdvancedFields: boolean;
    fields: IOfferTemplateQualificationField[];
    tier: number;
}

export interface IOfferTemplateQualificationField extends IOfferTemplateField {
    templateQualificationId: string;
}

export interface IOfferTemplateReward {
    templateId: string;
    templateRewardId: string;
    displayName: string;
    showAdvancedFields: boolean;
    fields: IOfferTemplateRewardField[];
    tier: number;
}

export interface IOfferTemplateRewardField extends IOfferTemplateField {
    templateRewardId: string;
}

export interface IOfferTemplateField {
    fieldId: string;
    defaultValue: string;
    editable: boolean;
}

export interface IOfferSelectOptions {
    qualificationLevelOptions: SelectOption[];
    qualificationTypeOptions: SelectOption[];
    qualificationItemsRewardPreference: SelectOption[];
    rewardLevelOptions: SelectOption[];
    rewardTypeOptions: SelectOption[];
    rewardCategoryOptions: SelectOption[];
    rewardCategoryTypeOptions: SelectOption[];
}

export interface IGetOfferTemplatesResponse {
    idToTemplateMap: IOfferTemplates;
}

export interface IRewardCategoryConfig {
    categoryId: string;
    displayName: string;
    description: string;
}

export interface IGetRewardCategoryConfigsResponse {
    rewardCategoryConfigs: IRewardCategoryConfig[];
}

export function isRewardCategoryForm(reward: IRewardForm | IRewardCategoryForm): reward is IRewardCategoryForm {
    return reward?.form?.model && 'rewardCategory' in reward.form.model;
}

export function isRewardCategory(reward: IReward | IRewardCategory): reward is IRewardCategory {
    return 'categoryId' in reward;
}

export function isRewardCategoryTemplate(template: IOfferTemplateReward): boolean {
    return template?.fields?.some(field => field.fieldId === 'rewardCategory');
}
