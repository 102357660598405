import { Injectable } from "@angular/core";
import {
    DeploymentStatusCode,
    IDeployment,
    IDeploymentError,
    IInstallation,
    IVersionedBusinessUnit
} from "../interfaces/deployments/deployment.interface";

export const MISSING_DATA_LABEL = 'Missing Data';
export const MIXED_VERSIONS_LABEL = 'Mixed Versions';
export const FAILED_INSTALLATION_LABEL = 'Install Failure';
export const OTHER_ERROR_LABEL = 'Other Error';

export const UNKNOWN_DEPLOYMENT: IDeployment = {
    deploymentName: 'Unknown',
    version: 'Unknown',
    color: 'basic',
    activationTime: undefined,
    deploymentStatusCode: DeploymentStatusCode.UNKNOWN
}

@Injectable()
export class DeploymentsDataAggregatorService {

    constructor() {
    }

    sortVersionedUnitsByVersion(versionedBusinessUnits: IVersionedBusinessUnit[]) {
        let versionMap = new Map<string, IVersionedBusinessUnit[]>();
        versionedBusinessUnits.forEach(unit => {
            if (this.isMissingData(unit.installations)) {
                this.addToOrCreateSeries(versionMap, unit, MISSING_DATA_LABEL);
            } else if (!this.isVersionConsistent(unit.installations)) {
                this.addToOrCreateSeries(versionMap, unit, this.getDominantVersion(unit.installations));
            } else {
                this.addToOrCreateSeries(versionMap, unit, this.getVersion(unit.installations));
            }
        });
        return versionMap;
    }

    buildErrors(version: string, versionedBusinessUnits: IVersionedBusinessUnit[]): IDeploymentError[] {
        let errors: IDeploymentError[] = [];
        versionedBusinessUnits.forEach(unit => {
            if (unit.installations?.findIndex(e => e.version === version) >= 0) {
                if (this.wasSuccessfulInstall(unit.installations)) {
                    errors.push(this.buildErrorsForUnit(unit, FAILED_INSTALLATION_LABEL));
                }
                if (!this.isVersionConsistent(unit.installations)) {
                    errors.push(this.buildErrorsForUnit(unit, MIXED_VERSIONS_LABEL));
                }
            }
        });
        return errors;
    }

    private wasSuccessfulInstall(installations: IInstallation[]) {
        return installations.findIndex(e => !e.successfulInstall) >= 0;

    }

    private isVersionConsistent(installations: IInstallation[]) {
        return installations.every(e => e.version === installations[0].version);
    }

    private isMissingData(installations: IInstallation[]) {
        return !installations || !installations[0];
    }

    private getVersion(installations: IInstallation[]) {
        return installations[0].version;
    }

    private getDominantVersion(installations: IInstallation[]) {
        const versionCounts: { [key: string]: number } = {};

        // Count occurrences of each version
        installations.forEach(installation => {
            const version = installation.version;
            if (versionCounts[version]) {
                versionCounts[version]++;
            } else {
                versionCounts[version] = 1;
            }
        });

        // Find the version with the highest count
        let dominantVersion: string;
        let maxCount = 0;
        for (const version in versionCounts) {
            if (versionCounts[version] > maxCount) {
                dominantVersion = version;
                maxCount = versionCounts[version];
            }
        }

        return dominantVersion;
    }

    private buildErrorsForUnit(unit: IVersionedBusinessUnit, errorLabel: string): IDeploymentError {
        if (errorLabel === MIXED_VERSIONS_LABEL) {
            return {
                unitAffected: unit.businessUnit,
                installationsAffected: unit.installations,
                label: MIXED_VERSIONS_LABEL
            }
        } else if (errorLabel === FAILED_INSTALLATION_LABEL) {
            return {
                unitAffected: unit.businessUnit,
                installationsAffected: unit.installations.filter(e => !e.successfulInstall),
                label: FAILED_INSTALLATION_LABEL
            }
        }
    }

    private addToOrCreateSeries(versionMap: Map<string, IVersionedBusinessUnit[]>, unitToAdd: IVersionedBusinessUnit, label: string) {
        versionMap.has(label) ? versionMap.get(label).push(unitToAdd) : versionMap.set(label, [unitToAdd]);
    }
}
