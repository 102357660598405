import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectGroup, SelectOption } from '../../forms.interface';
import { FormsService } from '../../forms.service';

@Component({
    selector: 'jmcc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    enabled: boolean = undefined;

    @Input()
    options: SelectOption[] = [];

    @Input()
    optionGroups: SelectGroup[] = [];

    @Input()
    control: FormControl;

    @Input()
    placeholder: string;

    @Input()
    multiSelect = false;

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    required: boolean;

    @Input()
    tooltip: string;

    @Input()
    tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';

    @Input()
    size: 'tiny' | 'small' | 'medium' | 'large' = 'large';

    constructor(protected formsService: FormsService) { }

    ngOnInit(): void {
        this.errorMessages = this.formsService.generateErrorMessages(this.errorMessages, this.label);
    }

    ngOnChanges(): void {
        if (this.enabled !== undefined) {
            if (this.enabled) {
                this.control.enable();
            } else {
                this.control.disable();
            }
        }
    }

    getStatus(): string {
        return this.formsService.getStatus(this.control);
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.control, this.errorMessages);
    }

}
