import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NB_WINDOW, NbIconLibraries } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AuthorizedActions } from './@core/state/authorized.actions';
import { NbAuthJWTToken, NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import * as moment from 'moment';

@Component({
    selector: 'jmcc-app',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription = new Subscription();

    constructor(
        private iconLibraries: NbIconLibraries,
        private router: Router,
        private store: Store,
        private authService: NbAuthService,
        @Inject(NB_WINDOW) nbWindow: Window
    ) {
        this.iconLibraries.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
        this.iconLibraries.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
        moment().format('MM/DD/yyyy HH:mm'); // TODO: fix this hacky workaround to prevent moment readonly error that happens on first load.

        const queryParamString = nbWindow.location.search;
        if (queryParamString.includes('token=')) {
            this.router.navigateByUrl('/auth/callback#access_token' + queryParamString.substring(queryParamString.indexOf('=')));
        } else if (queryParamString.includes('error=')) {
            if (queryParamString.includes('PENDING_APPROVAL_LOGIN')) {
                this.router.navigate(['auth/login/pending-approval']);
            } else if (queryParamString.includes('PENDING_APPROVAL_REGISTER')) {
                this.router.navigate(['auth/register/pending-approval']);
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(this.authService.onTokenChange().subscribe(token => {
            if (NbAuthJWTToken.NAME === token.getName()) {
                const millisUntilExpiration = (<NbAuthJWTToken>token).getTokenExpDate().getTime() - new Date().getTime();
                setTimeout(() => this.authService.refreshToken('email', token).subscribe(), millisUntilExpiration - 5000);
            } else if (NbAuthOAuth2JWTToken.NAME === token.getName()) {
                (<NbAuthOAuth2JWTToken>token).setRefreshToken(token.getValue());
                const millisUntilExpiration = (<NbAuthOAuth2JWTToken>token).getTokenExpDate().getTime() - new Date().getTime();
                setTimeout(() => this.authService.refreshToken('jmccOAuth', token).subscribe(), millisUntilExpiration - 5000);
            }
        }));
        this.subscription.add(this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && !this.router.navigated) {
                this.store.dispatch(AuthorizedActions.RefreshAuthorizedItems);
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
