import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RippleService } from './utils/ripple.service';
import { ServicesModule } from './services/services.module';

const DATA_SERVICES = [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: RippleService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
    // here you could provide any role based on any auth flow
        return of('guest');
    }
}

export const NB_CORE_PROVIDERS = [
    ...ServicesModule.forRoot().providers,
    ...DATA_SERVICES,
    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                view: '*',
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*',
            },
        },
    }).providers,
    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
];

@NgModule({
    imports: [
        CommonModule,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
