import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import {
    NB_TIME_PICKER_CONFIG,
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbAutocompleteModule,
    NbBadgeModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTabsetModule,
    NbTagModule,
    NbThemeModule,
    NbTimepickerModule,
    NbTooltipModule,
    NbUserModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
    ColorIconCardComponent,
    LocalNavBarComponent,
    StepComponent,
    StepperComponent,
    StepperNavButtonsComponent,
    StickyFooterComponent,
    TooltipIconButtonComponent,
    UserBubblesComponent
} from './components';
import {
    CapitalizePipe,
    ConvertForTimezonePipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
} from './pipes';
import { DEFAULT_THEME } from './styles/theme.default';
import { DARK_THEME } from './styles/theme.dark';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AddressPipe } from './pipes/address.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmojisComponent } from './components/emojis/emojis.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TranslateToYesNoPipe } from './pipes/translate-to-yes-no.pipe';
import { NbMomentDateModule } from '@nebular/moment';
import { SnakeCaseToWordsPipe } from './pipes/snake-case-to-words.pipe';
import { TenderTypeCodeIconPipe } from './pipes/tender-type-code-icon.pipe';
import { TransTypeCodeIconPipe } from './pipes/trans-type-code-icon.pipe';
import { TransStatusCodeIconPipe } from './pipes/trans-status-code-icon.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BusinessDateToLocalISOPipe } from './pipes/business-date-to-local-iso.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { OrderTypeIconPipe } from './pipes/order-type-icon.pipe';

const NB_MODULES = [
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbEvaIconsModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbMomentDateModule,
    NbPopoverModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSecurityModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTabsetModule,
    NbTagModule,
    NbThemeModule,
    NbTimepickerModule,
    NbTooltipModule,
    NbUserModule,
    NbBadgeModule
];

const MAT_MODULES = [
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    ClipboardModule
];

const COMPONENTS = [
    ColorIconCardComponent,
    EmojisComponent,
    LocalNavBarComponent,
    StepComponent,
    StepperComponent,
    StepperNavButtonsComponent,
    StickyFooterComponent,
    TimelineComponent,
    TooltipIconButtonComponent,
    UserBubblesComponent,
];
const PIPES = [
    AddressPipe,
    BusinessDateToLocalISOPipe,
    CapitalizePipe,
    ConvertForTimezonePipe,
    LocalizedDatePipe,
    MoneyPipe,
    SnakeCaseToWordsPipe,
    NumberWithCommasPipe,
    OrderTypeIconPipe,
    PluralPipe,
    RoundPipe,
    TenderTypeCodeIconPipe,
    TimingPipe,
    TranslateToYesNoPipe,
    TransStatusCodeIconPipe,
    TransTypeCodeIconPipe
];

@NgModule({
    imports: [
        CommonModule,
        MatRippleModule,
        ...NB_MODULES,
        ...MAT_MODULES
    ],
    exports: [
        CommonModule,
        MatRippleModule,
        ...PIPES,
        ...COMPONENTS,
        ...NB_MODULES,
        ...MAT_MODULES
    ],
    declarations: [...COMPONENTS, ...PIPES],
    providers: [
        {
            provide: NB_TIME_PICKER_CONFIG,
            useValue: {}
        },
    ]
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot(
                    {
                        name: 'jmcc-default',
                    },
                    [DEFAULT_THEME, DARK_THEME],
                ).providers,
                ...PIPES,
                TitleCasePipe
            ],
        };
    }
}
